.org-chart {
  list-style: none;
  margin: 0;
  padding: 0;
  background: #d5d6dd;
  border-radius: 4px;
  /** Due to this viewer sidebar people is not visible on ios*/
  /*overflow: hidden;*/
}

.org-chart.reports {
  overflow: hidden;
}

.org-chart > li {
  position: relative;
}

.org-chart > li:not(:last-child) {
  border-bottom: 0.75px #e2e2e2 solid;
}

.org-chart > li > a {
  display: block;
  padding: 0.75em 1em;
  text-decoration: none;
  color: #003470;
}

.org-chart > li .handle {
  width: 100%;
  /* .handle is already display:flex */
}

.org-chart .caret {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: rotate(-90deg) scale(1.2) translateY(-50%);
  display: none;
}

.org-chart > li.active,
.org-chart > li:hover {
  background: #d4e3ff;
}

.org-chart > li.active .handle .name {
  color: #2d498d;
}

.org-chart > li.active .caret {
  display: block;
}

.dialog .org-chart li.active {
  background: #2d498d;
}

.dialog .org-chart li.active .avatar {
  background: white;
}

.dialog .org-chart li.active .avatar svg {
  fill: #2d498d;
}

.dialog .org-chart li.active .name {
  color: white;
}
