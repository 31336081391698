/***********************************
 ********* main nav pages **********
 ***********************************/

section.nav-page {
  position: fixed;
  margin: 0 auto;
  background: #f3f3f3;
  z-index: 0;
  left: 0;
  top: 49px;
  bottom: calc(49px + env(safe-area-inset-bottom));
  width: 100%;
  max-width: 100vw;
  overflow-x: hidden;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  /** commenting this because of this animation stop working*/
  /* -webkit-transform: translate3d(0, 0, 0); */
}

section > .block-wrapper {
  /*height: 100%;*/
  overflow: auto;
}

section > .block-wrapper > div {
  text-align: left;
}

/***********************************
 ****** Sections - inside pages ******
 ***********************************/

.block-wrapper {
  text-align: center;
  background: #f3f3f3;
  min-height: 100%;
}

.block-wrapper > div {
  pointer-events: none;
  position: relative;
  padding-bottom: 0;
  left: 0;
  right: 0;
  height: 0;
  overflow: hidden;
  opacity: 0;
  z-index: 0;
}

.block-wrapper > div.active {
  opacity: 1;
  z-index: 1;
  overflow: auto;
  height: auto;
  pointer-events: all;
  padding-bottom: 50px;
}

.block-wrapper div > div.introduction {
  background: white;
  color: black;
  height: auto;
  width: 100%;
  max-width: 800px;
  margin: 10px auto 0px;
  padding: 15px;
  border-radius: 12px;
}

.block-wrapper div > div.introduction svg {
  fill: #2d498d;
  width: 100%;
  float: left;
}

.block-wrapper div > div.introduction p {
  font-size: 20px;
  margin: 0 auto;
}

.controls-inline {
  text-align: center;
  padding: 10px 0;
  position: relative;
}

/* the title of each report */
header.intro {
  /*margin-bottom: 2em;*/
  /*padding: 0 30px;*/
  background: rgba(0, 0, 0, 0.05);
  margin: 0 auto;
  color: #202124;
  padding: 20px;
  box-shadow: inset 0 -5px 10px rgba(0, 0, 0, 0.05);
  /*border-radius: 6px;*/
}
header.intro > .title {
  position: relative;
}
header.intro h2 {
  white-space: nowrap;
  margin: 0;
}
header.intro p {
  margin: 0;
  text-align: center;
  color: #202124;
  font: Arial, sans-serif;
}

/*
  .controls-inline:before {
    border-top: 0.75px white solid;
    z-index: 0;
    position: absolute;
    left: 0;
    right: 0;
    top: 100%;
    content: "";
  }
  */
.controls-inline > ul {
  display: inline-flex;
}

nav.nav-secondary.controls-inline {
  display: block;
  text-align: center;
  position: static;
  pointer-events: all;
  top: auto;
  background: transparent;
  border: 0;
}

nav.nav-secondary.controls-inline > ul {
  position: static;
  transform: none;
  display: inline-flex;
  width: auto;
}

@supports (-webkit-overflow-scrolling: touch) {
  /* CSS specific to iOS devices */
  section.nav-page {
    overflow-y: scroll;
    /* has to be scroll for iOS momentum-based scrolling on elements, not auto */
  }
}

@supports not (-webkit-overflow-scrolling: touch) {
  /* CSS for other than iOS devices */
  /* note that @support does not work in IE */
}

/* .fade-enter, */

.fade-exit-active {
  opacity: 0;
  transform: scale(0.5, 0.5);
  transition:
    transform 0.3s cubic-bezier(0, 0.5, 0.3, 1),
    opacity 0.3s cubic-bezier(0, 0.5, 0.3, 1),
    background-color 0.5s;
}

.fade-enter {
  opacity: 0.01;
  pointer-events: none;
  transform: scale(0.8, 0.8);
}

.fade-exit {
  opacity: 0;
  pointer-events: none;
  transform: scale(0.8, 0.8);
}

.fade-enter-active {
  opacity: 1;
  transform: none;
  z-index: 1;
  pointer-events: all;
  transition:
    0.3s transform cubic-bezier(0, 0.5, 0.3, 1),
    0.3s opacity cubic-bezier(0, 0.5, 0.3, 1);
}

.nav-page #container {
  height: 200px;
  margin-top: 0px;
  color: #2d498d;
}

.nav-page #container2 {
  color: #2d498d;
  margin-top: 140px;
}

.nav-page #container #arrow,
.nav-page #container2 #arrow {
  margin-top: 61px;
}

/****** responsive  ******/

@media (min-width: 600px) {
  section.nav-page {
    top: calc(49px + 49px);
    bottom: 0;
  }

  .block-wrapper div > div.introduction svg {
    width: 150px;
  }

  .block-wrapper div > div.introduction p {
    padding-left: 170px;
  }
}

/* iphone SE */
@media (max-width: 320px) {
  .ai-dashboard {
    padding: 0 !important;
  }
}

@media (max-width: 767px) {
  .nav-page #container {
    height: 100px;
  }
}
