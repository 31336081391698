/********************** swiper element ***********************/

.swiper-container {
  position: absolute;
  margin: 0 auto;
  padding: 0;
  overflow: hidden;
  list-style: none;
  left: 0;
  /*top: 49px;*/
  /*bottom: 49px;*/
  top: 0;
  bottom: 0;
  width: 100%;
  max-width: 100vw;
  z-index: 2;
  background: #f3f3f3;
}

.swiper-wrapper {
  display: flex;
  align-items: center;
  position: relative;
  z-index: 1;
  width: 100%;
  /* this height is assuming only one nav */
  /* height: calc(100vh - 49px - 98px); */
}

.swiper-container.notransitions {
  transition: none !important;
}

.swiper-slide-prev article.card > div,
.swiper-slide-next article.card > div {
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.1);
}

.swiper-pagination-bullet {
  width: 10px;
  height: 10px;
  margin: 0 0.25em !important;
  background: #0a193e;
  border: 2px #0a193e solid;
  border-color: transparent;
  vertical-align: middle;
  opacity: 1;
}

.swiper-pagination-bullet-active {
  opacity: 1;
  width: 15px;
  height: 15px;
  vertical-align: middle;
  background: #3d96dd;
  border-color: #3d96dd;
}

.swiper-button-prev {
  left: 0;
  background-color: #2d498d;
  background-image: url('data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D%27http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%27%20viewBox%3D%270%200%2027%2044%27%3E%3Cpath%20class%3D%22st0%22%20d%3D%22M27%2C5L10%2C22l17%2C17l-5%2C5L0%2C22l0%2C0l0%2C0L22%2C0%22%20fill%3D%22%23ffffff%22%2F%3E%3C%2Fsvg%3E') !important;
  background-size: 45%;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.8);
  border-radius: 0 4px 4px 0;
  background-repeat: no-repeat;
  background-position: center;
}
.swiper-button-prev::after {
  content: ' ';
  top: 10px;
}
.swiper-button-next::after {
  content: ' ';
}
.swiper-button-next {
  right: 0;
  background-color: #2d498d;
  background-image: url('data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D%27http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%27%20viewBox%3D%270%200%2027%2044%27%3E%3Cpath%20class%3D%22st0%22%20d%3D%22M27%2C22L27%2C22L5%2C44l-5-5l17-17L0%2C5l5-5L27%2C22L27%2C22z%22%20fill%3D%22%23ffffff%22%2F%3E%3C%2Fsvg%3E') !important;
  background-size: 45%;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.8);
  border-radius: 4px 0 0 4px;
  background-repeat: no-repeat;
  background-repeat: no-repeat;
  background-position: center;
}

@media (min-width: 400px) {
  .swiper-button-prev,
  .swiper-button-next {
    opacity: 1;
  }
}

@media (min-width: 600px) {
  .swiper-container {
    /*top: calc(49px + 49px);*/
    bottom: 0;
  }
}
