/**** overall page settings ****/

section#more {
  background: #f3f3f3;
  padding: 20px;
}

#more a:hover {
  color: #2d498d;
}

#more .block-wrapper {
  overflow-x: hidden;
}

#more .block-wrapper > div.active {
  background: white;
  border-radius: 6px;
  padding: 30px;
  color: #6c6c6c;
  text-align: left;
  overflow-x: hidden;
  max-width: 500px;
  margin: 0 auto;
}

#more .block-wrapper > div > p:first-child {
  margin-top: 0;
}

#more h2 {
  margin: 0 0 0.5em 0;
  font-size: 1.3em;
  text-align: center;
}

#more ul {
  list-style: none;
  margin: 0;
  margin-bottom: 1em;
  padding: 0;
  border-bottom: 1px #efefef solid;
}

#more ul > li {
  border-top: 1px #efefef solid;
}

#more .title {
  text-align: center;
}

#more .title img {
  max-width: 100%;
  height: 60px;
  margin-bottom: 10px;
}

#more h3 {
  margin-bottom: 0;
  color: #2d498d;
}

#more h3 a {
  padding-left: 10px;
}

#more h3 span {
  text-align: center;
  display: inline-block;
  width: 55px;
}

#more h3 svg,
#more h3 img {
  fill: #2d498d;
  height: 25px;
  /*width: 100px;*/
  vertical-align: middle;
  margin-right: 0;
}

#more ul p {
  margin-top: 0;
  margin-left: 42px;
}

#more p img {
  display: inline-block;
  height: 0.85em;
  margin-top: -0.2em;
}

#more-about {
  font-family: 'Roboto Condensed', Arial, sans-serif;
  font-weight: 300;
}
#more-about h2 {
  font-family: 'Roboto Condensed', Arial, sans-serif;
  font-weight: 300;
}

#more-about .footer {
  text-align: center;
}

#more-account li.org {
  padding: 10px;
  background: #efefef;
  border-radius: 6px;
  color: white;
  margin-bottom: 0.5em;
}

#more-account .handle.org {
  background: transparent;
}
#more-account .handle.org .avatar {
  width: 36px !important;
  height: 36px !important;
  background: #2d498d;
}
#more-account .handle.org svg {
  fill: white;
}
#more-account .handle.org .name {
  font-family: 'Roboto Condensed', Arial, sans-serif;
  font-weight: bold;
  font-size: 1.2em;
  padding-left: 10px;
  color: #2d498d;
}

#more form {
  display: inline;
}

#more fieldset {
  padding: 0;
  border: 0;
  display: block;
  width: 100%;
  max-width: 100%;
  overflow: hidden;
}

#more fieldset ~ fieldset {
  /*margin-top: 2em;*/
  position: relative;
}

#more ol {
  list-style: none;
  margin: 0;
  padding: 0;
}

#more ol > li {
  margin: 0;
  padding: 0.5em 0;
}

#more label {
  display: block;
}

#more li.org {
  font-family: 'Roboto Condensed', Arial, sans-serif;
  font-size: 1.5em;
  font-weight: 300;
}

#more p.description {
  font-style: italic;
  margin-top: 5px;
  margin-left: 15px;
  font-size: 12px;
}

#more input[type='submit'],
#more input[type='button'] {
  background-color: #3d96dd;
  -webkit-appearance: none;
  appearance: none;
}
#more input[type='submit']:hover,
#more input[type='button']:hover {
  background: #cfe5ff;
  color: #00639d;
}
#more input.log-out {
  background-color: #3d96dd;
  color: #fff;
  -webkit-appearance: none;
  appearance: none;
}
#more input.log-out:active {
  background-color: #003470;
}

/*
#more input[type="radio"] {
  display: inline-block;
  height: auto;
  width: auto;
}
*/

/*
#more li>span {
  margin-left: 12px;
}
*/

.error {
  color: #be1e2d;
  font-size: 12px;
  margin-left: 0px !important;
}

.forgot-password .error {
  color: white;
}

#more li > span label {
  display: inline-block;
  padding: 0px 10px 0px 2px;
}

/* .prefs-matrix {} */
#more .prefs-matrix > li {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  /* border-bottom: 1px lightgray solid; */
  padding: 15px 0;
}
#more .prefs-matrix > li:not(.header):nth-child(odd) {
  padding: 5px 0 15px 0;
  border-bottom: 1px lightgray solid;
}
#more .prefs-matrix > li:not(.header):nth-child(even) {
  padding: 15px 0 5px 0;
}

.prefs-matrix > li > * {
  flex: 1;
  margin: 0;
  padding: 0;
}

.prefs-matrix > li > label {
  flex-basis: 70%;
  color: #4d4d4d;
  line-height: 1.2;
  padding-right: 1em;
}

.prefs-matrix label > small {
  color: gray;
  line-height: 1.3;
  margin-top: 0.75em;
}

.prefs-matrix > li > span {
  flex-basis: 80px;
  min-width: 75px;
  display: block;
  text-align: center;
}

.prefs-matrix > li .onoffswitch {
  /* margin-left: 10px; */
}

#more .prefs-matrix > li.header {
  align-items: flex-end;
  padding: 2em 0 0.5em 0 !important;
  border-bottom: 1px gray solid;
  text-transform: uppercase;
  font-family: 'Roboto Condensed', sans-serif;
  font-weight: bold;
  font-size: 90%;
  /*font-weight: bold;*/
}

.prefs-matrix > li.header > label {
  color: black;
}

.prefs-matrix > li small {
  position: relative;
  display: block;
  padding-left: calc(1.2em + 0.5em);
}

.prefs-matrix > li small svg.icon {
  position: absolute;
  top: 2px;
  margin-left: calc(-1.2em - 0.5em);
}

.account a {
  color: -webkit-link;
  cursor: pointer;
  text-decoration: underline;
}

/* iphone SE */
@media (max-width: 320px) {
  section#more .block-wrapper > div {
    padding: 15px !important;
  }
}
