/************** Create Button show/hide **************/

/* js adds "on-"+the id number as a class to the body element */
/*
body.page-tiles.sub-active #tile-create-button,
body.page-driver.sub-driver-one #tile-create-button
{
  opacity: 1;
  pointer-events: all;
  transform: scale(1,1) rotate(0);
}
body.page-tiles.swiper #tile-create-button {
  opacity: 0;
  pointer-events: none;
}
*/

/***********************************
 *********** create form ***********
 ***********************************/

/**** the form itself ****/

/* allow the create dialog to be 100% tall */
.dialog.create > .box {
  max-height: calc(100% - env(safe-area-inset-bottom));
}

/* this is a vertical layout with a footer anchored to the bottom */
.dialog.create .inner.create {
  display: flex;
  flex-flow: column nowrap;
  overflow: visible; /* override standard dialog overflow to help with ios keyboards on the create screen */
  border-radius: 0;
  padding: 0;

  max-height: unset;
}
.inner.create > form {
  flex: 1 1 auto;
  position: static !important;
}
.inner.create > footer {
  flex: 0 0 70px;
  position: static !important;
  border-top: 1px lightgray solid;
}

/* .inner has no padding, so adding some here at the very top of the form */
.inner.create fieldset.target {
  margin-top: 10px;
}

/*
.create fieldset .settings-field,
.create fieldset .limit {
  float: left;
  width: 100%;
  max-width: 100%;
  border: 1px solid grey;
  border-radius: 7px;
  padding: 4px;
}

.create fieldset .create-field {
  float: left;
  width: 100%;
  max-width: 307px;
  margin: 1px;
}

.create fieldset .settings-field>.field-line input {
  font-size: 14px;
  font-style: normal;
}

.create fieldset .settings-field>.field-line input::-webkit-input-placeholder {
  * WebKit browsers *
  font-size: 13px;
  font-style: italic;
}

.create fieldset .settings-field>.field-line input:-moz-placeholder {
  * Mozilla Firefox 4 to 18 *
  font-size: 13px;
  font-style: italic;
}

.create fieldset .settings-field>.field-line input::-moz-placeholder {
  * Mozilla Firefox 19+ *
  font-size: 13px;
  font-style: italic;
}

.create fieldset .settings-field>.field-line input:-ms-input-placeholder {
  * Internet Explorer 10+ *
  font-size: 13px;
  font-style: italic;
}

.create fieldset .settings-field>.field-line button:hover+span {
  display: block;
}

.create fieldset .settings-field>.field-line span.info-popup {
  display: none;
  position: absolute;
  right: 0px;
  top: 35px;
  background: white;
  border: 2px solid gray;
  border-radius: 5px;
  padding: 5px;
  height: auto;
  z-index: 3000;
}
*/

.button-container {
  /*color: #4db7ed;*/
  float: left;
  display: inline-flex;
  width: 100%;
  height: 30px;
  overflow-y: auto;
}

.button-container p {
  margin: 0 auto;
  font-size: 18px;
  padding-top: 3px;
  padding-left: 2px;
  float: right;
}

.button-container button {
  border-radius: 5px;
  cursor: pointer;
  background: #4db7ed;
  color: white;
  /*padding: 2px 11px;*/
  width: 100%;
  /*max-width: 100px;*/
}

.button-container button svg {
  fill: white;
}

.button-container div.goals-within-category {
  width: 100%;
  /*width: 175px;
      padding: 0px 5px;*/
}

.button-container div.goals-within-category span {
  font-family: 'Roboto Condensed', Arial, sans-serif;
  font-weight: bold;
  color: #4db7ed;
  border: 1px gray solid;
  border-radius: 4px;
  padding: 0px 10px;
  cursor: pointer;
}

/*
.mark-private {
  padding: 10px 5px;
}

.mark-private input {
  display: inline-block;
  width: 20px;
  height: 15px;
}

.mark-private div {
  display: inline-block;
}

.mark-private div span {
  font-style: italic;
}
*/

.create ul.button-list a {
  background: white;
}

.create ul.button-list svg {
  width: 20px;
  height: 20px;
  fill: #2d498d;
}

.create select {
  color: gray;
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
  width: 100%;
  height: 35px;
  text-indent: 0.4em;
}

/**** the create button ****/

@keyframes create-throb {
  0% {
    background: #2d498d;
  }

  100% {
    background: #3d96dd;
  }
}

.create-button {
  position: fixed;
  left: 50%;
  margin-left: -30px;
  transform: scale(0.5, 0.5) rotate(90deg);
  opacity: 0;
  pointer-events: none;
  width: 60px;
  height: 60px;
  bottom: calc(81px + env(safe-area-inset-bottom));
  text-align: center;
  cursor: pointer;
  z-index: 1;
  transition:
    0.5s transform cubic-bezier(0, 0.5, 0.3, 1),
    0.5s opacity cubic-bezier(0, 0.5, 0.3, 1);
}
#people .create-button {
  margin-left: calc(-30px + (20px / 2));
}

.create-button > b {
  display: none;

  color: #2d498d;
  font-family: 'Roboto Condensed', Arial, sans-serif;
  font-weight: bold;
  position: absolute;
  right: 65px;
  top: 50%;
  transform: translateY(-50%);
  white-space: nowrap;
  text-transform: uppercase;
  text-shadow: 0px 0px 5px rgba(255, 255, 255, 1);
}

.create-button > div {
  position: absolute;
  background: #003470;
  color: white;
  left: 0;
  top: 0;
  width: 60px;
  height: 60px;
  border-radius: 100px;
  border: 3px white solid;
  box-shadow: 0px 0px 20px rgba(255, 255, 255, 1);
  animation: create-throb 2s infinite alternate;
}

.create-button svg {
  display: inline-block;
  vertical-align: middle;
  width: 25px;
  /* sets the size of the entire icon since it's square */
  height: 100%;
  /* forces vertical centering */
  fill: white;
  transition: 0.3s width cubic-bezier(0, 0.5, 0.3, 1);
}

.create-button:hover svg {
  width: 35px;
}

/* animate create button when active tiles are displayed */
body.page-tiles #tile-create-button {
  opacity: 1;
  pointer-events: all;
  transform: scale(1, 1) rotate(0);
  z-index: 2;
}

.people-target-container #secondary-container {
  position: relative;
  top: 10px;
  left: 250px;
}

.people-target-container #secondary-container .nav-secondary ul {
  left: 0;
  right: 0;
}

.people-target-container #secondary-container .nav-secondary {
  border-bottom: 0;
  box-shadow: none;
  background: transparent;
}

.people-target-container .people-target-list {
  padding-left: 270px;
}

@media (min-width: 600px) {
  .dialog.create .inner.create {
    border-radius: 10px;
  }

  .create-button {
    bottom: 20px;
  }
}

@media (min-width: 650px) {
  .dialog.create article > fieldset .settings-field,
  .dialog.create article > fieldset .limit {
    max-width: 50%;
  }
}

@media (min-width: 767px) {
  #people .create-button {
    margin-left: calc(-30px + (250px / 2));
  }
}

@media (max-width: 767px) {
  .people-target-container .people-target-list {
    padding-left: 20px;
  }
  .people-target-container #secondary-container {
    top: 0;
    left: 0;
  }
  .people-target-container #secondary-container .nav-secondary > ul {
    transform: translate(0, -50%);
    max-width: 320px;
  }
}
