input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0px 1000px #000 inset;
  box-shadow: 0 0 0px 1000px #000 inset;
  transition: background-color 5000s ease-in-out 0s;
}

.login-wrapper input:-internal-autofill-selected {
  background-color: #fff !important;
}

.login-wrapper::selection {
  background: #fff;
}

.login-wrapper .field-line > input,
.login-wrapper .field-line > textarea {
  border: 0;
  padding-left: 10px;
  background: #fff;
  -webkit-appearance: none;
}

.login-wrapper .field-line.undefined.error input {
  border: #f00 1px solid;
}

.login-wrapper {
  padding: 20px;
  background: #0a0d28;
  min-height: 100vh;
  margin: auto;
  text-align: center;
  padding-top: 50px;
}

.login-wrapper .bubble {
  background: #eff1f6;
  padding: 15px 30px;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.6);
}
.login-wrapper .bubble p {
  color: #1d1d1b;
}

.login-wrapper a {
  color: #007aff;
}

.login-wrapper h1 {
  margin: 0 0 40px;
  padding: 0;
}

.login-wrapper .login-para {
  font-size: 20px;
  font-family: 'Roboto Condensed', Arial, sans-serif;
  font-weight: 300;
  padding: 0 0 20px;
  margin: 0;
}

.login-wrapper .login-lost-pwd {
  font-size: 16px;
  color: white;
  font-family: 'Roboto Condensed', Arial, sans-serif;
  font-weight: 300;
  text-decoration: none;
  margin-top: 1em;
  cursor: pointer;
}
.login-wrapper .login-lost-pwd.resend {
  color: #007aff;
}

.login-wrapper input {
  border: 0.75px lightgray solid !important;
}

.login-wrapper .login-user input {
  border-radius: 6px 6px 0 0;
  /*border-bottom: 0.75px lightgray solid;*/
}

.login-wrapper .login-user.forgot-password input {
  border-radius: 6px 6px 6px 6px;
}

.login-wrapper .login-pwd {
  position: relative;
  top: -0.75px;
}
.login-wrapper .login-pwd input {
  border-radius: 0 0 6px 6px;
}

.login-wrapper .field-line > label {
  font-weight: normal;
  color: rgba(0, 0, 0, 0.4);
  left: 10px;
  padding-left: 0;
  font-size: 16px;
}
.login-wrapper .field-line.focused > label,
.login-wrapper .field-line.has-value > label {
  font-size: 100%;
}

.login-wrapper fieldset {
  padding: 0;
}

.login-wrapper h1 a {
  background: url('../img/PipelinePlus_Horizontal_Logo_RGB_Full_Color.svg') no-repeat center center;
  background-size: contain;
  padding-bottom: 30px;
  display: block;
  width: 100%;
  height: 100px;
}

.login-wrapper .button-primary {
  /*background: #91C44E;*/
  background: #3d96dd;
  color: #fff;
  border-radius: 6px;
  padding: 15px;
  font-size: 20px;
  margin: 15px 0 20px;
  text-transform: uppercase;
  font-weight: bold;
  font-family: 'Roboto Condensed', Arial, sans-serif;
}
.button-primary:active {
  background: #003470;
}
.button-primary:hover {
  background: #cfe5ff;
  color: #00639d;
}
.disabled {
  opacity: 0.5;
  pointer-events: none;
  cursor: default;
}

@keyframes fadeInAnimation {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.text-animation.active {
  animation: fadeInAnimation ease 3s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}

@media (min-width: 640px) {
  .login-center-layout {
    width: 550px;
    margin: auto;
  }
}

@media (max-width: 480px) {
  .login-wrapper .login-para {
    font-size: 16px;
  }
}

/* iphone 12 mini */
@media (max-width: 375px) {
  .login-wrapper {
    padding-top: 10px;
  }
  .login-wrapper h1 {
    margin-bottom: 10px;
  }
  .login-wrapper .login-para {
    font-size: 14px;
  }
}
