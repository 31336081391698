ul.colleague-list {
  list-style: none;
  padding: 0;
  margin: 0;
  line-height: 1;
  border-top: 1px #ccc solid;
  border-bottom: 1px #ccc solid;
  z-index: 99;
}

ul.container {
  max-width: 98%;
}

ul.colleague-list > li {
  -webkit-transform: translate3d(0, 0, 0);
  padding: 0;
}
.collaboration ul.colleague-list > li {
  display: flex;
  align-items: stretch;
}

ul.colleague-list > li > *:not(:first-child) {
  border-top: 0.75px #e2e2e2 solid;
}

ul.colleague-list > li:first-child > * {
  border-top: 0;
}

ul.colleague-list > li > * {
  position: relative;
}

ul.colleague-list > li > * > span {
  position: absolute;
  display: block;
  left: 0;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  line-height: 1.2;
}

ul.colleague-list > li > .name {
  flex: 1;
}
ul.colleague-list .name span:after {
  font-family: 'Roboto Condensed', Arial, sans-serif;
  font-weight: 300;
  color: gray;
  display: block;
}

ul.colleague-list .name.pending span:after {
  content: '(has not responded)';
}

ul.colleague-list .name.owner span:after {
  content: '(Initiator)';
}

.avatar {
  width: 60px;
  height: 60px;
  position: relative;
  flex-grow: 0;
  flex-shrink: 0;
}

.avatar b {
  background: #2d498d;
  border-radius: 50%;
  overflow: hidden;
  width: 36px;
  height: 36px;
  position: absolute;
  color: white;
  text-align: center;
  line-height: 36px;
  left: 50%;
  top: 50%;
  transform: translateY(-50%) translateX(-50%);
  font-family: 'Roboto Condensed', Arial, sans-serif;
  font-weight: bold;
  font-size: 15px;
}

ul.colleague-list time {
  padding-right: 0;
  padding-bottom: 0;
  padding-left: 0;
  white-space: nowrap;
  font-family: 'Roboto Condensed', Arial, sans-serif;
  font-weight: 300;
  text-transform: none;
  line-height: 1.4;
}

ul.colleague-list time:before {
  color: #b7b7b7;
}

ul.colleague-list .avatar.pending b {
  background: #daecf9;
}

ul.colleague-list .avatar b svg {
  top: auto;
  margin-left: auto;
  margin-right: auto;
}

ul.colleague-list > li.pending .avatar b {
  /*background: white;*/
  color: #2d498d;
}

ul.colleague-list > li.pending .name {
  color: #2d498d;
}

ul.colleague-list .controls {
  width: 100px;
  flex-grow: 0;
  position: relative;
  text-align: right;
}

ul.colleague-list .controls a {
  display: block;
  color: -webkit-link;
  cursor: pointer;
  text-decoration: underline;
}

ul.colleague-list .controls span.owner {
  text-transform: capitalize;
  color: #b7b7b7;
}

/* when it's info-only, there are no actions, only names, and we want to treat each name like a horizontal list of names */

ul.colleague-list.just-info {
  border-top: 0.75px #e2e2e2 solid;
  border-bottom: 0.75px #e2e2e2 solid;
  display: flex;
  flex-flow: row wrap;
  align-items: flex-start;
  align-content: flex-start;
}

td ul.colleague-list.just-info {
  border-top: unset;
  border-bottom: unset;
  /*width: 300px;*/
}

td ul.colleague-list.just-info .avatar {
  width: 30px;
  height: 30px;
}

ul.colleague-list.just-info > .controls {
  display: none;
}

ul.colleague-list.just-info > li {
  flex: 1 0 auto;
  /*margin: 5px;*/

  /*background: #f7f7f7;*/
  /*height: 40px;*/
  border: 0;
  /*border-radius: 20px;*/
  overflow: auto;
}

ul.colleague-list.just-info .avatar {
  width: 40px;
  height: 40px;
}

ul.colleague-list.just-info .avatar b {
  width: 28px;
  height: 28px;
  line-height: 28px;
  font-size: 85%;
}

ul.colleague-list.just-info .name {
  border: 0;
  font-weight: normal;
  padding-left: 5px;
}

ul.colleague-list.just-info .name span {
  position: static;
  transform: none;
  top: auto;
}

.collaboration ul.colleague-list.just-info > li {
  padding: 2.5px;
}

.react-dropdown-select-content .react-dropdown-select-input {
  width: 100% !important;
}

.react-dropdown-select-content > span {
  display: none !important;
}

@media (min-width: 600px) {
  ul.colleague-list .controls {
    flex-grow: 1;
  }
}
