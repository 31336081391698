/**** overall page settings ****/

section#tiles {
  background: #eaeaea;
  padding-top: 93px;
}

section#tiles #active-tile-swiper {
  padding-top: 0;
}

.hud .score small {
  display: block;
  font-size: 12px;
  height: 1.25em;
}
.hud {
  position: fixed;
  top: 60px;
  left: 20px;
  right: 20px;
  max-width: 550px;
  z-index: 0;
  pointer-events: none;

  list-style: none;
  margin: 0 auto;
  padding: 0;
  color: #484848;

  display: flex;
  justify-content: center;
  align-items: top;
  flex-flow: row nowrap;

  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: top;
  -ms-flex-align: top;
  align-items: top;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-flow: row nowrap;
  flex-flow: row nowrap;

  font-family: 'Roboto Condensed', sans-serif;
  font-weight: bold;
}
.hud > li {
  flex: 1 1 0;
}
.hud > li:first-child .stat {
  border-radius: 6px 0 0 6px;
}
.hud > li:last-child .stat {
  border-radius: 0 6px 6px 0;
}

.hud dl {
  margin: 0 0 0 -1px;
  padding: 0;
}
.hud dt {
  position: relative;
  text-transform: uppercase;
  font-size: 12px;
  font-weight: bold;
  color: gray;
  text-align: center;
  line-height: 1.3;
  white-space: nowrap;
  width: 100%;
  text-overflow: ellipsis;
}
.hud dt small {
  color: #a5a5a5;
}
.hud dd {
  margin: 0;
  padding: 0;
  font-weight: bold;
}
.hud .stat {
  color: black;
  background: rgba(255, 255, 255, 0.6);
  border: 1px #bbb solid;
  color: #2d498d;
  font-size: 24px;
  /*font-weight: bold;*/
  text-align: center;
  padding: 0 10px;
  line-height: 1.5;
}

.hud .summary {
  display: none;
  font-family: Arial, sans-serif;
  white-space: nowrap;
  font-size: 90%;
}

.hud p {
  margin: 0;
}

/* more markup to prettify the HUD popup */
.dialog.hud-dialog .horz {
  display: flex;
}

.dialog.hud-dialog .horz a {
  margin: 0 5px;
  border-radius: 4px;
  white-space: normal;
  text-decoration: none;
}

.dialog.hud-dialog .horz a svg {
  margin-left: 0 !important;
}

/* the details / summary element */

.hud-dialog details summary {
  border: 1px #2d498d solid;
  color: #2d498d;
  outline: none;
  border-radius: 3px;
  padding: 1em;
  margin-bottom: 0.5em;
}
/* new chrome always shows the marker on the summary element */
.hud-dialog details summary::marker {
  color: cyan;
  display: none;
  content: '';
}

.hud-dialog details summary legend {
  display: inherit;
  border-bottom: 0;
  margin-bottom: 0;
}
/* turn off before and after due to being embedded in .info-block */
.hud-dialog details legend:after {
  display: none;
  content: '';
}
.hud-dialog details legend:before {
  display: none;
}

section#tiles .block-wrapper {
  position: relative;
  z-index: 1;
  border-top: 1px #e2e2e2 solid;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  min-height: calc(100vh - 49px - 70px); /* window height minus secondary nav minus HUD */
}

section#tiles .ai-dashboard {
  padding-top: 2em;
}

.ai-dashboard li.do-now {
  position: relative;
}

.ai-dashboard li.do-now:before {
  /*content: '';*/
  position: absolute;
  background: #2d498d;
  left: -10px;
  top: -40px;
  height: 230px;
  width: calc(100% + 20px);
}

.ai-dashboard li.do-now:after {
  content: 'Respond Now';
  font-family: 'Roboto Condensed', Arial, sans-serif;
  font-weight: normal;
  font-size: 12px;
  text-transform: uppercase;
  position: absolute;
  top: -23px;
  right: 20px;
  line-height: 1.4;
  text-align: center;
  color: white;
  background: #007aff;
  height: 24px;
  padding: 4px 12px;
  border-radius: 4px 4px 0 0;
}

.ai-dashboard li.do-now > article {
  /*border-radius: 0;*/
  border-color: #007aff !important;
  background: #2d498d !important;
  color: white;
}

.ai-dashboard li.do-now > article h1 {
  color: white;
  border-bottom-color: #007aff;
}

.ai-dashboard li.do-now > article footer svg {
  fill: white;
}

.ai-dashboard#pipeline-active:before {
  content: 'Pursuing';
}

.ai-dashboard#pipeline-archived:before {
  content: 'On Hold';
}

.ai-dashboard#pipeline-lost:before {
  content: 'Lost';
}

.ai-dashboard#pipeline-completed:before {
  content: 'Won';
}
.ai-dashboard#pipeline-deleted:before {
  content: 'Deleted';
}

.ai-dashboard > li > article.tile.shared {
  border-color: #2d498d;
}

article.tile label.badge {
  position: absolute;
  top: 10px;
  right: 15px;
  color: white;
  font-family: 'Roboto Condensed', Arial, sans-serif;
  font-weight: normal;
  font-size: 12px;
  text-transform: uppercase;
  text-align: center;
  background: #3761c5;
  padding: 4px 8px;
  border-radius: 10px;
}

article.tile label.badge b {
  background: red;
  display: inline-block;
  text-align: center;
  font-weight: bold;
  border-radius: 50%;
  width: 16px;
  height: 16px;
}

.ai-dashboard.do-now > li {
  height: auto;
}

.ai-dashboard.do-now article {
  height: 80px;
}

.ai-dashboard.do-now article p {
  margin: 0 0 3px 0;
  text-align: center;
}

.ai-dashboard.do-now article.overdue button {
  background: #be1e2d;
}

/********************** target dashboard defaults ********************/

.tile-container article.tile {
  /*color: #2d498d;*/
  color: #484848;
  background: white;
  /*overflow: hidden;*/
}

.tile-container article.tile h1 {
  color: #2d498d;
  border-bottom-color: #2d498d;
}

.tile-container article.tile > svg {
  position: absolute;
  top: auto;
  bottom: 15px;
  left: 15px;
  fill: #2d498d;
}

.tile-container article.tile .tags .icon svg {
  width: 18px;
  height: 18px;
  margin-right: 0;
}

.tile-container article.tile > .icon-list {
  display: none;

  position: absolute;
  bottom: 15px;
  left: 15px;
}

.icon-list {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  align-items: flex-end;
}

.icon-list > li {
  text-align: center;
  padding-right: 5px;
}

.icon-list svg {
  position: static;
  margin-right: 0;
  fill: #919191;
  width: 16px;
  height: 14px;
}

.icon-list > li:first-child svg {
  margin-right: 0;
}

.tile-container article.tile h2 {
  font-size: 13px;
  font-weight: normal;
  border: 0;
  margin: 10px 0px;
  padding: 1px;
  overflow: hidden;
  text-overflow: ellipsis;
  font-family: 'Roboto Condensed';
  font-weight: normal;
}

/* if the objective is an info block */
.tile-container article.tile .info {
  background: cornsilk;
  border-radius: 4px;
  padding: 10px 15px;
  position: relative;
}

.tile-container article.tile .info .icon {
  position: absolute;
  top: 6px;
}

.tile-container article.tile .info svg {
  fill: #484848;
}

.tile-container article.tile .info > span:last-child {
  display: block;
  padding-left: 20px;
}

/* if the objective is an info block */
.current-step .info {
  padding: 10px 15px;
}

.current-step .info svg {
  fill: white;
}

article.tile time {
  position: absolute;
  right: 15px;
  bottom: 15px;
}
/* article.tile.archived time {
  padding: 0;
  left: 15px;
  right: unset;
  bottom: 50px;
  padding: 0;
} */

#tile-create-form .field-notes {
  margin-top: 14px;
}

button.save-note-button {
  margin-top: 10px;
  max-width: 210px;
}

/* if there's a .company following a .contact, add a pipe between */
article.tile h1 .contact ~ .company:before {
  content: ' » ';
}

article.tile .tip {
  padding-top: 1px;
}

article.tile .tip img {
  width: 18px;
  border: none;
  margin: 0 auto;
}

.tile-container article.tile > footer {
  display: flex;
  align-items: center;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  text-align: left;
  padding: 10px;
  border-top: 1px #007aff solid;
}

.tile-container article.tile > footer > p {
  margin: 0;
}

.tile-container article.tile > footer > p:first-child {
  width: 50px;
}

.tile-container article.tile > footer svg {
  width: 18px;
  height: 18px;
  fill: #007aff;
}

/********************** dashboard - colors ***********************/

.tile-container article.tile.overdue h1 {
  color: #be1e2d;
  border-bottom-color: #be1e2d;
}

article.tile.overdue time {
  background-color: #be1e2d;
  color: white;
}

.tile-container article.tile.soon h1 {
  color: #e09600;
  border-bottom-color: #e09600;
}

article.tile.soon time {
  background-color: #e09600;
  color: white;
}

.tile-container article.tile.later h1 {
  color: #266733;
  border-bottom-color: #266733;
}

article.tile.later time {
  background-color: #266733;
  color: white;
}

.tile-container article.tile.shared time {
  background-color: #2d498d;
  color: white;
}

/********************** card - colors ***********************/

/* overdue */
article.card.overdue h1 {
  color: #be1e2d;
  border-bottom-color: #be1e2d;
}

article.card.overdue button.action.next {
  background: #be1e2d;
  border: 0;
  color: white;
}

article.card.overdue div.block.next,
article.card.overdue div.block.next > div {
  background: #581111;
}

article.card.overdue .radio {
  border-color: #be1e2d;
}

article.card.overdue div.block.action-list {
  background: #581111;
}

article.card.overdue i.action {
  color: #e22b3c;
  display: inline-block;
  position: relative;
  top: 0;
  transition: top 0.3s linear;
}

article.card i.action:active {
  top: 5px;
  filter: saturate(200%);
}

article.card.overdue i.action svg {
  background: #e22b3c;
  fill: white;
}

article.card.overdue .followup .date-picker:before {
  background: linear-gradient(to right, rgba(88, 17, 17, 1) 0%, rgba(88, 17, 17, 0) 100%);
}

article.card.overdue .followup .date-picker:after {
  background: linear-gradient(to left, rgba(88, 17, 17, 1) 0%, rgba(88, 17, 17, 0) 100%);
}

article.card.overdue .followup .edit .date-scroller button.action.next {
  background: white;
  color: #2d498d;
}

article.card.overdue .current-step a,
article.card.overdue .followup a {
  /*color: #be1e2d;*/
  color: #e22b3c;
}

article.card.overdue .edit .date-picker button.action.next.active {
  background: #be1e2d;
  border-color: #be1e2d;
  color: white;
}

/* due soon */
article.card.soon h1 {
  color: #e09600;
  border-bottom-color: #e09600;
}

article.card.soon button.action.next {
  background: #e39f15;
  border: 0;
  color: white;
}

article.card.soon div.block.next,
article.card.soon div.block.next > div {
  background: #5f4001;
  /*background: linear-gradient(180deg, #5f4001 0%, #403e00 100%);*/
}

article.card.soon .radio {
  border-color: #e39f15;
}

article.card.soon div.block.action-list {
  background: #5f4001;
  text-align: center;
}

article.card.soon i.action {
  color: #e39f15;
}

article.card.soon i.action svg {
  background: #e39f15;
  fill: white;
}

article.card.soon .followup .date-picker:before {
  background: linear-gradient(to right, rgba(95, 64, 1, 1) 0%, rgba(95, 64, 1, 0) 100%);
}

article.card.soon .followup .date-picker:after {
  background: linear-gradient(to left, rgba(95, 64, 1, 1) 0%, rgba(95, 64, 1, 0) 100%);
}

article.card.soon .followup .edit .date-scroller button.action.next {
  background: white;
  color: #2d498d;
}

article.card.soon .current-step a,
article.card.soon .followup a {
  color: #e39f15;
}

article.card.soon .edit .date-picker button.action.next.active {
  background: #e39f15;
  border-color: #e39f15;
  color: white;
}

/* due later */
article.card.later h1 {
  color: #266733;
  border-bottom-color: #266733;
}

article.card.later button.action.next {
  background: #266733;
  border: 0;
  color: white;
}

article.card.later div.block.next,
article.card.later div.block.next > div {
  background: #05270c;
  /*background: linear-gradient(180deg, #05270c 0%, #002f2d 100%);*/
}

article.card.later .radio {
  border-color: #266733;
}

article.card.later div.block.action-list {
  background: #05270c;
  text-align: center;
}

article.card.later i.action {
  color: #388e49;
}

article.card.later i.action svg {
  background: #388e49;
  fill: white;
}

article.card.later .followup .date-picker:before {
  background: linear-gradient(to right, rgba(5, 39, 12, 1) 0%, rgba(5, 39, 12, 0) 100%);
}

article.card.later .followup .date-picker:after {
  background: linear-gradient(to left, rgba(5, 39, 12, 1) 0%, rgba(5, 39, 12, 0) 100%);
}

article.card.later .current-step a,
article.card.later .followup a {
  /*color: #266733;*/
  color: #388e49;
}

article.card.later .edit .date-picker button.action.next.active {
  background: #266733;
  border-color: #266733;
  color: white;
}

/* one last override for when the edit form is inside a card overdue/soon/later */
/* the default date scroller colors are gray -- inside a form */
article.card .back .edit .date-scroller button.action.next {
  background: white;
  border: 1px #ccc solid;
  color: #2d498d;
}

article.card .back .edit .date-scroller button.action.next.active {
  background: #2d498d;
  border-color: #2d498d;
  color: white;
}

article.card .followup .date-picker button.action.next {
  color: #2d498d;
  background: white;
}

article.card .back .edit .date-picker:before {
  background: linear-gradient(to right, rgba(239, 239, 239, 1) 0%, rgba(239, 239, 239, 0) 100%);
}

article.card .back .edit .date-picker:after {
  background: linear-gradient(to left, rgba(239, 239, 239, 1) 0%, rgba(239, 239, 239, 0) 100%);
}

/******* swiper ********/

body.page-tiles.swiper nav.app.secondary {
  top: -49px;
}

.divider {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  border-top: 1px #dadada solid;
}

.divider.objective {
  top: 70px;
}

.divider.info {
  display: none;
}

/****** cards ******/

article.card h1:before {
  content: 'Target';
  font-family: 'Roboto Condensed', Arial, sans-serif;
  font-weight: bold;
  font-size: 12px;
  text-transform: uppercase;
  position: absolute;
  left: 20px;
  top: 13px;
  line-height: 1.4;
  text-align: center;
  color: #2d498d;
}

article.card header h2 i.action {
  right: 15px;
  top: 50%;
  left: auto;
  transform: translateY(-50%);

  display: none;
}

/************/
section .your-notes {
  padding: 0.5rem 0 2rem 0;
}

article.card .front .target-notes {
  padding-block-start: 0.05em;
  padding-inline-start: 0;
  padding-inline-end: 0.75em;
  padding-block-end: 0.625em;
}

article.card .front legend {
  display: none;
  line-height: 1.2;
}

article.card .front .target-notes legend {
  display: block;
  line-height: 1.2;
  background-color: white;
}

article.card .main {
  /* height: calc(100% - 70px - 60px); */
  overflow-x: visible;
  overflow-y: visible;
}

article.card .block.next {
  padding: 20px;
  padding-top: 30px;
  background: #0a193e;
  height: 230px;
  color: white;
  position: relative;
  overflow: hidden;
}

article.card .block.next:before {
  content: 'Current Step';
  font-family: 'Roboto Condensed', Arial, sans-serif;
  font-weight: bold;
  font-size: 12px;
  text-transform: uppercase;
  position: absolute;
  left: 20px;
  top: 13px;
  color: rgba(255, 255, 255, 0.6);
}

article.card .block.next h3 {
  margin: 0 0 10px 0;
}

article.card .block.next .current-step > h3 {
  margin: 5px 0 10px 0;
  position: relative;
  font-family: 'Roboto Condensed', Arial, sans-serif;
  font-weight: normal;
  font-size: 15px;
}

article.card .current-step {
  margin: 0;
}

article.card .current-step a {
  font-family: 'Roboto Condensed', Arial, sans-serif;
  font-weight: bold;
}

article.card .due-date {
  position: relative;
  padding: 0;
  text-align: right;
  font-family: 'Roboto Condensed', Arial, sans-serif;
  font-weight: normal;
}

article.card .due-date a {
  text-transform: uppercase;
}

article.card .due-date svg {
  background: #2d498d;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  fill: white;
}

article.card .current-step time {
  /*position: absolute;*/
  display: inline-block;
  padding: 0px;
  text-align: right;
  bottom: 0px;
  right: 45px;
  color: white;
}

article.card .current-step p {
  padding: 0.5em 0;
  margin: 0;
  font-size: 12px;
  line-height: 1.2;
}

article.card .current-step p svg {
  margin-left: 0;
  width: 1.2em;
  height: 1.2em;
}

article.card button.action {
  display: block;
  border-radius: 6px;
  background: rgba(0, 0, 0, 0.5);
  color: white;
  border: 0;
  line-height: 50px;
  text-align: center;
  font-family: 'Roboto Condensed', Arial, sans-serif;
  font-weight: bold;
  font-size: 1.2em;
  text-transform: uppercase;
}

article.card button.action.next {
  border-radius: 6px;
  line-height: 45px;
  height: 45px;
  margin-top: 5px;
  overflow: hidden;
  text-overflow: ellipsis;
  text-decoration: none;
}

article.card button.action.next i.icn.hover {
  display: none;
}

article.card button.action.next:hover i.icn {
  display: none;
}

article.card button.action.next:hover i.icn.hover {
  display: inline-block;
}

article.card button.action svg {
  fill: white;
}

article.card i.action.snooze {
  left: auto;
  transform: translateY(-50%);
  background: #be1e2d;
  border: 0;
  top: 0;
}

article.card i.action.snooze svg {
  fill: white;
}

.next-action {
  text-decoration: none;
}

.block.next .followup.snooze h3 {
  margin-bottom: 10px;
}

.followup.snooze .date-picker {
  margin-top: 10px;
  height: 60px;
}

.followup.revenue .yesno {
  margin-bottom: 10px;
}

.followup.revenue button.action.next {
  margin-top: 10px;
}

.followup .radio.revenue-radio {
  background: white;
  border: 1px rgba(0, 0, 0, 0.2) solid;
  font-family: roboto condensed;
  font-size: 16px;
  min-height: 40px;
  z-index: 0;
}

.followup .radio.revenue-radio .check {
  border: 2px solid #003470;
}

.followup .radio.revenue-radio input[type='radio']:checked ~ .check {
  border: 2px solid #003470;
}

.followup .radio.revenue-radio input[type='radio']:checked ~ .check::before {
  background: #003470;
}

.followup input[type='radio']:checked ~ label {
  color: #003470;
}

.followup .radio.revenue-radio input[type='radio']:checked ~ {
  border-color: #003470;
}

.create .date-picker:before {
  background: linear-gradient(to right, rgba(239, 239, 239, 1) 0%, rgba(239, 239, 239, 0) 100%);
}

.create .date-picker:after {
  background: linear-gradient(to left, rgba(239, 239, 239, 1) 0%, rgba(239, 239, 239, 0) 100%);
}

.create .date-picker > div > button {
  background: white;
  color: #2d498d;
  border: 1px #ccc solid;
}

.field-line.company > input {
  border-radius: 4px 4px 0 0;
}
.field-line.contact > input {
  border-radius: 0 0 4px 4px;
}

fieldset.complete-step {
  padding-left: 20px;
}

.step.complete-step .field-line > label {
  padding-right: 40px;
}

fieldset legend:before  {
  content: '';
  position: absolute;
  left: -13px;
  top: -2px;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background: #2d498d;
}

@media (max-width: 600px) {
  fieldset.complete-step {
    padding-left: 0px;
    padding-right: 0;
  }
}

.complete-step .field-line ~ .field-line {
  margin-top: 3px;
}

article.card .complete-step button.action.next {
  margin-top: 3px;
}

/**** big friendly inputs ****/

.block.next > .followup {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  margin: 0;
  padding: 0;
  /*width: 100%;*/
  height: 0;
  overflow: hidden;
  /*background: red;*/
  transition: 0.3s top cubic-bezier(0, 0.5, 0.3, 1);
  line-height: 1;
}

.block.next > .followup.next-step.active {
  padding: 5px 15px;
}

.block.next > .followup.next-step fieldset {
  padding: 0;
}

.block.next > .followup.active {
  top: 0;
  height: 100%;
  padding: 15px;
  background: blue;
}

article.card .block.next > .followup > h3 {
  margin-bottom: 10px;
  font-weight: normal;
}

.followup .radio {
  border: 2px black solid;
  border-radius: 4px;
  position: relative;
  margin-bottom: 0.5em;
  font-size: 1.17em;
  cursor: pointer;
}

.followup input[type='radio'] {
  position: absolute;
  visibility: hidden;
  z-index: 9999;
}

.followup .radio input[type='text'] {
  /* opacity: 0;
    transition: opacity 0.2s;
    position: absolute;
    z-index: 0;
    top: 10px;
    left: 60px;
    /*right: 15px;
    width: calc(100% - 60px - 10px);
    z-index: 10; */
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.2s;
  position: absolute;
  top: 10px;
  left: 60px;
  /* right: 15px; */
  /* width: calc(100% - 0px); */
  z-index: 10;
}

/*
  .followup .radio.active input[type=text] {
    opacity: 1;
    z-index: 10;
  }
  */

.followup .radio:checked ~ input[type='text'] {
  opacity: 1;
  pointer-events: all;
  z-index: 10;
}

.followup .radio > label {
  display: block;
  position: relative;
  text-align: left;
  padding: 10px 5px 10px 40px;
  line-height: 1.2;
  z-index: 9;
  cursor: pointer;
  transition: all 0.25s linear;
  white-space: nowrap;
}

.followup .radio > label > span {
  font-family: Roboto Condensed;
  font-weight: bold;
  font-size: 14px;
}

.followup .radio .check {
  display: block;
  position: absolute;
  border: 2px white solid;
  border-radius: 100%;
  height: 25px;
  width: 25px;
  top: 50%;
  transform: translateY(-50%);
  left: 8px;
  z-index: 5;
  transition: border 0.25s linear;
}

.followup .radio .check::before {
  display: block;
  position: absolute;
  content: '';
  border-radius: 100%;
  height: 15px;
  width: 15px;
  top: 3px;
  left: 3px;
  margin: auto;
  transition: background 0.25s linear;
}

.followup input[type='radio']:checked ~ .check {
  border: 2px solid white;
}

.followup input[type='radio']:checked ~ .check::before {
  background: white;
}

.followup input[type='radio']:checked ~ label {
  color: white;
}

.followup input[type='radio']:checked ~ .radio {
  border-color: white;
}

.followup input[type='radio']:checked ~ input[type='text'] {
  opacity: 1;
  pointer-events: all;
  top: 1px;
  left: 0px;
}

.followup .check.square {
  border-radius: 0;
  top: 50%;
  transform: translateY(-50%);
}

.followup .check.square::before {
  content: '';
  width: 12px;
  height: 8px;
  position: absolute;
  top: 5.5px;
  left: 6px;
  border: 4px solid #fcfff4;
  border-top: none;
  border-right: none;
  border-radius: 0;
  background: transparent;
  opacity: 0;
  transform: rotate(-45deg);
}

.followup input[type='checkbox'] {
  visibility: hidden;
}

.followup input[type='checkbox']:checked ~ .check::before {
  opacity: 1;
}

.followup .checkbox {
  margin-top: 0;
  margin-bottom: 0;
  height: 50px;
  position: relative;
  font-size: 1.17em;
}

.followup .checkbox label {
  line-height: 1;
  padding-top: 0;
  padding-bottom: 0;
  margin: 0;
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}

.followup .checkbox.active label {
  color: white;
}

.followup .checkbox.active .check {
  border-color: white;
}

.followup .action.submit-revenue {
  position: absolute;
  margin: 0;
  left: -1px;
  right: -1px;
  bottom: 0;
  height: 75px;
  font-size: 1em;
  line-height: 45px;
  border-radius: 0;
  background: rgba(0, 0, 0, 0.1);
}

.followup .action.submit-revenue:hover {
  background: rgba(0, 0, 0, 0.2);
}

.followup .do-revenue {
  padding-left: 0;
  padding-right: 0;
  height: auto;
  overflow: auto;
}

.followup .do-revenue > .followup.revenue {
  top: 0;
}

article.card div.block.action-list {
  text-align: center;
  padding: 10px;
  height: 55px;
  background: #0a193e;
  border-top: 1px rgba(255, 255, 255, 0.2) solid;
}

article.card .block.action-list li {
  text-align: center;
}

article.card .block.action-list a,
article.card .block.action-list li > span {
  padding: 8px 2px;
  display: block;
}

/* hide cancel, it will be shown when a followup appears */
article.card .block.action-list .action-cancel {
  display: none;
}

article.card .block.action-list ul.active li {
  display: none;
}

article.card .block.action-list ul.active li.action-cancel {
  display: block;
}

article.card div.block.tabs {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 2;
  padding: 20px;
  border-top: 1px #dadada solid;
  border-bottom: 1px #dadada solid;
  background: #f2f2f2;
}

article.card div.block.tabs ul.button-bar {
  display: flex;
}

article.card div.block.context {
  border-top: 0;
  padding-left: 20px;
  padding-right: 20px;
  /*background: #e9e9e9;*/
  color: #585858;
}

article.card div.context-container > * {
  /* left: 20px; */
  right: 20px;
  position: relative;
}

/* disabled ul */

.disabled-ul {
  cursor: not-allowed !important;
  background-color: lightgray !important;
}

/* hide the longer name on smaller screens */
article.card .block.action-list span {
  display: none;
}

/**** list of prior steps *****/

div.steps > ol {
  list-style: none;
  margin: 0;
  padding: 0;
}

div.steps > ol > li {
  padding: 0;
  margin: 0 0 15px 0;
  position: relative;
  padding-left: 20px;
}

div.steps > ol svg {
  position: absolute;
  left: 0;
  top: 0.125em;
}

div.steps > ol p {
  margin: 0;
}

div.steps > ol b {
  font-size: 80%;
  text-transform: uppercase;
  font-family: 'Roboto Condensed', Arial, sans-serif;
  font-weight: bold;
}

.followup .radio .error-msg {
  margin: 3px 0 -20px;
  font-size: 10px;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0px 1000px #fff inset !important;
  box-shadow: 0 0 0px 1000px #fff inset !important;
}

.custom-checkbox {
  display: inline-block;
  position: relative;
  width: 20px;
  height: 20px;
}

.custom-checkbox input {
  opacity: 0;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}

.custom-checkbox span {
  width: 20px;
  height: 20px;
  border: 2px solid #fff;
  display: block;
}

.custom-checkbox span::before {
  content: '';
  width: 12px;
  height: 8px;
  position: absolute;
  top: 5.5px;
  left: 6px;
  border: 4px solid #fcfff4;
  border-top: none;
  border-right: none;
  border-radius: 0;
  background: transparent;
  opacity: 0;
  transform: rotate(-45deg);
}

.custom-checkbox input:checked + span {
  border: none;
}

.custom-checkbox input:checked + span::before {
  opacity: 1;
}

.revenue .custom-checkbox {
  float: left;
  margin: -2px 6px 7px 0;
}

/* ical integration link */
.url-copy {
  background: cornsilk;
  border-radius: 4px;
  padding: 15px;
}
.url-copy input {
  height: auto;
  line-height: 1.5;
  border: 1px lightgray solid;
  border-radius: 4px;
  padding: 5px 10px;
  margin: 0;
  background: transparent;
  font-family: monospace;
  font-size: 10px;
}
.url-copy a {
  display: block;
  padding: 0;
  padding-top: 5px;
}

.info-block.calendar a:hover {
  color: #007aff;
}

/* reset button in the search dialog */
.button-bar.reset a {
  padding: 20px 10px;
}
.button-bar.reset li a svg {
  margin-left: 0 !important;
  fill: #2d498d;
}
.button-bar.reset li.active a svg {
  fill: white;
}

.collaborator-notes {
  display: flex;
}

.collaborator-notes .items {
  display: flex;
  align-items: center;
  justify-content: center;
}

.collaborator-notes .save-time {
  max-width: 15%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: gray;
  font-size: 0.9rem;
  padding: 0 0.4rem;
  font-family: 'Roboto Condensed', Arial, sans-serif;
}

.read-or-hide {
  color: #003470;
  cursor: pointer;
  font-weight: 600;
}

.last-saved {
  color: gray;
  padding: 0.5rem 4rem;
  font-family: 'Roboto Condensed', Arial, sans-serif;
}

.last-saved .item {
  width: 15%;
}

.pending b {
  color: #2d498d;
}

.field-line.decorated {
  display: block;
  position: relative;
  border-radius: 4px;
}
.field-line.field-notes.has-value label {
  width: 100%;
  background: #f7f7f7;
  top: 11px;
}
.field-line.decorated.field-notes {
  background: transparent;
}
.field-line.decorated.field-notes:not(.has-value) textarea {
  height: 50px;
}

.field-line.has-value input,
.field-line.has-value textarea {
  background: rgba(255, 255, 255, 0.5);
  color: gray;
  font-size: 0.8rem;
  font-family: Arial, Helvetica, sans-serif;
}
/****** responsive ******/

@media (min-width: 360px) {
  article.card .current-step time {
    padding: 0px 5px 8px 0px;
  }
}

@media (min-width: 600px) {
  section#tiles {
    padding-top: 100px;
  }

  section#tiles .block-wrapper {
    /*min-height: calc(100vh - 49px - 49px - 100px);*/
    /* window height minus both navs minus HUD */
  }

  .hud {
    top: 110px;
    flex-wrap: nowrap;
  }

  .hud .sharing dl dd,
  .hud .bonus dl dd {
    display: block;
  }

  .dialog .info-block.report {
    display: block;
  }

  .hud .summary {
    display: block;
    text-align: center;
  }

  article.card .block.action-list span {
    display: inline;
  }

  article.card > div {
    overflow: hidden;
  }

  article.card .main {
    height: calc(100% - 85px - 85px);
    /* header h1 + header h2 */
    overflow-x: hidden;
    overflow-y: scroll;
    /* has to be scroll, not auto */
    -webkit-overflow-scrolling: touch;
  }

  article.card button.action.next {
    line-height: 60px;
    height: 60px;
  }

  .block.next > .followup.active {
    padding: 20px;
  }

  .ai-dashboard li.do-now:before {
    left: -20px;
  }

  .followup .radio .error-msg {
    font-size: 12px;
  }
}

@media screen and (max-width: 380px) {
  .remove-targets-selection button {
    width: 100% !important;
  }
}

@media (min-width: 500px) {
  .visible-xs {
    display: none;
  }
  .hud .score small {
    display: none !important;
  }
  section#tiles {
    background: #eaeaea;
    padding-top: 77px;
  }
}

@media (max-width: 500px) {
  .followup.revenue .yesno {
    flex-direction: column;
  }
  article.card .block.next {
    height: 270px;
  }
}
