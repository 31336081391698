.target-notes-container{
    border-radius: 4px;
    border: 1px solid #ccc;
}

.target-notes-container > .target-notes-textarea{
    height: 61px;
    max-height: 61px;
    padding-right: 40px;
    background-color: transparent;
    border: none;
}

.target-notes-container:focus-within{
    border: 1px solid #007aff;
}

