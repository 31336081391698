/********** fonts ***********/

@font-face {
  font-family: 'Roboto Condensed';
  src:
    local('Roboto Condensed Light'),
    url('../fonts/robotocondensed-light-webfont.woff2') format('woff2'),
    url('../fonts/robotocondensed-light-webfont.woff') format('woff');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto Condensed';
  src:
    local('Roboto Condensed'),
    url('../fonts/robotocondensed-regular-webfont.woff2') format('woff2'),
    url('../fonts/robotocondensed-regular-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto Condensed';
  src:
    local('Roboto Condensed Bold'),
    url('../fonts/robotocondensed-bold-webfont.woff2') format('woff2'),
    url('../fonts/robotocondensed-bold-webfont.woff') format('woff');
  font-weight: bold;
  font-style: normal;
}

/* https://geoffgraham.me/how-im-dealing-with-font-sizes/ */
html {
  font-size: 16px;
}
@media screen and (min-width: 320px) {
  html {
    font-size: calc(16px + 6 * ((100vw - 320px) / 680));
  }
}
@media screen and (min-width: 1000px) {
  html {
    font-size: 22px;
  }
}

/********** Start of Boilerplate ***********/

html {
  color: #222;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  box-sizing: border-box;

  font-size: 1em;
  line-height: 1;
}

body {
  margin: 0;
  padding: 0;
  padding-top: constant(safe-area-inset-top);
  padding-top: env(safe-area-inset-top); /* iphone x and iphone XR safe area */

  width: 100vw;
  max-width: 100%;
  min-height: 100vh;

  font-family: Arial, sans-serif;
  font-size: 14px;
  font-weight: normal;
  line-height: 1.42857143;
  text-rendering: optimizeLegibility;
  -webkit-text-rendering: optimizeLegibility;

  color: white;
  background-color: #fff;
}

/*
 * Remove text-shadow in selection highlight:
 * https://twitter.com/miketaylr/status/12228805301
 *
 * Vendor-prefixed and regular ::selection selectors cannot be combined:
 * https://stackoverflow.com/a/16982510/7133471
 *
 * Customize the background color to match your design.
 */

::-moz-selection {
  background: #b3d4fc;
  text-shadow: none;
}

::selection {
  background: #b3d4fc;
  text-shadow: none;
}

/*
 * A better looking default horizontal rule
 */

hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #ccc;
  margin: 1em 0;
  padding: 0;
}

/*
 * Remove the gap between audio, canvas, iframes,
 * images, videos and the bottom of their containers:
 * https://github.com/h5bp/html5-boilerplate/issues/440
 */

audio,
canvas,
iframe,
img,
svg,
video {
  vertical-align: middle;
}

/*
 * Remove default fieldset styles.
 */

fieldset {
  border: 0;
  margin: 0;
  padding: 0;
}

/*
 * Allow only vertical resizing of textareas.
 */

textarea {
  resize: vertical;
}

/* ==========================================================================
   Browser Upgrade Prompt
   ========================================================================== */

.browserupgrade {
  margin: 0.2em 0;
  background: #ccc;
  color: #000;
  padding: 0.2em 0;
}

/* ==========================================================================
   Author's custom styles
   ========================================================================== */

button {
  display: block;
  position: relative;

  font-family: 'Roboto Condensed', Arial, sans-serif;
  font-weight: bold;
  text-transform: uppercase;
  line-height: 30px;

  top: 0;
  background: #2d498d;
  border: 0;
  border-radius: 20px;
  color: white;
  width: 100%;
  white-space: nowrap;
  margin: 0;
  padding: 0 5px;
  cursor: pointer;
  transition: top 0.3s linear;
}

button:active {
  top: 5px;
  filter: saturate(200%);
}

button svg {
  fill: white;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Roboto Condensed', sans-serif;
  font-weight: bold;
}

a,
a:visited {
  color: blue;
}

.plus-brand {
  font-family: 'Roboto Condensed', sans-serif;
  font-weight: normal;
  font-style: italic;

  display: inline;
}

.plus-brand b {
  font-family: 'Roboto Condensed', sans-serif;
  font-weight: bold;
}

dl,
dt,
dd {
  font-weight: normal;
}

/* ==========================================================================
   EXAMPLE Media Queries for Responsive Design.
   These examples override the primary ('mobile first') styles.
   Modify as content requires.
   ========================================================================== */

@media only screen and (min-width: 35em) {
  /* Style adjustments for viewports that meet the condition */
}

@media print, (-webkit-min-device-pixel-ratio: 1.25), (min-resolution: 1.25dppx), (min-resolution: 120dpi) {
  /* Style adjustments for high resolution devices */
}

/* ==========================================================================
   Print styles.
   Inlined to avoid the additional HTTP request:
   http://www.phpied.com/delay-loading-your-print-css/
   ========================================================================== */

@media print {
  *,
  *:before,
  *:after {
    background: transparent !important;
    color: #000 !important;
    /* Black prints faster:
                                   http://www.sanbeiji.com/archives/953 */
    box-shadow: none !important;
    text-shadow: none !important;
  }

  a,
  a:visited {
    text-decoration: underline;
  }

  a[href]:after {
    content: ' (' attr(href) ')';
  }

  abbr[title]:after {
    content: ' (' attr(title) ')';
  }

  /*
     * Don't show links that are fragment identifiers,
     * or use the `javascript:` pseudo protocol
     */

  a[href^='#']:after,
  a[href^='javascript:']:after {
    content: '';
  }

  pre {
    white-space: pre-wrap !important;
  }

  pre,
  blockquote {
    border: 1px solid #999;
    page-break-inside: avoid;
  }

  /*
     * Printing Tables:
     * http://css-discuss.incutio.com/wiki/Printing_Tables
     */

  thead {
    display: table-header-group;
  }

  tr,
  img {
    page-break-inside: avoid;
  }

  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3;
  }

  h2,
  h3 {
    page-break-after: avoid;
  }
}

/********** End of Boilerplate ***********/

/*********** GLOBAL - CUSTOM *************/
*,
*:before,
*:after {
  box-sizing: border-box;
}

/* ios11 form inside fixed elements bug workaround */
html.is-form-open,
html.is-form-open > body {
  min-height: 100%;
  position: fixed;
  user-select: none;

  -webkit-overflow-scrolling: touch !important;
  overflow: auto !important;
  height: 100% !important;
}

body.swiper-on {
  overflow: hidden !important;
  max-height: 100% !important; /* 100% to make it easier to deal with ios keyboard */
}
body.swiper-on .nav-page {
  overflow: hidden !important;
}

button svg.icon,
a svg.icon {
  fill: white;
  width: 1.6em;
  height: 1.8em;
  top: -0.15em;
  margin-right: 0.25em;
}

button:hover,
a:hover {
  color: white;
}

button:hover svg.icon,
a:hover svg.icon {
  fill: white;
}

i.action {
  display: inline-block;
  color: #3761c5;

  font-family: 'Roboto Condensed', sans-serif;
  font-weight: bold;
  font-style: normal;
  text-transform: uppercase;
}
i.action svg {
  width: 20px;
  height: 20px;
  background: #3761c5;
  border-radius: 50%;
  overflow: hidden;
  fill: white;
  margin-right: 0.35em;
  position: relative;
  top: -0.15em;
}

code {
  background: cornsilk;
  padding: 5px 10px;
  border-radius: 4px;
  display: block;

  font-family: 'Roboto Condensed', sans-serif;
  font-weight: 300;
}

h2.subhead {
  color: #2d498d;

  text-align: left;
  text-transform: uppercase;
  font-size: 14px;
}

h4 {
  color: #2d498d;
  margin: 1em 0 0.5em;

  font-weight: bold;
  font-size: 14px;
  text-transform: uppercase;
}

svg.icon {
  width: 1.2em;
  height: 1.1em;
  position: relative;
  top: -0.15em;
  /*margin-left: -0.1em;*/
  margin-right: 0.5em;
  fill: #bbb;
  vertical-align: middle;
  display: inline-flex;
  align-self: center;
  overflow: visible;
}

.product-name {
  display: inline-block;
  /* -webkit-transform: skewX(-5deg);
    -moz-transform: skewX(-5deg);
    -ms-transform: skewX(-5deg);
    transform: skewX(-5deg) !important; */

  font-family: 'Roboto Condensed', Arial, sans-serif;
  font-weight: 300;
  /* text-transform: uppercase; */
}
/* .product-name > b {
        /* font-weight: bold; */
/* font-family: 'Roboto Condensed', Arial, sans-serif;
    } */

iframe[style*='2147483647'] {
  display: none;
}

/***** tags - mostly used in card view *****/
.tags {
  display: block;
  text-align: center;
}
.tagW {
  display: inline-block;
  background: #efdbff;
  color: #003470;
  border-radius: 8px;
  padding: 0.25em 0.75em;
  margin: 0 0 0 5px;
  font-family: 'Roboto Condensed', Arial, sans-serif;
  font-weight: normal;
  font-size: 10px;
  text-transform: uppercase;
}
.tag {
  display: inline-block;
  background: #d5e4f7;
  color: #003470;
  border-radius: 8px;
  padding: 0.25em 0.75em;
  margin: 0 0 0 5px;

  font-family: 'Roboto Condensed', Arial, sans-serif;
  font-weight: normal;
  font-size: 10px;
  text-transform: uppercase;
}
.tag time {
  padding: 0;
}
.tagWork {
  display: inline-block;
  background: #1ec780;
  color: #ffffff;
  border-radius: 8px;
  padding: 0.25em 0.75em;
  margin: 0 0 0 5px;

  font-family: 'Roboto Condensed', Arial, sans-serif;
  font-weight: normal;
  font-size: 10px;
  text-transform: uppercase;
}

.tag:before {
  font-size: 0.8em;
  text-transform: uppercase;
  font-family: 'Roboto Condensed', Arial, sans-serif;
  font-weight: bold;
}

.tag.won-revenue:before {
  content: 'Won revenue ';
}

.tag.projected-revenue:before {
  content: 'Projected revenue ';
}

.tag span svg {
  fill: #003470;
}

.tags .icon svg {
  fill: #003470;
}

/***** tags inside a ul list - mostly used in tile view *****/

ul.tags {
  list-style: none;
  margin: 0;
  padding: 0;
  position: absolute;
  left: 10px;
  right: 10px;
  bottom: 10px;
  white-space: nowrap;
  overflow: hidden;

  font-family: 'Roboto Condensed', Arial, sans-serif;
  font-weight: normal;
  font-size: 10px;
}

ul.tags > li {
  display: inline-block;
  margin: 0.25em 0 0.25em 0.25em;
}

ul.tags > li:first-child {
  margin-left: 0;
}

ul.tags a {
  background: rgba(0, 0, 0, 0.05);
  padding: 4px 10px;
  border-radius: 20px;
  display: block;
  color: #4db7ed;
  /*** specific color for Driver tags ***/
}

ul.tags a:hover {
  color: #003470;
}

.icon.right {
  position: absolute;
  right: 20px;
  top: 0;
}

/***********************************
 ******** up or down caret *********
 ***********************************/

.caret {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 2px;
  vertical-align: middle;
  border-top: 4px dashed;
  border-top: 4px solid \9;
  border-right: 4px solid transparent;
  border-left: 4px solid transparent;
}
.caret.up {
  border-top: 0;
  border-bottom: 4px dashed;
  border-bottom: 4px solid \9;
  content: '';
}

/* up or down indicator */
.up:before {
  content: '▲';
  color: green;
  padding-right: 5px;
}
.down:before {
  content: '▼';
  color: red;
  padding-right: 5px;
}

/* notifications for alerts */
label.badge {
  position: absolute;
  background: red;
  color: white;
  border-radius: 10px;
  padding: 0 6px;
  height: 20px;
  min-width: 20px;

  font-family: 'Roboto Condensed', Arial, sans-serif;
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;

  pointer-events: none;
}

/**** time element ****/

time {
  display: block;

  font-family: 'Roboto Condensed', Arial, sans-serif;
  font-weight: normal;
  text-align: right;

  /* background: #e8e8e8; */
  color: gray;

  border-radius: 20px;
  padding: 3px 10px;
}

time:before {
  font-size: 0.8em;
  text-transform: uppercase;
  font-family: 'Roboto Condensed', Arial, sans-serif;
  font-weight: bold;
}

time.due:before {
  content: 'Due ';
}

time.archived:before {
  content: 'On Hold ';
}

time.lost:before {
  content: 'Lost ';
}

time.completed:before {
  content: 'Won ';
}

time.timeline:before {
  content: 'Timeline ';
}

time.shared-by:before {
  content: 'Shared by: ';
}

time.shared:before {
  content: 'Shared ';
}

time.new:before {
  content: 'Alerted ';
}

time.viewed:before {
  content: 'Viewed ';
}

time.accepted:before {
  content: 'Since ';
}

time.pending:before {
  content: 'Invited ';
}

time.watched:before {
  content: 'Watched ';
}

/* a horizontal avatar / name construct using definition list markup */
.handle {
  display: inline-flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: flex-start;
  margin: 0;
  padding: 5px;
  background: #f1f1f1;
  border-radius: 50px;

  font-family: 'Roboto Condensed', Arial, sans-serif;
  font-weight: normal;
  font-size: 14px;
  line-height: 1 !important;
}
.handle .avatar {
  background: #87cbed;
  color: white;
  border-radius: 50%;
  width: 2em !important;
  height: 2em !important;
  position: relative;
  overflow: hidden;
  padding-top: 0.5em;

  text-align: center;
  font-size: 1em;
  font-weight: bold;
}
.handle .avatar svg {
  position: absolute;
  width: 50%;
  height: 50%;
  fill: white;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
}
.handle .name {
  padding: 0 5px;
  margin: 0;
  font-size: 1em;
  white-space: nowrap;
}
.handle .name svg {
  margin-right: 0;
  margin-left: 5px;
  top: 0;
}

.handle.org .avatar {
  background: #2d498d;
}

/********* the caret thing ************/
.caret-wrapper {
  display: block;
  border-radius: 50%;
  background: #2d498d;
  position: relative;

  width: 1.5em;
  height: 1.5em;
}
.caret-wrapper > .caret {
  font-size: 1.3em;
  position: absolute;
  color: white;
  left: 50%;
  top: 50%;
  margin-left: 0;
  transform: translateX(-50%) translateY(-50%) scale(1.3);
}

/* if there's a .company following a .contact, add a pipe between */
.target-name .contact ~ .company:before {
  content: ' » ';
}

.target-name .contact {
  font-size: 14px;
  font-weight: normal;
}

/* this is the little question mark, not the popup itself */
.poptip {
  display: inline-block;
  border-radius: 50px;
  width: 14px;
  height: 14px;
  background: cornsilk;
  border: 1px wheat solid;
  text-align: center;
  position: relative;
  top: -0.15em;
  cursor: pointer;
}
.poptip:after {
  content: '?';
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  color: tan;

  font-size: 11px;
  font-weight: normal;
}

.poptip:hover,
.poptip:active {
  background: wheat;
  stroke-width: 20px;
}

.poptip:hover:after {
  color: gray;
}

svg.poptip {
  border: 0;
  background: transparent;
}

/* override tip styling when used on valuebars in the Viewer::Overview report */
.poptip.valuebars {
  border: 1px white solid;
}
.poptip.valuebars:after {
  content: '';
}

/* this is the tip popup */
.chartist-tooltip {
  position: fixed;
  display: inline-block;
  pointer-events: none;
  min-width: 5em;
  max-width: 300px;
  padding: 0.5em 1em;
  background: rgba(0, 0, 0, 0.5);
  color: white;
  border-radius: 3px;
  z-index: 100;
  opacity: 0;
  transform: translateX(-50%) translateY(calc(-100% - 25px));
  transition:
    0.3s opacity cubic-bezier(0, 0.75, 0, 1),
    0.3s transform cubic-bezier(0, 0.75, 0, 1);

  font-family: Arial, sans-serif;
  font-size: 11px;
  font-weight: normal;
  text-align: center;
}
.chartist-tooltip:after {
  content: '';
  position: absolute;
  top: 100%;
  left: 50%;
  width: 0;
  height: 0;
  margin-left: -10px;
  border: 10px solid transparent;
  border-top-color: rgba(0, 0, 0, 0.5);
}

.chartist-tooltip.tooltip-show {
  opacity: 0;
  transform: translateX(-50%) translateY(calc(-100% - 10px));
}

.__react_component_tooltip {
  text-align: center;
  min-width: 5em;
  max-width: 300px;
  color: white;
  background: rgba(0, 0, 0, 0.7) !important;
  transition:
    0.3s opacity cubic-bezier(0, 0.75, 0, 1),
    0.3s transform cubic-bezier(0, 0.75, 0, 1);

  font-family: Arial, sans-serif;
  font-size: 11px;
  font-weight: normal;
  opacity: 1;
}

.__react_component_tooltip.place-top::after {
  border: 10px solid transparent;
  border-top-color: rgba(0, 0, 0, 0.5) !important;
  bottom: -16px !important;
}

.__react_component_tooltip.place-left::after {
  border: 10px solid transparent;
  border-left-color: rgba(0, 0, 0, 0.5) !important;
  right: -16px !important;
}

/******* end of temporary code *******/
.mt-10 {
  margin-top: 10px;
}

.mb-10 {
  margin-bottom: 10px;
}

.d-block {
  display: block;
}

*:not(html) {
  /* -webkit-transform: translate3d(0, 0, 0); */
  -webkit-overflow-scrolling: touch;
}

/*** the success / failure message popup ***/
.toastr {
  background: #eff1f6 !important;
  border-radius: 4px !important;
  color: #2d498d !important;
}
.toastr.rrt-success {
  border: 1px white solid !important;
  outline: 3px rgba(0, 122, 255, 0.5) solid;
}
.toastr.rrt-success svg {
  fill: #2d498d !important;
}
.toastr.rrt-error {
  border: 1px rgba(190, 30, 45, 1) solid !important;
  outline: 3px rgba(190, 30, 45, 0.5) solid;
}
.toastr.rrt-error svg {
  fill: rgba(190, 30, 45, 1) !important;
}

.rrt-confirm-holder .rrt-confirm .rrt-message {
  color: #2d498d;
}
.rrt-confirm-holder .rrt-confirm .rrt-buttons-holder button.rrt-ok-btn {
  color: #000;
}
.rrt-confirm-holder .rrt-confirm .rrt-buttons-holder button.rrt-cancel-btn {
  color: red;
}
.rrt-confirm-holder .rrt-confirm .rrt-buttons-holder .rrt-button.rrt-cancel-btn:active {
  background-color: #f5f5f5 !important;
}
.rrt-confirm-holder .rrt-confirm .rrt-buttons-holder .rrt-button.rrt-ok-btn:active {
  background-color: #f5f5f5 !important;
  color: #000 !important;
}

/***********************************
 ********** score colors ***********
 ***********************************/

/* currently used only in insights */
.score-100 {
  background-color: rgb(39, 102, 50) !important;
  color: white;
}
.score-99 {
  background-color: rgb(43, 103, 49) !important;
  color: white;
}
.score-98 {
  background-color: rgb(47, 104, 49) !important;
  color: white;
}
.score-97 {
  background-color: rgb(50, 105, 48) !important;
  color: white;
}
.score-96 {
  background-color: rgb(54, 107, 48) !important;
  color: white;
}
.score-95 {
  background-color: rgb(58, 108, 47) !important;
  color: white;
}
.score-94 {
  background-color: rgb(62, 109, 47) !important;
  color: white;
}
.score-93 {
  background-color: rgb(65, 110, 46) !important;
  color: white;
}
.score-92 {
  background-color: rgb(69, 111, 46) !important;
  color: white;
}
.score-91 {
  background-color: rgb(73, 112, 45) !important;
  color: white;
}
.score-90 {
  background-color: rgb(77, 113, 44) !important;
  color: white;
}
.score-89 {
  background-color: rgb(80, 115, 44) !important;
  color: white;
}
.score-88 {
  background-color: rgb(84, 116, 43) !important;
  color: white;
}
.score-87 {
  background-color: rgb(88, 117, 43) !important;
  color: white;
}
.score-86 {
  background-color: rgb(92, 118, 42) !important;
  color: white;
}
.score-85 {
  background-color: rgb(95, 119, 42) !important;
  color: white;
}
.score-84 {
  background-color: rgb(99, 120, 41) !important;
  color: white;
}
.score-83 {
  background-color: rgb(103, 121, 40) !important;
  color: white;
}
.score-82 {
  background-color: rgb(107, 123, 40) !important;
  color: white;
}
.score-81 {
  background-color: rgb(110, 124, 39) !important;
  color: white;
}
.score-80 {
  background-color: rgb(114, 125, 39) !important;
  color: white;
}
.score-79 {
  background-color: rgb(118, 126, 38) !important;
  color: white;
}
.score-78 {
  background-color: rgb(122, 127, 38) !important;
  color: white;
}
.score-77 {
  background-color: rgb(125, 128, 37) !important;
  color: white;
}
.score-76 {
  background-color: rgb(129, 129, 37) !important;
  color: white;
}
.score-75 {
  background-color: rgb(133, 131, 36) !important;
  color: white;
}
.score-74 {
  background-color: rgb(137, 132, 35) !important;
  color: white;
}
.score-73 {
  background-color: rgb(141, 133, 35) !important;
  color: white;
}
.score-72 {
  background-color: rgb(144, 134, 34) !important;
  color: white;
}
.score-71 {
  background-color: rgb(148, 135, 34) !important;
  color: white;
}
.score-70 {
  background-color: rgb(152, 136, 33) !important;
  color: white;
}
.score-69 {
  background-color: rgb(156, 137, 33) !important;
  color: white;
}
.score-68 {
  background-color: rgb(159, 138, 32) !important;
  color: white;
}
.score-67 {
  background-color: rgb(163, 140, 32) !important;
  color: white;
}
.score-66 {
  background-color: rgb(167, 141, 31) !important;
  color: white;
}
.score-65 {
  background-color: rgb(171, 142, 30) !important;
  color: white;
}
.score-64 {
  background-color: rgb(174, 143, 30) !important;
  color: white;
}
.score-63 {
  background-color: rgb(178, 144, 29) !important;
  color: white;
}
.score-62 {
  background-color: rgb(182, 145, 29) !important;
  color: white;
}
.score-61 {
  background-color: rgb(186, 146, 28) !important;
  color: white;
}
.score-60 {
  background-color: rgb(189, 148, 28) !important;
  color: white;
}
.score-59 {
  background-color: rgb(193, 149, 27) !important;
  color: white;
}
.score-58 {
  background-color: rgb(197, 150, 26) !important;
  color: white;
}
.score-57 {
  background-color: rgb(201, 151, 26) !important;
  color: white;
}
.score-56 {
  background-color: rgb(204, 152, 25) !important;
  color: white;
}
.score-55 {
  background-color: rgb(208, 153, 25) !important;
  color: white;
}
.score-54 {
  background-color: rgb(212, 154, 24) !important;
  color: white;
}
.score-53 {
  background-color: rgb(216, 156, 24) !important;
  color: white;
}
.score-52 {
  background-color: rgb(219, 157, 23) !important;
  color: white;
}
.score-51 {
  background-color: rgb(223, 158, 23) !important;
  color: white;
}
.score-50 {
  background-color: rgb(227, 159, 22) !important;
  color: white;
}
.score-49 {
  background-color: rgb(226, 156, 22) !important;
  color: white;
}
.score-48 {
  background-color: rgb(225, 154, 23) !important;
  color: white;
}
.score-47 {
  background-color: rgb(225, 151, 23) !important;
  color: white;
}
.score-46 {
  background-color: rgb(224, 149, 24) !important;
  color: white;
}
.score-45 {
  background-color: rgb(223, 146, 24) !important;
  color: white;
}
.score-44 {
  background-color: rgb(222, 144, 25) !important;
  color: white;
}
.score-43 {
  background-color: rgb(222, 141, 25) !important;
  color: white;
}
.score-42 {
  background-color: rgb(221, 138, 26) !important;
  color: white;
}
.score-41 {
  background-color: rgb(220, 136, 26) !important;
  color: white;
}
.score-40 {
  background-color: rgb(219, 133, 27) !important;
  color: white;
}
.score-39 {
  background-color: rgb(219, 131, 27) !important;
  color: white;
}
.score-38 {
  background-color: rgb(218, 128, 28) !important;
  color: white;
}
.score-37 {
  background-color: rgb(217, 125, 28) !important;
  color: white;
}
.score-36 {
  background-color: rgb(216, 123, 29) !important;
  color: white;
}
.score-35 {
  background-color: rgb(216, 120, 29) !important;
  color: white;
}
.score-34 {
  background-color: rgb(215, 118, 30) !important;
  color: white;
}
.score-33 {
  background-color: rgb(214, 115, 30) !important;
  color: white;
}
.score-32 {
  background-color: rgb(213, 113, 31) !important;
  color: white;
}
.score-31 {
  background-color: rgb(213, 110, 31) !important;
  color: white;
}
.score-30 {
  background-color: rgb(212, 107, 32) !important;
  color: white;
}
.score-29 {
  background-color: rgb(211, 105, 32) !important;
  color: white;
}
.score-28 {
  background-color: rgb(210, 102, 33) !important;
  color: white;
}
.score-27 {
  background-color: rgb(210, 100, 33) !important;
  color: white;
}
.score-26 {
  background-color: rgb(209, 97, 34) !important;
  color: white;
}
.score-25 {
  background-color: rgb(208, 95, 34) !important;
  color: white;
}
.score-24 {
  background-color: rgb(207, 92, 34) !important;
  color: white;
}
.score-23 {
  background-color: rgb(206, 89, 35) !important;
  color: white;
}
.score-22 {
  background-color: rgb(206, 87, 35) !important;
  color: white;
}
.score-21 {
  background-color: rgb(205, 84, 36) !important;
  color: white;
}
.score-20 {
  background-color: rgb(204, 82, 36) !important;
  color: white;
}
.score-19 {
  background-color: rgb(203, 79, 37) !important;
  color: white;
}
.score-18 {
  background-color: rgb(203, 76, 37) !important;
  color: white;
}
.score-17 {
  background-color: rgb(202, 74, 38) !important;
  color: white;
}
.score-16 {
  background-color: rgb(201, 71, 38) !important;
  color: white;
}
.score-15 {
  background-color: rgb(200, 69, 39) !important;
  color: white;
}
.score-14 {
  background-color: rgb(200, 66, 39) !important;
  color: white;
}
.score-13 {
  background-color: rgb(199, 64, 40) !important;
  color: white;
}
.score-12 {
  background-color: rgb(198, 61, 40) !important;
  color: white;
}
.score-11 {
  background-color: rgb(197, 58, 41) !important;
  color: white;
}
.score-10 {
  background-color: rgb(197, 56, 41) !important;
  color: white;
}
.score-9 {
  background-color: rgb(196, 53, 42) !important;
  color: white;
}
.score-8 {
  background-color: rgb(195, 51, 42) !important;
  color: white;
}
.score-7 {
  background-color: rgb(194, 48, 43) !important;
  color: white;
}
.score-6 {
  background-color: rgb(194, 45, 43) !important;
  color: white;
}
.score-5 {
  background-color: rgb(193, 43, 44) !important;
  color: white;
}
.score-4 {
  background-color: rgb(192, 40, 44) !important;
  color: white;
}
.score-3 {
  background-color: rgb(191, 38, 45) !important;
  color: white;
}
.score-2 {
  background-color: rgb(191, 35, 45) !important;
  color: white;
}
.score-1 {
  background-color: rgb(190, 33, 46) !important;
  color: white;
}
.score-0 {
  background-color: rgb(189, 30, 46) !important;
  color: white;
}
.score-none {
  background-color: #ddd !important;
  color: white;
}
