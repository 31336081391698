/**** overall page settings ****/

section#insights {
  /*background: #eaeaea;*/
}

section#insights header.intro {
  border-radius: 0;
  -webkit-user-select: none;
}

section#insights #insights-swiper {
  padding-top: 0;
}

/********** dashboard ***********/

section#insights .ai-dashboard {
  padding-top: 0;
}

.insights-container .excerpt {
  position: absolute;
  /* left: 15px; */
  /* right: 15px; */
  top: 100px;
}

.nudge {
  display: flex;
  flex-flow: row nowrap;
  justify-content: stretch;
  align-items: flex-start;
  gap: 15px;

  margin-top: 10px;
  font-size: 16px;
  line-height: 1.2;

  font-family: 'Roboto Condensed', Arial, sans-serif;
}

.nudge .data {
  flex: 1 1 40%;
  display: inline-block;
  font-weight: bold;
  text-align: right;
  color: #2d498d;
}

.nudge .label {
  flex: 1 1 60%;
  text-align: left;
  font-weight: normal;
  margin: 0;
}

.nudge .score {
  font-size: 20px;
  color: white;
  transform: none;
}

.nudge .score b.label {
  padding: 5px 10px;
  border-radius: 8px;
  font-family: 'Roboto Condensed', sans-serif;
  font-weight: bold;
}

.nudge .days {
  text-align: center;
  font:
    normal 20px/20px 'Roboto Condensed',
    'Helvetica Neue',
    Helvetica,
    Arial,
    sans-serif;
  color: #2d498d;
  padding: 5px;
  display: inline-block;
  border-bottom: 1px #2d498d solid;
}

.objective .nudge {
  display: inline-block;
  background: white;
  border: 1px lightgray solid;
  padding: 5px 10px;
  border-radius: 6px;
}

article.tile.insights h1 {
  font-size: 20px;
}
article.insights h1 svg {
  width: 20px;
  height: 20px;
}

article.tile.insights .tags,
article.card.insight .tags {
  bottom: 15px;
}

article.tile.insights span.icon {
  background: #2d498d;
  width: 24px;
  height: 24px;
  margin-right: 0;
  display: block;
  border-radius: 50%;
  position: relative;
}

article.tile.insights .tags svg,
article.card.insight .tags svg {
  width: 14px;
  height: 14px;
  fill: white;
  position: absolute;
  top: calc(50% - 1px);
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  margin-right: 0;
}

/****** Insights open button ******/

/* js adds "on-"+the id number as a class to the body element */
body.page-tiles.sub-active #insights-panel-button {
  opacity: 1;
  pointer-events: all;
  transform: scale(1, 1) rotate(0);
}

body.page-tiles.swiper #insights-panel-button,
body.page-insights.swiper #insights-panel-button {
  opacity: 0;
  pointer-events: none;
}

.ai-dashboard#insights-new:before {
  content: 'New Insights';
}

.ai-dashboard#insights-viewed:before {
  content: 'Already Viewed';
}

article.tile time {
  position: absolute;
  right: 15px;
  bottom: 15px;
}

article.tile time.viewed {
  background-color: #e8e8e8;
  color: gray;
}

/****** Insights - Tiles ******/
/* specific to a strip within a Insights tile */
article.tile.insight .strip.insights > li {
  margin-left: 0;
  max-width: 100%;
}

/****** Insights - Cards ******/

article.card.insight h1:before {
  content: 'Insight';
}

article.card.insight h2.objective:before {
  content: 'Observation';
}

article.card.insight h3 {
  font-weight: normal;
}

article.card.insight .block.data {
  padding: 20px;
  padding-top: 20px;
  padding-top: 30px;
  background: #2d498d;
  height: 230px;
  overflow: hidden;
  position: relative;
  text-align: center;
}

article.card.insight .block.data .nudge {
  align-items: center;

  background: white;
  border-radius: 6px;
  font-size: 1.5em;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  padding: 1.5em 1em;
  margin: 0;
  max-width: calc(100% - 40px);
  width: 100%;
}

article.card .block.suggestion {
  padding: 25px 20px 20px 20px;
}

article.card .block.suggestion:before {
  content: 'Suggested Next Steps';
  font-family: 'Roboto Condensed', Arial, sans-serif;
  font-weight: bold;
  font-size: 12px;
  text-transform: uppercase;
  position: absolute;
  left: 20px;
  top: 13px;
  color: #2d498d;
}

.sub-text {
  font-size: 12px;
  text-align: center;
  align-content: center;
  font-weight: normal;
  color: #224099;
}

.insights-text {
  text-align: center;
  padding: 0px 10px !important;
}

.insights-chart {
  padding: 5px 10px !important;
}

.user-revenue-total,
.user-steps-total {
  background-color: #224099;
  width: 100px;
  height: 35px;
  border-radius: 30px;
  text-align: center;
  line-height: 35px;
  font-family: 'Roboto Condensed', Arial, sans-serif;
  font-weight: bold;
  font-size: 26px;
  color: white;
  margin: auto;
}

.user-steps-total {
  width: 50px;
}

.tile-value-container {
  margin: 0 10px;
  text-align: center;
  overflow: hidden;
}

.tile-value-name,
.tile-value-total {
  display: inline-block;
  width: 32%;
  text-align: center;
  font-weight: normal;
}

.tile-value-name {
  font-size: 12px;
  color: gray;
}

.tile-value-total {
  height: 30px;
  background-color: #224099;
  /* fallback background color */
  color: white;
  line-height: 30px;
}

div.least-amount {
  background-color: #90a6e6 !important;
}

div.normal-amount {
  background-color: #576eb3 !important;
}

div.most-amount {
  background-color: #224099 !important;
}

.insights-tile-container {
  text-align: center;
}

.insights-revenue-tile {
  min-width: 100%;
  max-width: 100%;
  height: 130px;
  background-color: #576eb3;
  color: white !important;
  font-size: 18px;
  display: inline-block;
  margin: 2px;
  border-radius: 12px;
  overflow-x: hidden;
  overflow-y: auto;
}

.insights-revenue-tile h3 {
  text-align: left;
  margin: 5px 10px 0px 10px;
}

.insights-revenue-tile h4 {
  text-align: left;
  margin: 0px 10px !important;
}

div.revenue-row {
  display: flex;
  flex-direction: row;
  text-align: left;
}

div.revenue-row .revenue-date {
  padding-left: 10px;
}

div.revenue-row .revenue-quantity {
  width: 100%;
  text-align: right;
  padding-right: 10px;
  font-weight: normal;
}

/****** Insights - inspiring messages ******/

article.card .main .inspiring-messages {
  color: black;
  font-size: 14px;
  margin: 10px 20px;
  padding: 1px 10px !important;
  background-color: lightgray;
  border-radius: 7px;
}

article.card .main .inspiring-messages img {
  width: 20px;
  float: left;
  padding-right: 5px;
}

article.card .main > .block.insights-activity {
  text-align: center;
  color: white;
}

article.card .main > .block.insights-activity h2 {
  white-space: inherit;
  color: gray;
}

article.card .main > .block.insights-activity > .activity h4 {
  font-weight: 600;
  margin: 0 auto;
}

article.card .main > .block.insights-activity > .activity > .recent-activity {
  font-size: 16px;
}

article.card .main div.block.insights-activity ul.activity {
  background-color: #576eb3;
  margin: 4px;
  padding-bottom: 8px;
  border-radius: 12px;
}

/****** responsive ******/

@media (min-width: 481px) {
  /*section#insights article.card header {
    height: 380px;
  }
  section#insights article.card main {
    height: calc(100% - 380px);
  }*/
  .tile-value-name {
    font-size: 10px;
  }

  .insights-revenue-tiles {
    width: 100%;
    margin: 0 auto;
  }
}

@media (min-width: 600px) {
  div.revenue-row .revenue-quantity {
    width: 160px;
  }

  .insights-revenue-tile {
    max-width: 215px;
    min-width: 150px;
  }

  .insights-button {
    bottom: 90px;
  }
}
