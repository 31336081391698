.react-autosuggest__container {
  position: relative;
}

.react-autosuggest__suggestions-list {
  position: absolute;
  z-index: 10;
  border-radius: 0 0 4px 4px;
  border: 1px #2d498d solid;
  list-style: none;
  margin: 0;
  padding: 0;
  left: 0;
  right: 0;
  overflow: hidden;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
}

.react-autosuggest__suggestion {
  padding: 10px;
  text-align: left;
  border-top: 1px #2d498d solid;
  background: white;
  cursor: pointer;
  color: gray;
  position: relative;
}
.react-autosuggest__suggestion span {
  color: #222;
}
.react-autosuggest__suggestion small {
  display: block;
}

.react-autosuggest__suggestion:hover {
  background: #ececec;
  color: black;
}
.react-autosuggest__suggestion:active {
  background: #2d498d;
  color: white;
  top: 3px;
  z-index: 2;
}
.react-autosuggest__suggestion:active span,
.react-autosuggest__suggestion:active small {
  color: white;
}

.react-autosuggest__suggestion--highlighted {
  background-color: #2d498d;
  color: #fff;
}

.react-autosuggest__input--focused {
  outline: none;
}

.cancel-search {
  float: right;
  color: #2d498d;
  display: none;
}

.front .height-100 .cancel-search {
  display: block;
}

.dialog.collaborationDl .box .search-con-outer .react-autosuggest__suggestions-list {
  width: calc(100% + 97px);
  left: -97px;
  top: 60px;
  overflow: auto;
  max-height: 200px;
}

@media (min-width: 767px) {
  .react-autosuggest__suggestions-container {
    position: absolute;
    z-index: 10;
    width: 100%;
  }

  .dialog.collaborationDl .box .search-con-outer .react-autosuggest__suggestions-list {
    top: 10px;
    left: -114px;
    width: calc(100% + 114px);
    max-height: 383px;
  }

  .react-autosuggest__suggestions-list {
    position: relative;
  }
}

dialog.collaborationDl .box .search-container .react-autosuggest__container {
  width: 90% !important;
}
