/***********************************
 *********** base styles ***********
 ***********************************/

form,
fieldset {
  font-family: Arial, sans-serif;
}

input {
  background: white;
  border: 1px rgba(0, 0, 0, 0.2) solid;
  display: block;
  padding: 0.5em 1em;
  font-size: 13px;
  height: 35px;
  border-radius: 4px;
  font-family: Arial, sans-serif;
  width: 100%;
  -webkit-transform: translateZ(0) !important;
  transform: translateZ(0) !important;
}

input[disabled] {
  cursor: not-allowed;
  background-color: #eee;
  color: gray;
  opacity: 1;
}

input[type='submit'],
input[type='button'] {
  cursor: pointer;
  height: 60px;
  line-height: 1;
  color: white;
  background-color: #2d498d;
  font-size: 15.6px;
  text-transform: uppercase;
  font-family: 'Roboto Condensed', Arial, sans-serif;
  font-weight: bold;
}
input[type='submit']:active,
input[type='button']:active {
  background-color: #2d498d;
}

label.radio_labels {
  padding-right: 10px;
}

/***********************************
 ********** sets of fields *********
 ***********************************/

fieldset {
  padding: 10px 20px 40px 20px;
}

fieldset legend {
  text-align: left;
  color: #003470;
  text-transform: uppercase;
  font-family: 'Roboto Condensed', Arial, sans-serif;
  font-weight: bold;
  background: #efefef;
  padding: 0;
  margin-bottom: 10px;
  width: 100%;
  position: relative;
}

fieldset legend:after {
  content: '';
  position: absolute;
  border-top: 1px #003470 solid;
  left: 10px;
  top: 23px;
  right: 0;
}

fieldset legend svg.icon {
  width: 16px;
  height: 16px;
  /*border-radius: 50%;*/
  margin: 0;
  margin-right: 20px;
  top: 2px;
  left: 5px;
  fill: white;
  /*background: #2d498d;*/
  /*padding: 7px;*/
  margin-left: -11px;
}
fieldset legend:before {
  content: '';
  position: absolute;
  left: -13px;
  top: -2px;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background: #003470;
}

fieldset input,
fieldset ul.button-list span {
  background: white;
  border: 1px rgba(0, 0, 0, 0.2) solid;
  display: block;
}

fieldset p {
  margin-bottom: 5px;
}

fieldset p:first-of-type {
  margin-top: 0;
}

/**** big action buttons at the bottom of forms ****/

.actions {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  position: absolute;
  z-index: 1;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.05);
  padding: 10px;
}

article.card.flipped > .back .inner > form {
  /*height: calc(100% - 72px);*/
  overflow-y: auto;
}

article.card.flipped > .back .inner .actions {
  bottom: 0;
  top: auto;
  position: relative;
  background: #efefef;
}

.actions button {
  display: block;
  border: 1px solid #2d498d;
  width: auto;
  border-radius: 8px;
  font-size: 1.5em;
  margin: 0;
  padding: 0 1em;
  font-family: 'Roboto Condensed', Arial, sans-serif;
  font-weight: bold;
  text-transform: uppercase;
}

.actions > button:first-child {
  margin-top: 0;
}

.actions button.cancel {
  background: lightgray;
  border: 0;
  color: gray;
}

.actions button.cancel:hover {
  color: #2d498d;
}

.field-line ~ .field-line {
  margin-top: 0.5em;
}

/***********************************
 *** label / inputs on same line ***
 ***********************************/

fieldset div.form-group label {
  float: left;
  text-align: right;
  width: 107px;
  padding: 5px !important;
}

fieldset div.form-group input {
  width: calc(100% - 107px);
  height: 30px;
}

/***********************************
 ************ field line ***********
 ***********************************/

/**** a horizontal item that attaches something to the right edge of the field ****/

.field-line {
  display: flex;
  position: relative;
  /*border-left: 2px transparent solid;*/
}

.field-line > * {
  flex: 1 1 auto;
  height: 50px;
}

.field-line > input:first-child {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}

.field-line > input:last-child {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}

.field-line > input,
.field-line > textarea {
  padding: 18px 6px 6px 15px;
  font-size: 16px;
  line-height: 1.42857143;
  color: #555;
  background-color: #fff;
  background-image: none;
  border: 1px solid #ccc;

  padding-left: 15px;
  transition:
    0.3s padding-left cubic-bezier(0, 0.75, 0, 1),
    0.3s background-color cubic-bezier(0, 0.75, 0, 1);
}

.field-line.decorated > textarea {
  font-size: 14px;
  font-style: normal;
  height: auto;
  width: 100%;
}

.field-line > button {
  padding: 6px;
  font-size: 14px;
  font-family: 'Roboto Condensed', Arial, sans-serif;
  font-weight: normal;
  line-height: 1;
  color: #555;
  text-align: center;
  background: linear-gradient(to bottom, #f5f5f5, #eaeaea);
  background-color: #eee;
  border-radius: 4px;
  border-left: 0;
  border: 1px rgba(0, 0, 0, 0.1) solid;
  white-space: nowrap;
}

.field-line > a {
  padding: 6px;
  font-size: 14px;
  font-family: 'Roboto Condensed', Arial, sans-serif;
  font-weight: 300;
  line-height: 1;
  color: #555;
  text-align: center;
  background: linear-gradient(to bottom, #f5f5f5, #eaeaea);
  background-color: #eee;
  border-radius: 4px;
  border-left: 0;
  border: 1px rgba(0, 0, 0, 0.1) solid;
  white-space: nowrap;
}

.field-line > label {
  position: absolute;
  z-index: 1;
  left: 12px;
  top: 50%;
  height: auto;
  transform: translateY(-50%);
  pointer-events: none;
  font-family: 'Roboto Condensed', Arial, sans-serif;
  font-weight: bold;
  display: block;
  padding: 1px 5px;
  font-size: 14px;
  color: gray;
  transition:
    0.3s top cubic-bezier(0, 0.75, 0, 1),
    0.3s left cubic-bezier(0, 0.75, 0, 1),
    0.3s padding-left cubic-bezier(0, 0.75, 0, 1),
    0.3s color cubic-bezier(0, 0.75, 0, 1),
    0.3s font-size cubic-bezier(0, 0.75, 0, 1);
}

/* turn off the browser outline mode */
.field-line input:focus,
.field-line textarea:focus {
  outline: none;
}

/* the field is being edited */
.field-line.focused input,
.field-line.focused textarea {
  border-color: #007aff;
}

.field-line.focused label {
  top: 12px;
  font-size: 11px;
  color: #1f65a7;
}

/* the field is being edited */
.field-line.error input,
.field-line.error textarea,
.more-account input.error {
  border-color: #be1e2d;
}

.field-line.has-value input,
.field-line.has-value textarea {
  background: rgba(255, 255, 255, 0.5);
  color: #007aff;
}

.field-line.has-value label {
  top: 12px;
  font-size: 11px;
  color: gray;
}

/*
.field-line.has-value .action-sheet-btn {
  display: none;
}
*/

.field-line .action-sheet-btn.submit {
  background: #3d96dd;
  color: white;
  text-transform: uppercase;
  padding: 0 20px;
  border-radius: 0 4px 4px 0;
}

.field-line .action-sheet-btn.download {
  background: #2d498d;
  color: white;
  text-transform: uppercase;
  padding: 15px 0px;
  border-radius: 0 4px 4px 0;
}

.field-line .action-sheet-btn.download:hover,
.field-line .action-sheet-btn.submit:hover {
  background: #cfe5ff;
  color: #00639d;
}

/***********************************
 ******** field with an icon *******
 ***********************************/

/**** a horizontal item that attaches something to the right edge of the field ****/

.field-line.decorated {
  display: block;
  position: relative;
  border-radius: 4px;
}

#target-note {
  width: -webkit-fill-available;
}
.followup.lost, .followup.lost form {
  display: flex;
  justify-content: center;
  flex-direction: column;
  flex: 1;
}

.followup.lost fieldset {
  padding: 0;
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: space-around;
}

.followup.next-step .field-line.decorated,
.followup.lost .field-line.decorated {
  /* we add this blank placeholder issue in safari in input and textareas ios greater than 13*/
  background: #fff;
}

.field-line.decorated > input,
.field-line.decorated > textarea {
  border-radius: 4px;
  padding-right: 40px;
}

.followup.next-step .field-line.decorated > input,
.followup.next-step .field-line.decorated > textarea,
.followup.lost .field-line.decorated > input,
.followup.lost .field-line.decorated > textarea {
  /* we add this blank placeholder issue in safari in input and textareas ios greater than 13*/
  background-color: transparent;
}

.field-line.decorated > button {
  position: absolute;
  background: transparent;
  fill: #2d498d;
  white-space: nowrap;
  top: 0;
  right: 0px;
  width: auto;
  border: 1px #ccc solid;
  border-width: 1px 1px 1px 0;
}

.field-line.decorated > button:hover {
  background: #daecf9;
}

.field-line.decorated svg {
  width: 20px;
  height: 20px;
  margin-right: 0;
  fill: #2d498d;
}

.field-line.decorated > button:hover svg {
  fill: #2d498d;
}

.settings-field div.field-line.decorated svg {
  width: 16px;
  height: 16px;
  fill: #2d498d;
}

.field-line.decorated.focused {
  background: white;
}

.actions-block {
  display: flex;
  justify-content: center;
  align-items: center;
}
.actions-block > * {
  flex: 1 1 auto;
}

.button {
  display: block;
  background: #2d498d;
  border-radius: 4px;
  cursor: pointer;
  height: 60px;
  line-height: 1;
  color: white;
  background-color: #2d498d;
  font-size: 15.6px;
  text-transform: uppercase;
  font-family: 'Roboto Condensed', Arial, sans-serif;
  font-weight: bold;
}
.button.secondary {
  color: #007ffa;
  background: #efefef;
}
.button.secondary:hover {
  color: white;
  background: #007ffa;
}

/***********************************
 ******** input format types *******
 ***********************************/

span.currency {
  position: absolute;
  padding: 7px;
  font-size: 13px;
  display: inline-block;
}

/**** footer messages ****/

form footer .help {
  display: none;
}

form.clean footer .clean {
  display: block;
}

form.dirty footer .dirty {
  display: block;
}

form.done footer .done {
  display: block;
}

/*********************************************
 ********** friendly on/off buttons **********
 *********************************************/

.onoffswitch {
  font-family: 'Roboto Condensed', Arial, sans-serif;
  font-weight: normal;
  position: relative;
  width: 70px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
}

.onoffswitch-checkbox {
  display: none;
}

.onoffswitch-label {
  display: block !important;
  padding: 0 !important;
  overflow: hidden;
  cursor: pointer;
  border: 2px solid #999999;
  border-radius: 20px;
}

.onoffswitch-inner {
  display: block;
  width: 200%;
  margin-left: -100%;
  transition: margin 0.3s ease-in 0s;
  text-align: left;
}

.onoffswitch-inner:before,
.onoffswitch-inner:after {
  display: block;
  float: left;
  width: 50%;
  height: 28px;
  padding: 0;
  line-height: 28px;
  font-size: 14px;
  color: white;
  font-family: 'Roboto Condensed', Arial, sans-serif;
  font-weight: bold;
  box-sizing: border-box;
}

#inline-inputs {
  display: flex;
  justify-content: center;
  align-items: center;
}

#inline-inputs > .field-line {
  margin-top: 0;
}

#inline-inputs > div {
  flex: 1;
}

#inline-inputs > div input,fieldset > div#inline-input > div.field-line.company > input {
  border-radius: 0;
}

#inline-inputs > div:first-child input {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}
#inline-inputs > div:last-child input {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}

.onoffswitch-inner:before {
  content: 'ON';
  padding-left: 13px;
  background-color: #2d498d;
  color: #ffffff;
}

.onoffswitch-inner:after {
  content: 'OFF';
  padding-right: 10px;
  background-color: #eeeeee;
  color: #999999;
  text-align: right;
}

.onoffswitch-switch {
  display: block;
  width: 32px;
  margin: 0px;
  background: #ffffff;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 38px;
  border: 2px solid #999999;
  border-radius: 20px;
  transition: all 0.3s ease-in 0s;
}

.onoffswitch-checkbox:checked + .onoffswitch-label .onoffswitch-inner {
  margin-left: 0;
}

.onoffswitch-checkbox:checked + .onoffswitch-label .onoffswitch-switch {
  right: 0px;
}
