/***********************************
 ************* dialogs *************
 ***********************************/

.dialog {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  color: gray;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  pointer-events: none;
  opacity: 0;
  z-index: 200;
  padding: 0;
  /*height: 100vh; specifying a vh height can cause problems with ios keyboard */
  background: rgba(0, 0, 0, 0.5);
  transition: 0.3s opacity cubic-bezier(0, 0.75, 0, 1);
}

/* this is the centered wrapper for the visible portion of the dialog (.inner) */
.dialog > .box {
  width: 100%;
  height: 100%;
  max-height: calc(100% - env(safe-area-inset-bottom));
  position: relative;

  max-width: 600px;

  margin-top: -100px;
  transition:
    0.3s opacity cubic-bezier(0, 0.75, 0, 1),
    0.3s margin-top cubic-bezier(0, 0.75, 0, 1);
}

/* add the active class to make the dialog animate in from the top */
.dialog.active {
  opacity: 1 !important;
  pointer-events: all;
}
.dialog.active > .box {
  margin-top: 0;
}

/* this is the background of the visible portion of the dialog */
.dialog .box .inner {
  background: #efefef;
  border-radius: 0; /* use @media to specify 8px */
  padding: 10px;
  width: 100%;
  height: 100%;
  max-height: calc(100vh - 49px - 49px - 20px); /* both navs plus inner's padding */
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.5);
  position: relative;
  /* this is for all dialogs...need to override for create dialog, or the action buttons won't be visible with the keyboard open */
  overflow-x: hidden;
  overflow-y: auto;
}

.dialog.input .inner > form {
  overflow-x: hidden;
  overflow-y: auto;
}

.dialog.input .inner footer {
  height: 70px;
}

.dialog h2 {
  text-transform: uppercase;
  font-size: 14px;
  font-family: 'Roboto Condensed', Arial, sans-serif;
  font-weight: bold;
  color: #2d498d;
  text-align: center;
  line-height: 1.3;
  border-bottom: 1px #2d498d solid;
}

.dialog ul.menu {
  list-style: none;
  margin: 0 auto;
  padding: 0;
}

.dialog ul.menu > li {
  margin: 0;
  padding: 0;
  position: relative;
}
.dialog ul.menu > li ~ li.divider,
.dialog ul.menu > li.divider + li {
  border-bottom: 1px white solid;
}

.dialog ul.menu > li a {
  padding: 0.5em 1em;
  position: relative;
  color: gray;
  display: block;
  font-family: 'Helvetica Neue', Arial, sans-serif;
  font-weight: normal;
  text-decoration: none;
}

.dialog ul.menu input {
  padding: 0.5em 0.5em 0.5em 2em;
  width: 100%;
  position: relative;
  color: gray;
  display: block;
  font-family: 'Helvetica Neue', Arial, sans-serif;
  font-weight: normal;
  text-decoration: none;
}

.dialog button.action {
  border-radius: 6px;
  background: #2d498d;
  color: white;
  text-transform: uppercase;
  font-family: 'Roboto Condensed', Arial, sans-serif;
  font-weight: bold;
  border: 0;
  position: relative;
  top: 0;
  transition: top 0.3s linear;
}

.dialog .menu > .show-all {
  padding: 10px 0;
  border-top: 1px #2d498d solid;
}
.dialog .menu > .show-all > a {
  border-radius: 4px;
  padding: 1em;
  border: 1px white solid;
  color: #2d498d;
}
.dialog .menu > .show-all svg {
  fill: #2d498d;
}

.dialog button.action:active {
  top: 5px;
  filter: saturate(200%);
}

.dialog .search {
  margin-bottom: 1em;
  position: relative;
  margin-bottom: 1em;
}
.dialog .search input {
  padding-left: 30px;
}
.dialog .search > svg {
  position: absolute;
  top: 0.75em;
  left: 10px;
}

.dialog ul.menu > li.divider {
  border-top: 1px gray solid;
}

.dialog ul.menu > li:hover a {
  background: rgba(0, 0, 0, 0.1);
  color: #2d498d;
}
.dialog ul.menu > li:hover label {
  border-color: white;
}

.dialog ul.menu > li svg {
  margin-right: 0;
}

.dialog ul.menu > li.active a {
  background: #2d498d;
  color: white;
}

.dialog ul.menu > li.active svg {
  fill: white;
}

.dialog ul.menu.horz {
  display: flex;
  flex-flow: row nowrap;
}
.dialog ul.menu.horz > li {
  flex: 1 0 auto;
  padding: 0 2.5px;
}
.dialog ul.menu.horz > li a {
  border: 1px #2d498d solid;
  border-radius: 4px;
}
.dialog ul.menu.horz > li label {
  border: 0;
  width: auto;
  padding: 0;
  min-width: 0;
}

.dialog label.marker {
  position: absolute;
  right: 1em;
  border: 1px lightgray solid;
  border-radius: 4px;
  top: 50%;
  transform: translateY(-50%);
  padding: 0 1em;
  font-family: 'Roboto Condensed', Arial, sans-serif;
  font-weight: bold;
  line-height: 1.75;
  min-width: 52px;
  text-align: center;
}
.dialog li:not(.active) label.marker {
  background: white;
}
.dialog label.marker svg {
  height: 15px;
  fill: #2d498d;
}
.dialog li.active label.marker svg {
  fill: white;
}

.dialog label.overdue {
  background: #be1e2d !important;
  color: white;
}

.dialog label.soon {
  background: #e39f15 !important;
  color: white;
}

.dialog label.later {
  background: #266733 !important;
  color: white;
}

.close-dialog {
  position: absolute;
  z-index: 100;
  top: 5px;
  right: 5px;
  width: 22px;
  height: 22px;
  background: #3d96dd;
  fill: white;
  border-radius: 50%;
}

.close-dialog svg {
  position: absolute;
  /*fill: #2d498d;*/
  fill: white;
  left: 50%;
  top: 50%;
  width: 14px;
  height: 14px;
  transform: translateX(-50%) translateY(-50%);
}

/********** standardized info blocks **********/

.info-block {
  background: rgba(255, 255, 255, 1);
  border-radius: 8px;
  padding: 10px 12px 5px 12px;
}
* > .info-block:not(:last-child) {
  margin-bottom: 10px;
}

.info-block fieldset {
  padding: 15px 10px 10px 10px;
}
.info-block fieldset legend {
  background: transparent;
}

/* horizontal strips inside info blocks */
.info-block .strip {
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  margin: 10px 0;
}
.info-block .strip > li {
  padding: 0 2.5px;
}
.info-block .strip a.button.download {
  padding: 8px 15px;
  white-space: nowrap;
}
.info-block .strip a.button.download svg {
  margin-right: 0;
}
.info-block .strip a.button.download span {
  display: none;
}

.info-block .button,
.info-block select {
  padding: 10px 15px;
  height: auto;
}
.info-block select {
  position: relative;
  background: url(data:image/svg+xml;base64,PHN2ZyBpZD0iTGF5ZXJfMSIgZGF0YS1uYW1lPSJMYXllciAxIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA0Ljk1IDEwIj48ZGVmcz48c3R5bGU+LmNscy0xe2ZpbGw6I2ZmZjt9LmNscy0ye2ZpbGw6IzQ0NDt9PC9zdHlsZT48L2RlZnM+PHRpdGxlPmFycm93czwvdGl0bGU+PHJlY3QgY2xhc3M9ImNscy0xIiB3aWR0aD0iNC45NSIgaGVpZ2h0PSIxMCIvPjxwb2x5Z29uIGNsYXNzPSJjbHMtMiIgcG9pbnRzPSIxLjQxIDQuNjcgMi40OCAzLjE4IDMuNTQgNC42NyAxLjQxIDQuNjciLz48cG9seWdvbiBjbGFzcz0iY2xzLTIiIHBvaW50cz0iMy41NCA1LjMzIDIuNDggNi44MiAxLjQxIDUuMzMgMy41NCA1LjMzIi8+PC9zdmc+)
    no-repeat 95% 50%;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  padding: 14px 20px;
  padding-right: 30px;
  border-radius: 4px;
}

.info-block .button {
  background: #3d96dd;
  border-radius: 4px;
  color: white;
  /* border: 1px #2d498d solid; */
  text-decoration: none;
  text-transform: uppercase;
  font-size: 14px;
  font-family: 'Roboto Condensed', Arial, sans-serif;
  font-weight: bold;
}
.info-block .button svg {
  fill: white;
  margin-left: 0 !important;
}
.info-block .button:hover svg {
  fill: #00639d;
}
.info-block .button:hover {
  background: #cfe5ff;
  color: #00639d;
}

/********** switcher dialog **********/

#viewer-switcher .close-dialog {
  top: 5px;
}

#viewer-switcher > .box {
  height: auto;
  max-height: calc(100vh - 49px - 49px);
}

.dialog .info-block legend {
  text-transform: uppercase;
  white-space: nowrap;
}

/********** date range dialog **********/

#viewer-date-range {
  bottom: 49px;
}
#viewer-date-range > .box {
  width: calc(100% - 40px); /* since we're so nested, add horizontal space */
  margin-left: 20px;
  height: auto;
}
#viewer-date-range > .box .inner {
  display: flex;
  flex-flow: column nowrap;
  border-radius: 10px;
  height: 100%;
}
#viewer-date-range .inner > .info-block {
  flex: 1 1 auto;
  overflow-x: hidden;
  overflow-y: auto;
}
#viewer-date-range .inner > footer {
  flex: 0 0 60px;
}

#viewer-date-range .rdrDefinedRangesWrapper {
  display: none;
}

/*********** filter dialog **********/

/* unset the height here so we can get back our scrolling behavior */
.dialog.hud-dialog > .box {
  height: auto;
  max-height: calc(100vh - 49px - 49px);
}

/* this dialog will fit on all screen sizes */
.dialog.filter-dialog > .box {
  height: auto;
}

.dialog.filter-dialog fieldset a svg.icon,
.dialog.filter-dialog fieldset button svg.icon {
  top: 3px;
  padding: 1px;
  margin: -5px 0px 0px 10px;
}

.dialog .inline-search-filter svg.search {
  fill: #2d498d;
}

.dialog .inline-search-filter input {
  background: #efefef !important;
}

.dialog.filter-dialog fieldset button.snooze svg.icon {
  position: absolute;
  top: auto !important;
}

.dialog.filter-dialog .filter fieldset > ul.button-bar:not(:first-of-type) {
  margin-top: 20px;
}

/*********** collaboration dialog ************/

.dialog.collaborationDl {
  position: fixed;
  left: 0;
  right: 0;
  z-index: 101 !important;
  /*width: 100%;*/
  margin: auto;
  height: 100%;
  opacity: 0;
  transition:
    0.3s opacity cubic-bezier(0, 0.75, 0, 1),
    0.3s margin-top cubic-bezier(0, 0.75, 0, 1);
  border-radius: 0;
  bottom: 0;
  /*display: block;*/
}

.dialog.collaborationDl .box {
  position: relative;
  width: calc(100% - 40px);
  max-width: 610px;
  height: calc(100% - 200px);
  padding: 15px 20px;
  display: -webkit-box;
  display: -ms-flexbox;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  background: #fff;
  border-radius: 12px;
  margin-top: 0;
}

.dialog.collaborationDl .box .search-container {
  width: 100%;
}

.dialog.collaborationDl .box .search-con-outer button {
  width: 120px;
  max-width: 120px;
  margin-right: 15px;
  border-radius: 4px;
}

.search-con-outer {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  width: 100%;
}

@media (min-width: 600px) {
  .dialog > .box {
    height: calc(100% - 40px);
  }
  .dialog > .box .inner {
    border-radius: 10px;
  }

  #people .dialog.collaborationDl {
    left: 250px;
  }

  .dialog.collaborationDl .box {
    margin-top: 70px;
  }
}

@media (min-width: 400px) {
  .info-block .strip a.button.download span {
    display: inline-block;
  }
}
@media screen and (max-width: 520px) {
  .ytd-revenue-field-1 {
    width: 42% !important;
  }
  .ytd-revenue-equal {
    margin-left: 0 !important;
  }
  .ytd-revenue-field-2 {
    width: 50% !important;
  }
  .ytd-revenue-field-3 {
    width: calc(100% - 25px) !important;
  }
}

@media screen and (max-width: 430px) {
  .ytd-revenue-field-1 {
    width: calc(100% - 25px) !important;
  }
  .ytd-revenue-equal {
    margin-left: 10px !important;
  }
  .ytd-revenue-field-2 {
    width: calc(100% - 25px) !important;
  }
  .ytd-revenue-field-3 {
    width: 100% !important;
    margin-left: 0 !important;
  }
  .ytd-revenue-plus {
    margin-right: 0 !important;
  }
}

/*
@media (max-width: 350px) {

  .dialog > .box {
    padding: 0;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    transform: none;
  }
  .dialog .box .inner {
    border-radius: 0;
    height: 100%;
    padding: 5px;
  }

}

@media (max-width: 650px) {
  article.card div.context-container .dialog.collaborationDl {
    width: calc(100% - 20px);
    left: 10px;
    right: 10px;
    height: 96%;
    padding: 0 10px;
  }

  .dialog.collaborationDl .box {
    max-width: calc(100% - 60px);
    height: calc(100% - 180px);
    margin-top: -12px;
  }
}
*/
