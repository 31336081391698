/***********************************
 *** horizontal strip of elements **
 ***********************************/

/**** just a horizontal set of items ****/

ul.strip {
  display: flex;
  justify-content: space-between;
  flex-wrap: nowrap;
  margin: 0 auto;
  padding: 0;
  /* same as dashboard tiles */
  list-style: none;
}

/**** if it's a UI set, tweak styling to be buttons ****/

ul.strip.ui {
  width: auto;
  font-weight: normal;
  font-family: Arial, sans-serif;
  padding: 10px 10px 15px 10px;
}

ul.strip.ui > li {
  flex-wrap: nowrap;
}

ul.strip.ui a {
  display: block;
  padding: 0.5em;
  white-space: nowrap;
  text-decoration: none;
  font-size: 12px;
  color: #919191;
  border-radius: 4px;
  border: 1px #919191 solid;
  background: white;
  text-overflow: ellipsis;
  overflow: hidden;
}

ul.strip.ui a:hover,
ul.strip.ui a:active {
  border-color: #2d498d;
  color: #2d498d;
}

/***********************************
 ********* insights hud ***********
 ***********************************/

.strip.insights {
  width: 100%;
  list-style: none;
  margin: 0 auto;
  padding: 0;
  display: inline-block;
}

.strip.insights > li {
  width: 100%;
  min-width: 100%;
  max-width: 100%;
  color: gray;
  text-align: center;
  padding: 3px;
  margin: 10px 0px 30px 0px;
  display: inline-block;
  float: left;
}

.strip.insights > li > dl {
  border: 1px rgba(0, 0, 0, 0.15) solid;
  cursor: pointer;
}

.strip.insights > li dt {
  text-transform: uppercase;
}

.strip.insights li.score dl {
  background: linear-gradient(to right, #bd1e2e 0, #e39f16 49%, #276734 100%);
}

.strip.insights li.score span.score {
  position: absolute;
  text-align: center;
}

.strip.insights li.score b.label {
  color: white;
  background: transparent;
}

.strip.insights b.label {
  font-size: 16px;
}

.strip.insights span.them {
  position: absolute;
  transform: translateX(-50%);
  background-color: transparent;
  font-weight: 200;
  top: 1px;
  line-height: 2.2;
}

.strip.insights span.you {
  font-family: 'Roboto Condensed', Arial, sans-serif;
  font-weight: bold;
  position: absolute;
  transform: translateX(-50%);
  background-color: transparent;
  top: -9px;
}

.strip.insights span.you small {
  font-family: 'Roboto Condensed', Arial, sans-serif;
  font-weight: bold;
  color: black;
  background-color: transparent;
}

.strip.insights span.them:after {
  content: '';
  position: absolute;
  z-index: -1;
  top: 25px;
  bottom: 10px;
  left: 50%;
  border-left: 2px dotted gray;
  opacity: 0.5;
  transform: translate(-50%);
}

.strip.insights span.you:after {
  content: '';
  position: absolute;
  z-index: -1;
  top: 25px;
  bottom: 10px;
  left: 50%;
  border-left: 1px solid black;
  transform: translate(-50%);
}

.strip.insights span.you b.label {
  font-size: 16px;
  line-height: 2.2;
}

.strip.insights span.them b.label {
  font-size: 13px;
}

.strip.insights span.top {
  color: green;
}

.strip.insights span.top small {
  color: green;
}

ul.strip li.score dd {
  display: block;
}

/***********************************
 *********** driver hud ************
 ***********************************/

dl.stat.bar.driver-progress:before {
  background: #4db7ed;
}

/***********************************
 ********* a stat, like fb *********
 ***********************************/

dl.stat {
  list-style: none;
  margin: 0;
  padding: 0;
  line-height: 1.2;
  height: 30px;
  border-radius: 4px;
  position: relative;
  display: block;
  width: 100%;
}

dl.stat.bar-title {
  width: 100%;
  margin: 0 auto;
  height: 14px;
}

dl.stat > dt,
dl.stat > dd {
  display: inline-block;
  margin: 0;
  padding: 0;
  line-height: 1.7;
}

dl.stat > dt {
  font-family: 'Roboto Condensed', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-weight: bold;
  height: 15px;
  position: absolute;
  left: 0;
  top: -18px;
  right: 0;
  display: block;
  text-align: center;
  line-height: inherit;
  color: gray;
}

dl.stat > dd {
  color: black;
  font-family: 'Roboto Condensed', Arial, sans-serif;
  font-weight: bold;
  font-size: 120%;
  white-space: nowrap;
}

dl.stat > dd.negative {
  color: red;
}

dl.stat > dd.positive {
  color: green;
}

dl.stat > dd small {
  color: #b5b4b4;
  font-weight: normal;
  display: block;
  position: relative;
  top: 3px;
  left: 0;
  right: 0;
  line-height: 1;
  white-space: nowrap;
  /*font-family: Arial, sans-serif;*/
  font-size: 12px;
  background: white;
}

dl.stat dd.stat-details {
  font-size: 11px;
  /*padding: 0px 10px 0px 0px;*/
  line-height: 2.5;
  color: white;
  float: left;
  opacity: 0.5;
  text-transform: uppercase;
}

dl.stat dd.stacked {
  display: flex;
}

dl.stat .overdue {
  color: white;
  font-weight: 200;
  background: #be1e2d;
  height: 29px;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}

dl.stat .soon {
  color: white;
  font-weight: 200;
  background: #e39f15;
  height: 29px;
}

dl.stat .later {
  color: white;
  font-weight: 200;
  background: #266733;
  height: 29px;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}

dl.stat.bar:before {
  /*content: "Average\00a0";*/
  content: '';
  font-family: 'Roboto Condensed', Arial, sans-serif;
  font-weight: bold;
  color: lightgray;
  position: absolute;
  line-height: 2;
  text-align: right;
  left: 0;
  top: 0;
  bottom: 0;
  background: #ececec;
  border-right: 1px lightgray solid;
}

.data .bar {
  padding: 3px;
  background: #efefef;
  color: white;
  text-align: right;
  font-size: 80%;
  position: absolute;
  height: 30px;
  top: 50%;
  transform: translateY(-50%);
}

.data .bar svg {
  position: absolute;
  left: 5px;
  top: 0;
  margin: 5px;
  width: 20px;
  height: 20px;
  fill: white;
}

.data .bar span {
  display: block;
  text-align: right;
  height: 30px;
  line-height: 25px;
}

.data .bar.later {
  background: #266733;
}

.data .bar.soon {
  background: #e39f15;
}

.data .bar.overdue {
  background: #be1e2d;
}

.data .bar.dormant {
  background: #d6d5d5;
}

/***********************************
 *********** motivator ************
 ***********************************/

.motivator .meter {
  position: relative;
  background: linear-gradient(to right, #bd1e2e 0, #e39f16 49%, #276734 100%);
  border-radius: 4px;
  height: 30px;
  width: 100%;
}

.motivator .legend {
  font-size: 1.3em;
  font-family: 'Roboto Condensed', Arial, sans-serif;
  font-weight: bold;
  line-height: 1.5;
  color: white;
  opacity: 0.4;
  text-transform: uppercase;
}

.motivator .legend > small {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.motivator .legend > small.procrastinator {
  left: 10px;
}

.motivator .legend > small.rainmaker {
  right: 10px;
}

.meter span.score {
  position: absolute;
  display: block;
  top: -18px;
  width: 40px;
  height: 44px;
  margin-left: -20px;
  transition: all 0.5s cubic-bezier(0, 0.75, 0, 1);
  text-align: center;
}

.meter span.score em {
  text-align: center;
  display: block;
  height: 15px;
  font-style: normal;
  font-family: 'Roboto Condensed', Arial, sans-serif;
  font-weight: bold;
  text-transform: uppercase;
  color: gray;
  position: static;
}

.meter span.score b.label {
  display: inline-block;
  color: white;
  background: transparent !important;
  font-size: 24px;
  font-family: 'Roboto Condensed', Arial, sans-serif;
  font-weight: bold;
  height: 30px;
  text-align: center;
  position: static;
  text-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
}

.meter .score,
.meter .score b {
  width: 40px;
}

.meter .score.score-100,
.meter .score.score-100 b {
  width: 55px;
}

.meter span.score.firm b.label {
  font-weight: normal;
}

.meter span.score.firm em {
  color: lightgray;
}

.meter span.score.you em {
  color: black;
}

.meter span.score.score-100 {
  margin-left: -55px;
}

.meter span.score.score-0 {
  margin-left: 0;
}

/* left and right edges of the motivator */

.strip.motivator {
  margin: 20px 0px;
}

.strip.motivator > li {
  padding: 0 2.5px;
}

.strip.motivator dl.stat > dt {
  text-transform: uppercase;
}

.strip.motivator dl.stat > dd.positive .caret {
  color: green;
}

.strip.motivator dl.stat > dd.negative .caret {
  color: red;
}

.strip.motivator > li.steps > dl,
.strip.motivator > li.revenue > dl {
  border: 0.5px lightgray solid;
}

.strip.motivator > li.steps small {
  left: 0;
  right: auto;
}

.strip.motivator > li.revenue small {
  left: auto;
  right: 0;
}

@media (min-width: 320px) {
  .strip.insights > li {
    min-width: 100%;
  }
}

@media (min-width: 530px) {
  .strip.insights > li {
    min-width: 75%;
    max-width: 100%;
  }
}

@media (min-width: 875px) {
  .strip.insights > li {
    min-width: calc(100% / 3);
    max-width: 25%;
  }
}

@media (min-width: 1220px) {
  .strip.insights > li {
    min-width: 25%;
    max-width: calc(100% / 3);
  }
}

/*@media (min-width: 1565px) {
  .strip.insights > li {
    min-width: calc(100% / 5);
    max-width: 25%;
  }
}*/
