/**** overall page settings ****/

.viewer-sub-header {
  width: 100%;
  background: white;
  text-align: center;
  height: 40px;
  z-index: 999999;
  position: absolute;
  padding-top: 5px;
  box-shadow: 0 0 30px rgb(0 0 0 / 20%);
}

section#viewer iframe {
  width: 100vw;
  height: 100vh;
  border: 0;
  position: absolute;
  left: 0;
}

section#viewer .block-wrapper > div.active {
  height: calc(100vh - 49px);
  overflow-x: hidden;
  padding-bottom: 0;
}

/* for the prototype only */
/* section#viewer img {
    width: 100%;
    max-width: 100%;
} */

/* individual panels inside each report */
section.panel {
  background: white;
  padding: 12px;
  border-radius: 6px;
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.1);
  margin-bottom: 2em;
  min-height: 65px;
  position: relative;
}

section.panel > header {
  text-align: center;
  border-bottom: 0.75px lightgray solid;
  margin-left: -12px;
  margin-right: -12px;
  padding-bottom: 10px;
}
section.panel > header p {
  margin: 0;
}
section.panel > .main {
  text-align: center;
}
section.panel > footer {
  text-align: center;
  border-top: 0.75px lightgray solid;
  margin-left: -12px;
  margin-right: -12px;
  padding-top: 10px;
}

section.panel.parameters {
  z-index: 10;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  /*align-items: center;*/
  align-content: center;
}
section.panel.parameters.expanded {
  background: white;
  color: #003470;
}
section.panel.parameters > p {
  margin: 0 0 5px 0;
}

/*
section.panel .rdrDateRangePickerWrapper {
    margin-top: 1em;
    margin-bottom: 1em;
}
*/

/* date picker, mobile-first ... override for bigger screens below */

.rdrDateRangePickerWrapper {
  display: flex;
  flex-direction: column;
}
.rdrMonth {
  width: auto;
}
.rdrPprevButton {
  margin-left: 7px;
  margin-right: 0;
}
.rdrNextButton {
  margin-left: 0;
  margin-right: 7px;
}
.rdrDefinedRangesWrapper {
  width: 100%;
}
.rdrDefinedRangesWrapper button {
  border-radius: 0;
}

.rdrStaticRanges {
  flex-flow: row wrap;
}

.rdrDateDisplayItem {
  box-shadow: none;
  border: 1px gray solid;
}
.rdrDateDisplayItem input {
  cursor: text;
}
.rdrDateDisplayItemActive {
  border-color: rgb(61, 145, 255);
}
.rdrDateDisplayItemActive input {
  color: rgb(61, 145, 255);
}

.rdrStaticRanges {
  border: 1px gray solid;
  border-radius: 4px;
}
.rdrStaticRanges button {
  color: unset;
  flex: 1 1 33.334%;
}

.rdrInputRangeInput {
  padding: unset;
}

.rdrStaticRangeSelected .rdrStaticRangeLabel,
.rdrStaticRangeSelected:hover .rdrStaticRangeLabel {
  background: rgb(61, 145, 255);
  color: white !important;
}
.rdrStaticRangeSelected:active .rdrStaticRangeLabel {
  background: #003470;
}

/********* orgchart popup *********/

/* used only if we go back to the table view for orgchart
table.tree tr.group > td:first-child {
    padding-left: 40px;
}
    table.tree tr.user > td:first-child {
        padding-left: 80px;
    }
*/

#viewer-switcher fieldset > p {
  margin-bottom: 1em;
}

.orgchart .node {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;

  position: relative;

  background: white;
  border: 1px lightgray solid;
  border-radius: 4px;
  margin: 7px 0;
  padding: 8px;
  color: #202124;
  cursor: pointer;
}
.orgchart .node dl.handle {
  flex: 1 0 70%;
  padding: 0;
  background: transparent;
}
.orgchart .node .context {
  display: none; /* mobile first */
  flex: 1 0 30%;
  font-size: 9px;
  font-style: italic;
  padding-right: 5px;
  text-align: right;
  color: gray;
}
/* indent users */
.orgchart .node.user {
  margin-left: 10%;
  text-decoration: none;
  color: #202124;
}

.orgchart .help {
  margin-left: 10%;
  color: gray;
  font-size: 13px;
}

/* handle the active state */
.orgchart .node.active {
  background: #003470;
  color: white;
  border-color: #003470;
}
.orgchart .node.active .avatar {
  background: white;
  color: #003470;
}
.orgchart .node.active .avatar svg {
  fill: #003470;
}
.orgchart .node.active .context {
  color: white;
}

.orgchart .node:hover {
  background: #3d96dd;
  border-color: #3d96dd;
  color: white;
}
.orgchart .node:hover > svg {
  fill: white;
}
.orgchart .node:hover .avatar {
  background: white;
  color: #3d96dd;
}
.orgchart .node:hover .avatar svg {
  fill: #3d96dd;
}
.orgchart .node:hover .context {
  color: white;
}

section.panel .no-results-found {
  border: 0;
}

/* the title of each report */
section#viewer header.intro {
  margin-bottom: 2em;
  /*padding: 0 30px;*/
  background: transparent;
  box-shadow: none;
  padding: 0;
}

header.intro > .title {
  position: relative;
}

header.intro h2 {
  white-space: nowrap;
  margin: 0;
  font-weight: normal;
}

header.intro .mgmt-report-dl {
  position: absolute;
  top: 0;
  right: 0;
  font-family: 'Roboto Condensed', sans-serif;
  font-weight: normal;
}

section#viewer header.intro p {
  margin: 0.5em 0 0 0;
  padding: 0.5em 0 0 0;
  border-top: 1px gray solid;
  text-align: left;
}

/* download buttons on the mgmt report */
.mgmt-report-dl {
  display: inline-flex;
  align-content: center;
  line-height: 1;
}
.mgmt-report-dl:before {
  content: 'Download As: ';
  display: none;
  font-size: 80%;
  text-transform: uppercase;
  line-height: 2.5;
  margin-right: 0.5em;
}

.mgmt-report-dl .tags {
  display: inline-block;
}

.mgmt-report-dl .tag {
  background: white;
  vertical-align: top;
  cursor: pointer;
  border-radius: 30px;
  line-height: 2;
}

.mgmt-report-dl .tag:hover {
  background: #003470;
  color: white;
}

.mgmt-report-dl .tag:hover svg {
  fill: white;
}

.mgmt-report-dl .tag svg {
  width: 2em;
  height: 2em;
  fill: #003470;
}

.mgmt-report-dl .tags a {
  text-decoration: none;
}

/************ ai-frame overrides ************/

section#viewer nav ul a {
  padding: 0.25em 0.5em;
}
section#viewer nav .handle,
.dialog nav .org-chart .handle {
  background: transparent;
}

.handle.initiator dt {
  background: #003470;
}

.handle.group .avatar {
  background: #1497d8;
}

.ai-frame .go-back {
  position: absolute;
  left: 30px;
  top: 0;
  width: auto;
  display: flex;
  background: white;
  padding: 0 0.5em;
  margin: 0;
  color: #003470;
  outline: none;
  border-radius: 0 0 4px 4px;
  box-shadow: 0 0 10px rgb(0 0 0 / 5%);
}
.ai-frame .go-back > .icon-wrapper {
  display: block;
  width: 16px;
  height: 16px;
  margin-right: 10px;
}
.ai-frame .go-back > small {
  padding-right: 10px;
}
.ai-frame .go-back > .user-name {
  padding-left: 10px;
  border-left: 1px #efefef solid;
  padding-right: 5px;
}
.ai-frame .go-back > span svg.icon {
  fill: #003470;
}
.ai-frame .go-back > span svg.icon {
  fill: #003470;
}
.ai-frame .go-back > small {
  text-transform: uppercase;
}

.ai-frame .go-back:hover {
  background: #3d96dd;
}
.ai-frame .go-back:hover > span svg.icon {
  fill: white;
}
.ai-frame .go-back:hover * {
  color: white;
}

.ai-frame .go-back:active {
  outline: none;
}

/************ data table overrides ************/

td > .handle {
  background: transparent;
}

td > .colleague-list.just-info > li {
  margin: 2px;
  padding: 2.5px;
  height: auto;
}

/* the details/summary structure ... summary contains the header, the rest contains one or more params */
details.report-params {
  display: block;
  padding: 0;
  margin-bottom: 2em;
  border: 0;
  border-radius: 6px;
  overflow: hidden;

  font-family: 'Roboto Condensed', Arial, sans-serif;
}
details.report-params summary {
  /* do not set to display:block or it will remove the :marker */
  position: relative;
  margin-bottom: inherit;

  padding: 1em;
  border-radius: 0;
  cursor: pointer;
  border: 1px transparent solid;
  border-width: 0 0 1px 0;
  background: #003470;
  color: white;
}
/* change the standard chrome/webkit summary marker */
details.report-params summary:marker,
details.report-params summary:-webkit-details-marker {
  color: white;
}
details.report-params summary:before {
  position: absolute;
  z-index: -1;

  content: '';

  width: 25px;
  height: 25px;
  left: 6px;
  top: 10px;

  background: white;
  border-radius: 50%;
  color: #003470;
}
/* this is the message when it's expanded */
details.report-params summary:after {
  position: absolute;
  padding-left: 10px;
  padding-right: 10px;

  content: 'Select configuration options for this report...';
  color: gray;
  font-style: italic;

  display: none;
}

/* when the details block is open */
details.report-params[open] {
  border: 1px lightgray solid;
  background: #ffffff;
}
details.report-params[open] summary {
  border-width: 0 0 1px 0;
  border-radius: 6px 6px 0 0;
  background: transparent;
  color: #003470;
}
details.report-params[open] summary {
  margin-bottom: auto;
}
/* hide this paragraph, and show it only when [open] */
details.report-params[open] summary > p {
  display: none;
}
details.report-params[open] summary:after {
  display: inline;
}

/* text inside the summary element */
details.report-params summary p {
  display: inline; /* inline-block adds space at top on mobile */
  margin: 0;
}
/* styling variables inside the summary paragraph */
details.report-params summary span {
  display: inline;
  background: transparent;
  border-radius: 3px;
  font-weight: bold;
}
/* the report-tag inside the closed summary */
details.report-params summary .report-tag {
  background: transparent;
  border: 0.5px white solid;
  border-radius: 3px;
  margin: 2px 8px;
  padding: 3px 8px;
}
details.report-params summary .report-tag svg {
  width: 1em;
  height: 1em;
  fill: white;
  margin-right: 5px;
}

/* one parameter, with label, help text, value, presets, etc */
details.report-params > .param {
  display: block;
  margin: 10px 0;
}

/* the label on top of the param */
details.report-params > .param label {
  display: flex;
  flex-flow: row nowrap;
  align-items: flex-end;
  justify-content: stretch;

  font-family: 'Roboto Condensed', sans-serif;
  font-weight: normal;
  font-size: 12px;

  padding: 4px 8px;
  background: transparent;
  color: #003470;
  padding: 0;
  margin: 0;
  border-bottom: 1px #003470 solid;
}
/* the label name itself */
details.report-params label span {
  display: inline-block;
  padding: 2px 12px 0 12px;
  border-radius: 3px 3px 0 0;

  text-transform: uppercase;
  font-weight: bold;
  white-space: nowrap;
}
/* the text description next to the label name */
details.report-params > .param label small {
  margin-left: 10px;
  padding-right: 12px;
  color: #003470;

  text-transform: unset;
  font-size: 100%;
}

/* the value selection structure */
details.report-params > .param .input-wrapper {
  display: flex;
  flex-flow: column nowrap;
  align-items: stretch;
  justify-content: stretch;
}

/* selection, to the left of presets */
details.report-params > .param .value {
  /* min-width: 260px; */
  /* flex: 1 0 260px; */
  padding: 10px;
  /* border-right: 1px lightgray solid; */
}

/* presets, to the right of the selection */
details.report-params > .param .presets {
  /* flex: 1 1 auto; */
  /* margin: 10px; */
  position: relative;
  overflow: hidden;
}
details.report-params > .param .scroller {
  padding: 10px;
}
/* list of presets in a row */
details.report-params .presets ul {
  list-style: none;
  margin: 0;
  padding: 0;

  display: inline-flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: flex-start;
}
details.report-params .presets ul > li {
  flex: 1 1 auto;
  padding: 0;
  margin: 0 5px 0 0;
}
details.report-params .presets li a {
  display: flex;
  align-items: center;

  padding: 6px 10px;
  white-space: nowrap;

  text-decoration: none;

  background: white;
  border-radius: 4px;
  color: #003470;
  border: 1px lightgray solid;
}
details.report-params .presets li a:hover {
  background: #e3f4fc;
  border-color: #003470;
}

details.report-params .presets li a.active {
  background: #003470;
  color: white;
  border-color: #003470;
}
details.report-params .presets li a.active .avatar {
  background: white;
}
details.report-params .presets li a.active svg {
  fill: #003470;
}

/* hide the presets help text for the org-chart param on mobile */
details.report-params .org-view .presets {
  display: none;
}

/* left/right fading to indicate there is more being cut off */
details.report-params .presets:before,
details.report-params .presets:after {
  content: '';
  position: absolute;
  z-index: 100;
  top: 0;
  bottom: 0;
  width: 60px;
  pointer-events: none;
  opacity: 0;
  transition: opacity 0.5s;
}
details.report-params .presets:before {
  left: 0;
  background: linear-gradient(-90deg, rgba(243, 243, 243, 0) 0%, rgba(243, 243, 243, 1) 100%);
}
details.report-params .presets:after {
  right: 0;
  background: linear-gradient(90deg, rgba(243, 243, 243, 0) 0%, rgba(243, 243, 243, 1) 100%);
}
details.report-params .presets.off-left:before,
details.report-params .presets.off-right:after {
  opacity: 1;
}

/* buttons inside the entire param, both .selection and .presets */
details.report-params .param .selection {
  display: inline-flex;
  justify-content: space-between;
  align-items: center;

  position: relative;
  cursor: pointer;

  background: #3d96dd;
  /* border: 1px #003470 solid; */
  border-radius: 4px;
  color: #003470;

  text-transform: none;
  overflow: hidden;

  padding: 10px;
  height: auto;
}
details.report-params .param .selection > .marker {
  width: 28px;
  height: 28px;
  position: relative;
}
details.report-params .param .selection > .marker svg {
  position: absolute;
  width: 14px;
  height: 14px;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  fill: #003470;
}
details.report-params .param .selection .arrow {
  flex: 0 1 2em;
  position: relative;
}

details.report-params .param .selection .handle > .name {
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 128px;
}

details.report-params .handle {
  padding: 0;
  background: transparent;
}
details.report-params .param .selection .avatar {
  background: transparent;
}
details.report-params .param .selection svg {
  fill: #003470;
}

details.report-params .selection.date-range .marker {
  flex: 0 0 28px;
}
details.report-params .selection.date-range .range {
  flex: 1 1 auto;
  padding-left: 5px;
}
details.report-params .selection.date-range .arrow {
  flex: 0 0 22px;
}
details.report-params .selection.date-range .end:before {
  content: '→';
  display: inline-block;
  padding: 0 0.25em;
}

details.report-params .selection .caret-wrapper {
  width: 22px;
  min-width: 22px;
  height: 22px;
  min-height: 22px;
  background: #ffffff;
  margin-left: 0.5em;
}
details.report-params .selection .caret-wrapper .caret {
  color: #3d96dd;
}

/* the selection buttons when active (selected) */
details.report-params .param .selection.active {
  background: #3d96dd;
  color: #fff;
}
details.report-params .param .selection.active svg {
  fill: white;
}
details.report-params .param .selection.active .caret-wrapper {
  background: white;
}
details.report-params .param .selection.active .caret {
  color: #3d96dd;
}

/* the selection buttons when hovering ... this definition has to be in css after .active */
details.report-params .param .selection:hover {
  background: #cfe5ff;
  /* border-color: #00639d; */
  color: #00639d;
}
details.report-params .param .selection:hover svg {
  fill: #00639d;
}
details.report-params .param .selection:hover .caret-wrapper {
  background: white;
}
details.report-params .param .selection:hover .caret {
  color: #00639d;
}

.report-tag {
  display: inline-block;
  padding: 2px 4px;
  margin: 0 5px;
  background: white;
  border-radius: 4px;
  font-family: 'Roboto Condensed', Arial, sans-serif;
}
.report-tag svg {
  margin-right: 0.1em;
  fill: gray;
}

.dialog .report-tag {
  border: 0.75px lightgray solid;
}

.param .mbsc-form-control-wrapper {
  visibility: hidden;
  position: absolute;
  width: 0;
  height: 0;
  opacity: 0;
}

.mbsc-popup-buttons {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;
  padding: 10px !important;
}
.mbsc-popup-button-close {
  flex: 1 1 35%;
}
.mbsc-popup-button.btn.submit {
  flex: 1 1 65%;
  background: #3d96dd;
  color: white;
  border-radius: 4px;
}
.mbsc-popup-button.btn.submit:hover,
.mbsc-popup-button.btn.submit.mbsc-hover {
  background: #cfe5ff;
  color: #00639d;
  opacity: 1;
}
.mbsc-ios-dark.mbsc-button-flat {
  color: #3d96dd;
}

.mbsc-ios-dark.mbsc-range-control-value.active {
  color: #3d96dd;
}
.mbsc-ios-dark.mbsc-calendar-button.mbsc-button {
  color: #3d96dd;
}

.mbsc-ios-dark.mbsc-selected .mbsc-calendar-cell-text {
  background: #3d96dd;
  border-color: #3d96dd;
}

/* .mbsc-ios-dark.mbsc-range-day:after{
    background-color: 
} */

/************ the chart section of reports ************/

#graph {
  position: relative;
}

#graph .chart {
  position: relative;
  z-index: 1;
  margin: 1em 0;
  height: 250px;
}
section.panel .height-loader {
  margin: 1em 0;
  height: 250px;
  overflow: hidden;
}
section.panel .height-loader svg {
  width: 100%;
  height: 100%;
}

section.panel.chart {
  padding-top: 10px;
  padding-bottom: 10px;
  max-height: 400px;
  min-height: 370px;
}

section.panel.chart header {
  text-align: center;
  border-bottom: 1px lightgray solid;
  margin-left: -12px;
  margin-right: -12px;
  padding-bottom: 10px;
}

section.panel.table header {
  text-align: center;
  border-bottom: 1px lightgray solid;
  margin-left: -12px;
  margin-right: -12px;
  padding-bottom: 10px;
}

section.panel.chart footer {
  text-align: center;
  border-top: 1px lightgray solid;
  margin-left: -12px;
  margin-right: -12px;
  padding-top: 10px;
}

/* the chart loader image */
section.panel.chart > svg {
  margin-top: 2em;
}

/* override settings in global.css */
.tip {
  background: #e3f4fc;
  color: black;
}

.tip:after {
  border-top-color: #e3f4fc;
}

.tip.org {
  background: #e3f4fc;
  color: black;
}

.tip.org:after {
  border-top-color: #e3f4fc;
}

.tip.group {
  background: #003470;
  color: white;
}

.tip.group:after {
  border-top-color: #003470;
}

.tip.user {
  background: black;
  color: white;
}

.tip.user:after {
  border-top-color: black;
}

/*********** the tabular data ***********/

section.panel.table {
  /*overflow-x: auto;*/
  /*overflow-y: hidden;*/
  padding: 0;
}

table.mgmt {
  min-width: 100%;
  border-collapse: collapse;
  table-layout: fixed;
  font-family: Arial, sans-serif;
  font-size: 12px;
  line-height: 1;
  user-select: none;
}

/* standard column settings */

table.mgmt colgroup col {
  border-left: 0.5px #f3f3f3 solid;
}

table.mgmt col.sorted {
  background: rgba(227, 254, 251, 0.3);
}

/* black left border to delineate data cells */
table.mgmt colgroup.data > col:first-child {
  border-left: 1px black solid !important;
}

/* consistent cell settings */

table.mgmt th {
  vertical-align: bottom;
  color: black;
  font-family: 'Roboto Condensed', Arial, sans-serif;
  font-weight: bold;
}
table.mgmt th.sortable {
  cursor: pointer;
}

table.mgmt td {
  vertical-align: middle;
  color: #383838;
}

table.mgmt th,
table.mgmt td {
  position: relative;
  padding: 5px 10px;
  /*white-space: nowrap;*/
  /*vertical-align: middle;*/
  /*cursor: pointer;*/
  text-align: center;
}

/* extra padding on first cell */
table.mgmt tr > *:first-child {
  padding-left: 15px;
}

/* extra padding on last cell */
table.mgmt tr > *:last-child {
  padding-right: 15px;
}

/* text alignment */
table.mgmt th.text,
table.mgmt td.text {
  text-align: left;
}

table.mgmt th.text.center,
table.mgmt td.text.center {
  text-align: center;
}

table.mgmt td .target-name {
  margin: 0;
  min-width: 150px;
  font-family: 'Roboto Condensed', sans-serif;
  font-weight: normal;
  padding: 5px 0;
  line-height: 1.3;
}

table.mgmt .target-name svg {
  fill: #003470;
}

table.mgmt .target-name i {
  color: gray;
}

table.mgmt .target-head .target-name i {
  color: lightgray;
}

table.mgmt th.numeric,
table.mgmt td.numeric {
  text-align: right;
}

/* header rows */

/* reference rows of data go in the thead section */
table.mgmt thead tr:last-child th {
  border-bottom: 1px black solid;
  line-height: 1;
  font-family: 'Roboto Condensed', sans-serif;
  font-weight: normal;
  cursor: pointer;
  padding-top: 10px;
}

table.mgmt thead tr.freeze td {
  border-top: 1px black solid;
  border-bottom: 1px black solid;
}

table.mgmt thead > tr:first-child th {
  padding-top: 20px;
}

table.mgmt th:active {
  background: rgba(0, 0, 0, 0.05);
}

table.mgmt th small {
  font-family: Arial, sans-serif;
}

table.mgmt th b {
  text-transform: uppercase;
  font-weight: normal;
}

th:after {
  position: absolute;
  right: 1px;
  bottom: 10px;
  font-size: 130%;
}

th.sortable:hover:after {
  content: '  \2195';
  color: #3d96dd;
}
th.sorted.ascending:after {
  content: '  \2191';
  color: black;
}

th.sorted.descending:after {
  content: ' \2193';
  color: black;
}

/* data rows */

table.mgmt tbody {
  position: relative;
}

table.mgmt tbody > tr:first-child > td {
  border-top: 1px lightgray solid;
}

table.mgmt tbody tr:not(:last-child) td {
  border-bottom: 1px lightgray solid;
}

table.mgmt tr.active td {
  background: #e3f4fc;
  color: #4c4c4c;
}

table.mgmt tbody tr:hover td {
  background-image: linear-gradient(rgba(0, 0, 0, 0.01), rgba(0, 0, 0, 0.01));
}

table.mgmt tbody tr.active:hover td {
  background-image: linear-gradient(rgba(0, 0, 0, 0.01), rgba(0, 0, 0, 0.01)), linear-gradient(#e3f4fc, #e3f4fc);
}

table.mgmt td.user-list {
  padding: 3px 0;
  text-align: left;
}
table.mgmt .user-list .colleague-list {
  display: inline-flex;
}

table.mgmt tr > td:first-child .handle {
  min-width: 150px;
}

/* highlight the divider between contact and company */
table.mgmt .target-name .contact ~ .company:before {
  color: #3d96dd;
}

/* separator for lists of strings inside cells */
/*
table.mgmt td i {
    font-style: normal;
    display: inline-block;
    width: 0.75em;
    height: 0.75em;
    margin: 0 0.5em;
    background: #007ffa;
    border-radius: 50%;
    color: transparent;
    text-align: center;
    vertical-align: middle;
}
*/

/* the value 3-part bar chart */
.valuebars {
  display: inline-flex;
  background: #cfcfcf;
  border-radius: 4px;
  width: 85%;
  height: 20px;
  overflow: hidden;
  min-width: 70px;
}

.valuebars b {
  flex: 1 1 33.334%;
  background: hsl(0, 0%, 80%);
  color: white;
  font-size: 11px;
  line-height: 1.4;
  padding: 0.25em 0.6em 0.3em;
  text-align: center;
}

.valuebars > b:not(:first-child) {
  border-left: 1px white solid;
}

.valuebars b.p1 {
  background: hsl(0, 0%, 80%);
}

.valuebars b.p2 {
  background: hsl(0, 0%, 60%);
}

.valuebars b.p3 {
  background: hsl(0, 0%, 35%);
}

.percent-bar {
  height: 20px;
  min-width: 100px;
  --percent-watched: 0;
  position: relative;
  background: #efefef;
}

.percent-bar:after {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: var(--percent-watched);
  background: #003470;
}
.percentDone-bar:after {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: var(--percent-watched);
  background: #1ec780;
}

/************* embedded pipeline dashboard *************/

#people {
  background: #eaeaea;
  /*padding-top: 100px;*/
  padding: 0;
  overflow-x: hidden;
  overflow-y: auto;
  top: 0;
  bottom: 0;
}

#people .hud {
  left: 50%;
  top: 30px;
  transform: translateX(-50%);
  right: auto;
  position: absolute;
  width: 320px;
  max-width: calc(100% - 15px - 15px - 10px);
}

#people .dashboard-container {
  min-height: 100%;
  margin-top: 100px;
  border-top: 1px #e2e2e2 solid;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

#people .dashboard-container.swiper {
  margin-top: 0;
}
#people .dashboard-container.swiper > .status-wrapper {
  position: absolute;
  min-height: calc(100% - 49px);
}
#people .dashboard-container.swiper div.active {
  padding-bottom: 0;
}
#people .swiper .swiper-container {
  bottom: 0;
}
#people .swiper article.card {
  bottom: 50px;
}
#people .swiper .swiper-pagination {
  bottom: 30px;
}

#people .status-wrapper {
  pointer-events: none;
  position: relative;
  left: 0;
  right: 0;
  min-height: 100%;
}

#people .status-wrapper > div {
  opacity: 0;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  height: 0;
  transition: 0.3s opacity;
  pointer-events: none;
}

#people .status-wrapper > div.active {
  opacity: 1;
  pointer-events: all;
  height: auto;
  padding-bottom: 50px;
  background: #f3f3f3;
}

/************* embedded user worksheets *************/

#user_worksheets .intro {
  margin-top: 15px;
}

/* **************************************** */

/* chartist colors */

#chart .ct-label.ct-horizontal {
  text-align: center;
  white-space: nowrap;
}

/* the x and y axis on the grid itself */
.ct-grid.main-axis.new-month-axis {
  stroke-dasharray: none;
  stroke: black;
  stroke-width: 2px;
}

/* the vertical line for a new month */
.ct-grid.new-month-axis {
  stroke-dasharray: none;
  stroke: black;
  stroke-width: 0.5px;
}

.ct-label.new-month {
  font-weight: 700;
  color: black;
  white-space: nowrap;
  margin-left: -50%;
  transform: rotate(23deg);
}

/* the label text styling */
.ct-label {
  font-size: 11px !important;
}
.ct-chart-donut .ct-label {
  fill: white;
  font-weight: bold;
  font-size: 13px !important;
}

.ct-area {
  pointer-events: none !important;
  fill-opacity: 0.15 !important;
}

.ct-line {
  pointer-events: none !important;
}

/* firm chart area */
.ct-series-a .ct-area {
  fill: #003470 !important;
}

.ct-series-a .ct-line {
  stroke: #003470 !important;
  stroke-width: 2px;
  stroke-linecap: round;
}

.ct-series-a .ct-point {
  stroke: #003470 !important;
  stroke-linecap: round;
  stroke-width: 10px;
}

.ct-series-a .ct-point:hover {
  stroke-width: 20px;
}

.ct-series-b .ct-point:hover {
  stroke-width: 20px;
}

.ct-series-c .ct-point:hover {
  stroke-width: 20px;
}

/* group chart area */
.ct-series-b .ct-area {
  fill: #1497d8 !important;
}

.ct-series-b .ct-line {
  stroke: #1497d8 !important;
  stroke-width: 2px;
  stroke-linecap: round;
}

.ct-series-b .ct-point {
  stroke: #1497d8 !important;
  stroke-linecap: round;
  stroke-width: 8px;
}

/* member chart area */
.ct-series-c .ct-area {
  fill: #87cbed !important;
}

.ct-series-c .ct-line {
  stroke: #87cbed !important;
  stroke-width: 2px;
  stroke-linecap: round;
}

.ct-series-c .ct-point {
  stroke: #87cbed !important;
  stroke-linecap: round;
  stroke-width: 8px;
}

/* legend */
.ct-legend {
  margin: 0;
  padding: 0;
  list-style: none;
  font-size: 1em;
  display: inline-flex;
  font-family: 'Roboto Condensed', sans-serif;
  font-weight: 300;
}

.ct-legend li {
  /*display: inline-block;*/
  position: relative;
  margin-right: 10px;
  margin-bottom: 3px;
  font-weight: bold;
  color: gray;
}

.ct-legend li:before {
  width: 12px;
  height: 12px;
  position: relative;
  left: 0;
  top: -1px;
  content: '';
  border-radius: 50%;
  vertical-align: middle;
  display: inline-block;
  margin-right: 0.25em;
}

li.ct-series-a:before {
  background-color: #003470;
  border-color: #003470;
}

li.ct-series-b:before {
  background-color: #1497d8;
  border-color: #1497d8;
}

li.ct-series-c:before {
  background-color: #87cbed;
  border-color: #87cbed;
}

li.ct-series-d:before {
  background-color: #e39f15;
  border-color: #e39f15;
}

.client-table-pills-container {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 0;
}

.client-table-pills-container .tag {
  margin-right: 15px;
}

/* Pie chart */

.highlight .legend span {
  display: inline-block;
  white-space: nowrap;
}
.highlight .legend span:before {
  content: '';
  display: inline-block;
  width: 1em;
  height: 1em;
  margin-right: 0.25em;
  vertical-align: middle;
  position: relative;
  top: -1px;
  border-radius: 2px;
  background: #003470;
}
.highlight .legend .ct-series-overdue:before {
  background: #be1e2d;
}
.highlight .legend .ct-series-soon:before {
  background: #e39f15;
}
.highlight .legend .ct-series-later:before {
  background: #266733;
}
.highlight .legend .ct-series-client:before {
  background: #90b1ff;
}
.highlight .legend .ct-series-prospect:before {
  background: #6189ea;
}
.highlight .legend .ct-series-refsource:before {
  background: #003470;
}
.highlight .legend .ct-series-low:before {
  background: hsl(0, 0%, 80%);
}
.highlight .legend .ct-series-medium:before {
  background: hsl(0, 0%, 60%);
}
.highlight .legend .ct-series-high:before {
  background: hsl(0, 0%, 35%);
}

.pie-graph .graph-area .ct-chart-pie .ct-label {
  font-weight: 700;
  fill: white;
  font-size: 11px !important;
}

.pie-graph .graph-area .ct-series .ct-area,
.pie-graph .graph-area .ct-series .ct-slice-pie {
  fill: #777777;
  stroke: white;
  stroke-width: 1px;
}
.pie-graph .graph-area .ct-series .ct-slice-donut {
  stroke: #777777;
  fill: transparent;
}

/* overdue */
.pie-graph .graph-area .ct-series-overdue .ct-area,
.pie-graph .graph-area .ct-series-overdue .ct-slice-pie {
  fill: #be1e2d;
  stroke-width: 0;
}
.pie-graph .graph-area .ct-series-overdue .ct-slice-donut {
  stroke: #be1e2d;
  fill: transparent;
}

/* due soon */
.pie-graph .graph-area .ct-series-soon .ct-area,
.pie-graph .graph-area .ct-series-soon .ct-slice-pie {
  fill: #e39f15;
  stroke-width: 0;
}
.pie-graph .graph-area .ct-series-soon .ct-slice-donut {
  stroke: #e39f15;
  fill: transparent;
}

/* due later */
.pie-graph .graph-area .ct-series-later .ct-area,
.pie-graph .graph-area .ct-series-later .ct-slice-pie {
  fill: #266733;
  stroke-width: 0;
}
.pie-graph .graph-area .ct-series-later .ct-slice-donut {
  stroke: #266733;
  fill: transparent;
}

/* client and low */
.pie-graph .graph-area .ct-series-client .ct-area,
.pie-graph .graph-area .ct-series-client .ct-slice-pie {
  fill: #90b1ff;
}
.pie-graph .graph-area .ct-series-client .ct-slice-donut {
  stroke: #90b1ff;
  fill: transparent;
}
/* prospect and medium */
.pie-graph .graph-area .ct-series-prospect .ct-area,
.pie-graph .graph-area .ct-series-prospect .ct-slice-pie {
  fill: #6189ea;
}
.pie-graph .graph-area .ct-series-prospect .ct-slice-donut {
  stroke: #6189ea;
  fill: transparent;
}
/* refsource and high */
.pie-graph .graph-area .ct-series-refsource .ct-area,
.pie-graph .graph-area .ct-series-refsource .ct-slice-pie {
  fill: #003470;
}
.pie-graph .graph-area .ct-series-refsource .ct-slice-donut {
  stroke: #003470;
  fill: transparent;
}

/* client and low */
.pie-graph .graph-area .ct-series-low .ct-area,
.pie-graph .graph-area .ct-series-low .ct-slice-pie {
  fill: hsl(0, 0%, 80%);
}
.pie-graph .graph-area .ct-series-low .ct-slice-donut {
  stroke: hsl(0, 0%, 80%);
  fill: transparent;
}
/* prospect and medium */
.pie-graph .graph-area .ct-series-medium .ct-area,
.pie-graph .graph-area .ct-series-medium .ct-slice-pie {
  fill: hsl(0, 0%, 60%);
}
.pie-graph .graph-area .ct-series-medium .ct-slice-donut {
  stroke: hsl(0, 0%, 60%);
  fill: transparent;
}
/* refsource and high */
.pie-graph .graph-area .ct-series-high .ct-area,
.pie-graph .graph-area .ct-series-high .ct-slice-pie {
  fill: hsl(0, 0%, 35%);
}
.pie-graph .graph-area .ct-series-high .ct-slice-donut {
  stroke: hsl(0, 0%, 35%);
  fill: transparent;
}

/* ******************* Table Search Filters ********************* */

section.panel.table > header.filter {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
  padding: 15px 10px;
}
header.filter > .categories {
  margin-left: 10px;
  padding-top: 5px;
}

.table-filter {
  position: relative;
  align-self: center;
  padding-left: 10px;
  max-width: 250px;
  padding-top: 5px;
}
.table-filter > input {
  padding-left: 25px;
  height: 30px;
}
.table-filter > svg {
  position: absolute;
  top: 12px;
  left: 15px;
  margin-right: 0;
}
.table-filter > .clear-table-filter {
  position: absolute;
  top: 0;
  right: 0;
  height: 30px;
  width: 30px;
  background: transparent;
  border-radius: 0 4px 4px 0;
  cursor: pointer;
}
.table-filter .clear-table-filter svg {
  width: 16px;
  height: 16px;
  fill: transparent;
  margin-right: 0;
  position: absolute;
  top: 65%;
  transform: translateY(-50%) translateX(-50%);
}

.table-filter > input:not(:placeholder-shown) ~ .clear-table-filter svg {
  fill: gray;
}

header.filter .button-bar li {
  flex: 1 0 33.334%;
}

.targets .clear-table-filter,
.overlaps .clear-table-filter {
  cursor: pointer;
}
.clear-table-filter p {
  border-radius: 20px;
  cursor: pointer;
}
.clear-table-filter p:hover {
  background: #003470;
  color: white;
}

/* *********************** Overview Table ********************* */

.overview .average-potential {
  min-width: 130px;
}

.panel.table.overview td {
  cursor: pointer;
}

/************************* Highlights *************************/

.top-section {
  background: white;
  padding: 12px;
  border-radius: 6px;
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.1);
  margin-bottom: 2em;
  display: flex;
}

.middle-section {
  background: white;
  padding: 0 12px;
  border-radius: 6px;
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.1);
  margin-bottom: 2em;
}

.select-days {
  background: #f3f3f3;
  padding: 15px 20px;
  color: #000;
}

.select-calander {
  padding: 15px 20px;
  color: #000;
  margin: auto;
}

.download-pdf-link {
  background: #f3f3f3;
  padding: 15px 20px;
  color: #000;
}

.col-wrapper {
  display: flex;
  flex-wrap: wrap;
  color: #000;
}

.col-wrapper .column-box {
  width: calc(100% / 3);
  padding: 0 15px;
  margin: 25px 0;
}

@media (max-width: 991px) {
  .col-wrapper .column-box {
    width: calc(100% / 2);
  }

  .top-section {
    flex-direction: column;
  }

  .download-pdf-link {
    margin-left: 0;
  }
}

@media (max-width: 767px) {
  .col-wrapper .column-box {
    width: 100%;
  }
}

.col-wrapper .column-box h5 {
  margin: 0;
  text-transform: uppercase;
  font-size: 15px;
  border-bottom: 0.75px gray solid;
}

.col-wrapper .column-box .legend {
  margin: 5px 0 10px 0;
}
.col-wrapper .column-box .legend span {
  display: block;
}

.col-wrapper .user-info {
  display: flex;
  align-items: center;
}

.col-wrapper .user-img {
  background: #87cbed;
  color: white;
  border-radius: 50%;
  width: 2em !important;
  height: 2em !important;
  position: relative;
  text-align: center;
  font-size: 1em;
  font-weight: normal;
  overflow: hidden;
  padding-top: 0;
  line-height: 2em;
}

.col-wrapper .username {
  width: calc(100% - 2em);
  padding-left: 10px;
  color: gray;
  font-size: 18px;
  font-weight: normal;
}

.graph-area {
  margin-top: 25px;
}

/* *********************** Overlaps Table ********************* */

.overlaps {
  overflow: auto;
}

.overlaps table > thead > tr:nth-child(1) {
  border-bottom: 1px black solid;
}

.overlaps table > thead > tr:nth-child(1) > th.text.sorted.ascending {
  width: 20%;
}

.overlaps table > thead > tr:nth-child(1) > th:nth-child(2) {
  text-align: left;
  padding-left: 20px;
}

.overlaps thead > tr > th:first-child,
.overlaps tbody > tr > td:first-child {
  border-right: 1px black solid;
}

/* ********************* Targets Table ******************** */

.targets tbody tr > td:first-child {
  min-width: 150px;
}

.targets thead > tr:nth-child(1) {
  border-bottom: 1px black solid;
}

.targets .target,
.targets .user-list,
.targets .objective {
  min-width: 150px;
}

.targets .target {
  max-width: 300px;
}

.targets tbody td.status {
  white-space: nowrap;
}
.targets tbody td.status svg {
  margin-right: 0;
}

/* ********************* Clients Table ******************** */

.clients thead > tr > th {
  border-bottom: 1px black solid;
}
.clients tbody > tr > td {
  vertical-align: top;
}

.clients table > tbody > tr > td:nth-child(1) {
  max-width: 120px;
  overflow: auto;
}

.clients table > tbody > tr.target-head > td {
  /*border-top: 1px solid #a2a2a2 !important;*/
  border-top: 1px solid #003470 !important;
  border-bottom: 1px solid #003470 !important;
  /*background-color: #f0f3f3;*/
  background-color: #003470;
  /*background-color: white;*/
  /*color: #2d498d;*/
  color: white;
  font-weight: bolder;
}

.clients table > tbody > tr.target-head td {
  padding: 2px 5px 2px 15px;
}

.clients th.company {
  width: 10%;
  min-width: 70px;
}
.clients th.contact {
  width: 15%;
  min-width: 70px;
}
.clients th.objective {
  width: 15%;
  min-width: 70px;
}
.clients th.steps {
  width: 60%;
}

.clients td.steps {
  padding: 10px;
}

.cell-boxes {
  list-style: none;
  margin: 0;
  padding: 0;

  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
}
.cell-boxes > li {
  flex: 1 1 100%;
  background: #efefef;
  border-radius: 4px;
  padding: 10px;
  border-left: 6px #efefef solid;

  display: flex;
  flex-flow: row nowrap;
  align-items: center;
}
.cell-boxes > li:not(:first-child) {
  margin-top: 5px;
}
.cell-boxes .step {
  padding-left: 10px;
}
.cell-boxes .handle {
  background: white;
}
.cell-boxes > li.step.overdue {
  border-left-color: #be1e2d;
}
.cell-boxes > li.step.soon {
  border-left-color: #e09600;
}
.cell-boxes > li.step.later {
  border-left-color: #266733;
}

/* ********************* Progress Table ******************** */

.progress td .user {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
}
.progress td .user svg {
  margin-left: 10px;
}

.progress .group-head > td {
  background: #003470;
  color: white;
}

.progress td.group {
  padding-left: 10px !important;
}
.progress .expand-wrapper {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
}
.progress .caret-wrapper {
  margin-right: 10px;
}

.progress td.progress svg {
  background: #1ec780;
  border-radius: 50%;
  margin-right: 0;
  padding: 3px;
  fill: white;
  width: 18px;
  height: 18px;
}

.progress td.user a {
  color: #003470;
  text-decoration: none;
}
.progress td.user a:hover {
  color: #3d96dd;
}
.progress td.user a:hover .handle {
  background: #e3f4fc;
}

/********************** worksheet data entry ********************/

section.panel.worksheet h1 {
  position: relative;
  color: #003470;
  text-align: left;
  font:
    300 1.7em/45px 'Roboto Condensed',
    Arial,
    sans-serif;
  margin: -12px -12px 12px -12px;
  padding: 0 15px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: auto;
}

section.panel.worksheet h1 p {
  margin: 0;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
}

table.worksheet {
  border-collapse: collapse;
  width: 100%;
  font:
    normal 12px/1.4 Arial,
    sans-serif;
}
table.worksheet col {
  min-width: 150px;
}
table.worksheet col.name {
  width: 20%;
  border-right: 0.75px lightgray solid;
  min-width: 100px;
}
table.worksheet th,
table.worksheet td {
  padding: 5px;
  text-align: left;
  color: #202124;
}
table.worksheet tbody th {
  min-width: 100px;
}
table.worksheet td {
  min-width: 120px;
}
table.worksheet tbody > tr:nth-child(odd) {
  background: rgba(0, 0, 0, 0.02);
}
table.worksheet thead th {
  vertical-align: bottom;
  border-bottom: 0.75px black solid;
}
table.worksheet tbody th,
table.worksheet tbody td {
  font-weight: normal;
  vertical-align: middle;
  position: relative;
  text-align: left;
}

/* ********************* responsive content ******************** */

section#viewer img {
  width: 100%;
  margin: 10px 0;
}

/****** responsive ******/

@media (min-width: 600px) {
  details.report-params > .param .input-wrapper {
    flex-flow: row nowrap;
    align-items: center;
  }

  details.report-params > .param .value {
    flex: 1 1 25%;
    min-width: 250px;
    max-width: 400px;
    border-right: 1px lightgray solid;
  }
  details.report-params > .param .presets {
    flex: 1 1 75%;
    align-items: center;
  }

  details.report-params .org-view .presets {
    display: block;
    padding: 10px;
  }
  details.report-params > .param .input-wrapper {
    margin: 0 10px;
  }

  .orgchart .node .context {
    display: block;
  }

  #viewer-date-range {
    top: calc(49px + 10px);
    bottom: 0;
  }
  #viewer-date-range .rdrDefinedRangesWrapper {
    display: block;
  }

  #people {
    bottom: 0;
  }
  #people .dashboard-container.swiper > .status-wrapper {
    min-height: calc(100% - 49px);
  }

  section#viewer {
    top: 49px;
    height: calc(100% - 10px);
    color: gray;
  }
  section#viewer:before {
    content: '';
    position: fixed;
    left: 0;
    top: 49px;
    right: 0;
    height: 10px;
    background: white;
    z-index: 10;
    box-shadow: 0 0 30px rgba(0, 0, 0, 0.2);
  }
  section#viewer .block-wrapper {
    /*min-height: calc(100vh - 49px);*/
    min-height: calc(100% - 10px);
    top: 10px;
    position: relative;
  }

  section#viewer aside.ai {
    top: 0;
    height: calc(100% - 10px);
  }

  .mgmt-report-dl:before {
    display: inline-block;
  }

  #people .ai-dashboard > li:not(.break) {
    min-width: calc(100% / 2);
    /*max-width: 50%;*/
  }
}

@media (min-width: 768px) {
  .mbsc-popup-button-close {
    flex: 1 1 50%;
  }
  .mbsc-popup-button.btn.submit {
    flex: 1 1 50%;
    background: #003470;
    color: white;
    border-radius: 4px;
  }

  #viewer-date-range > .box {
    margin-left: 250px;
  }
}

/* temp: to remove secondary nav bar on viewer */
@media (max-width: 600px) {
  section#viewer {
    padding-top: 0;
    top: 0;
  }
}

@media (max-width: 767px) {
  .targets header {
    flex-direction: column;
  }

  .overlaps .clear-table-filter,
  .clients .clear-table-filter {
    align-items: center;
    white-space: nowrap;
    margin-left: 10px;
    min-width: auto;
  }

  .overlaps .clear-table-filter p,
  .clients .clear-table-filter p {
    margin-top: 8px;
  }
}

@media (min-width: 900px) {
  /* date picker, mobile-first ... override for bigger screens below */
  .rdrDateRangePickerWrapper {
    flex-flow: row nowrap;
  }
  .rdrDefinedRangesWrapper {
    flex: 1 1 33.334%;
  }
  .rdrCalendarWrapper {
    flex: 1 1 66.663%;
  }
  .rdrStaticRanges {
    flex-flow: column nowrap;
  }
  .rdrStaticRanges button {
    flex: 0 0 100%;
  }
}

@media (min-width: 875px) {
  .ai-dashboard > li:not(.break),
  .ai-dashboard.driver-tasks > li {
    min-width: 20%;
    max-width: calc(100% / 3);
  }

  .tile-container article.tile h1 {
    font-size: 1.9em;
  }
}

@media (min-width: 1220px) {
  .ai-dashboard > li:not(.break) {
    min-width: 20%;
    max-width: 25%;
  }
}

@media (min-width: 1565px) {
  .ai-dashboard > li:not(.break) {
    min-width: calc(100% / 5);
    max-width: 20%;
  }
}

@media (min-width: 1200px) {
  #people .ai-dashboard > li:not(.break) {
    min-width: calc(100% / 3);
  }
}
