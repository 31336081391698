.App-alert .alert {
  width: 20rem;
  background: #fff;
  padding: 1rem;
  border-radius: 8px;
  color: #2d2d2d;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.3);
  border: 2px #2d498d solid;
  border-radius: 8px;
}
.App-alert button {
  border-radius: 4px;
  background: #007aff;
}
