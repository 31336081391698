/****************** generalized dashboard overview ****************/

/* 
  aside background: #ccd4e8
  aside selected: #2d498d
  aside active (accent): #e3f4fc
*/

/* the flexbox for the entire layout */
.ai-frame {
  display: flex;
  height: 100%;
  width: 100vw;
  position: relative;
  z-index: 0;
  user-select: none;
  padding-top: 30px;
}

.ai-frame > aside {
  background: #0a0d28;
  padding: 10px;
  flex-grow: 0;
  /* lock the width and allow the main element to grow naturally */
  width: 20px;
}

.ai-frame > main {
  flex-grow: 1;
  /* this makes the main element always take up horizontal space left by aside */
  margin-left: 20px;
  position: relative;
  overflow-x: hidden;
  overflow-y: auto;
}

.ai-frame.show-report-header .expando {
  top: 50px;
}
.ai-frame.show-report-header.kpi-dash > main {
  margin-left: 0;
}

/* structure of content inside the main element */
.ai-frame > main > div {
  padding: 30px;
  pointer-events: none;
  padding-bottom: 40px;
  position: absolute;
  left: 0;
  right: 0;
  height: 0;
  margin: 0;
  overflow: hidden;
  z-index: 0;
  opacity: 0;
  transition: 0.5s opacity linear;
}

.ai-frame > main > div.active {
  opacity: 1;
  pointer-events: all;
  height: auto;
}

/* left vertical nav */
aside.ai {
  position: absolute;
  border-right: 0.75px #003470 solid;
  z-index: 100;
  top: 0;
  bottom: 0;
  transition: 0.3s width cubic-bezier(0, 0.75, 0, 1);
}

/* button to expand the left nav */
aside.ai > .expando {
  background: #0a0d28;
  width: 22px;
  height: 30px;
  position: absolute;
  z-index: 99;
  top: 25px;
  right: -22px;
  border-radius: 0 20px 20px 0;
  border: 0.75px #003470 solid;
  border-left: 0;
  line-height: 1;
  cursor: pointer;
}

aside.ai > .expando:after {
  content: '▶︎';
  color: white;
  position: absolute;
  left: 30%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  font-family: Arial, sans-serif;
  font-size: 13px;
}

/* the vertical flex element */
aside.ai > .col-wrap {
  display: flex;
  flex-flow: column nowrap;
  align-items: stretch;
  justify-content: flex-start;
  overflow: hidden;
  height: 100%;
  width: 100%;
}

/* so the text inside each element doesn't wrap as the left nav expands */
aside.ai > .col-wrap > * {
  min-width: 230px;
  position: relative;
}

aside.ai > .col-wrap > nav {
  flex: 0 1 auto;
}

/* the people picker can scroll */
aside.ai > .col-wrap nav.ai.people {
  flex: 1 0 auto;
  flex-grow: 1;
}

/* when the aside is expanded, handle interior elements */
aside.ai.expanded {
  width: 250px;
}

aside.ai.expanded .expando:after {
  transform: translateX(-50%) translateY(-50%) rotate(180deg);
}

@media (min-width: 600px) {
  aside.ai {
    top: 98px;
  }
}

.scroller,
.scroller.horz {
  overflow-x: auto;
  overflow-y: hidden;
  width: 100%;
  height: auto;
}

.scroller.vert {
  overflow-x: hidden;
  overflow-y: auto;
  width: auto;
  height: 100%;
}

/* the nav buttons */
nav.ai {
  background: transparent;
  line-height: 1;
  position: relative;
}

nav.ai > header {
  position: relative;
  background: transparent;
  color: #ffffff;
  font-weight: normal;
  padding: 1.5em 1.2em 0.75em 1.2em;
  text-transform: uppercase;
  font-size: 0.85em;
  height: 40px; /* manually set this so we can control the height of the scroller */
}
nav.ai .subhead {
  font-family: 'Roboto Condensed', Arial, sans-serif;
}

/* need to account for the header just above the scroller */
aside.ai nav.ai > .scroller.vert {
  border-radius: 4px;
  position: absolute;
  /* this is necessary for safari */
  left: 0;
  right: 0;
  top: 40px;
  /* the height of the header */
  bottom: 1px;
  height: auto;
}

/* report names */
nav.ai span,
nav.ai small {
  display: block;
  line-height: 1.3;
}

nav.ai .title {
  font-family: 'Roboto Condensed', sans-serif;
  font-weight: bold;
  font-size: 15px;
  line-height: 1.2;
}

nav.ai small {
  color: #003470;
  font-size: 80%;
}

nav.ai li:hover,
nav.ai li.active {
  background: #e3f4fc;
}

nav.ai li.active span {
  color: #003470;
}

nav.ai li.active .caret {
  display: block;
  color: #003470;
}

nav.ai li.active .avatar b {
  background: #003470;
}

.inline-search-filter {
  position: absolute;
  top: 0;
  right: 0;
  height: 40px;
  width: 100%;
  pointer-events: none;
  line-height: 1;
}

.inline-search-filter svg {
  fill: white;
  cursor: pointer;
  pointer-events: all;
  position: absolute;
  margin-right: 0;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  width: 1.3em;
  height: 1.3em;
}

.inline-search-filter svg.close {
  display: none;
  fill: #2d498d;
}

.inline-search-filter input {
  position: absolute;
  background: #ccd4e8 !important;
  border-bottom: 0.75px #003470 solid;
  border-width: 0 0 0.75px 0;
  border-radius: 0;
  opacity: 0;
  padding: 5px 30px 5px 5px;
  height: calc(100% - 5px);
  top: 0;
  right: 0;
  width: 0;
  transition:
    0.3s width cubic-bezier(0, 0.75, 0, 1),
    0.3s opacity cubic-bezier(0, 0.75, 0, 1);
}

.inline-search-filter input:focus,
.inline-search-filter input:active {
  outline: 0;
}

.inline-search-filter.active input {
  opacity: 1;
  width: 100%;
}

.inline-search-filter.active svg.search {
  display: none;
}

.inline-search-filter.active svg.close {
  display: block;
}

@media (min-width: 768px) {
  aside.ai {
    width: 250px;
    /*height: 100%;*/
    opacity: 1;
    position: static;
    background: #0a0d28;
    flex: 0 0 250px;
    bottom: 0;
    border-right: 0;
  }

  aside.ai > .col-wrap {
    width: auto;
  }

  .ai-frame .expando {
    display: none;
  }

  .ai-frame > main {
    margin-left: 0;
  }
}
