.action-sheet {
  position: absolute;
  z-index: 2;
  left: 20px;
  right: 20px;
  bottom: -100%;
  transition: bottom 0.3s cubic-bezier(0, 0.75, 0, 1);

  overflow: auto;
  max-height: calc(100% - 75px - 49px - 49px);

  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-end; /* this forces whatever is in this element to be aligned to the bottom without having to position it absolute */
}
.action-sheet.active {
  bottom: 20px;
  top: auto;
}

/* display as a flex column in all cases */
.action-sheet .action-sheet-inr {
  max-height: 100%;
  height: 100%;
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-end;
}
.action-sheet .action-sheet-inr > .title {
  flex: 0 0 22px;
}
.action-sheet .action-sheet-inr > .window {
  flex: 1 1 auto;
  overflow: hidden;
}
.action-sheet .action-sheet-inr > .action-cancel {
  flex: 0 0 40px;
}

/* when action sheet is active, .showing-action-sheet class is added to inner */
.inner:after {
  content: '';
  position: absolute;
  opacity: 0;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.2);
  pointer-events: none;
  transition: opacity 0.3s;
}
.inner.showing-action-sheet:after {
  opacity: 1;
}
.inner.showing-action-sheet > footer {
  display: none;
}

.action-sheet .title {
  color: white;
  text-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  background: transparent;
  text-align: center;
  font-size: 16px;
  font-family: 'Roboto Condensed', Arial, sans-serif;
  font-weight: bold;
  text-transform: uppercase;
}

.action-sheet button {
  cursor: pointer;
  border-radius: 0;
  height: 46px;
  min-height: 46px;
  font-size: 16px;
  text-align: center;
  color: #0076ff;
  background-color: #ffffff;
  line-height: 1;
  border: 1px #2d498d solid;
  border-bottom: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  padding: 5px 10px;
  display: block;
  width: 100%;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  text-transform: none;
  font-weight: normal;
  outline: none;
}

.action-sheet button:first-of-type {
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
}

.action-sheet button:nth-last-of-type(2) {
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
  border-bottom: 1px #2d498d solid;
}

.action-sheet button.action-cancel {
  margin-top: 8px;
  border-radius: 6px;
  background: #2d498d;
  color: white;
  text-transform: uppercase;
  font-family: 'Roboto Condensed', Arial, sans-serif;
  font-weight: bold;
  border: 0;
  position: relative;
  top: 0;
  transition: top 0.3s linear;
}
.action-sheet button.action-cancel:active {
  top: 5px;
  filter: saturate(200%);
}

.action-sheet .window {
  display: block;
  width: 100%;
  /*height: calc(100% - 85px);*/
  /* height of cancel button plus a margin */
  min-height: unset;
  border-radius: 6px;
  position: relative;
}

.action-sheet.step-suggestions button {
  height: 59px;
  min-height: 59px;
  text-align: left;
  line-height: 22px;
  font-size: 16px;
}

.action-sheet.step-suggestions b,
.action-sheet.step-suggestions small {
  display: block;
}

.action-sheet.step-suggestions small {
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
}

.action-sheet.step-suggestions button.action-cancel {
  text-align: center;
}

.d-none {
  visibility: hidden;
}

.target-detail-sheet {
  position: fixed;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  z-index: 100;
}

.target-detail-sheet .action-sheet {
  max-width: calc(650px - 50px);
  left: 0;
  right: 0;
  width: 100%;
  margin: 0 auto;
  /*top: 100px;*/
}

.target-detail-sheet .action-sheet.active {
  bottom: 55px;
  overflow: auto;
}

.action-sheet .Cal__Container__root {
  display: flex;
  flex-flow: column nowrap;
}
.action-sheet .Cal__Container__root > .Cal__Header__root {
  flex: 0 1 60px;
}
.action-sheet .Cal__Container__root > .Cal__Container__wrapper {
  flex: 1 1 auto;

  display: flex;
  flex-flow: column nowrap;
}
.action-sheet .Cal__Container__wrapper > .Cal__Weekdays__root {
  flex: 0 0 33px;
}
.action-sheet .Cal__Container__wrapper > .Cal__Container__listWrapper {
  flex: 1 1 auto;
}
.Cal__Today__root .Cal__Today__chevron {
  fill: white;
  position: relative !important;
  top: 20% !important;
}
.Cal__Today__root .Cal__Today__chevron path {
  fill: white !important;
}

/*
.action-sheet .Cal__MonthList__root {
  height: calc(100vh - 370px) !important;
}

.target-detail-sheet .action-sheet .Cal__MonthList__root {
  height: calc(100vh - 394px) !important;
}

.target-detail-sheet .action-sheet .window {
  height: calc(100% - 106px);
  min-height: unset;
}

.target-detail-sheet .Cal__Years__root {
  height: calc(100% - 147px) !important;
}
*/

.Cal__Years__list {
  height: 100% !important;
}

@media (width: 768px) {
  .action-sheet .Cal__MonthList__root {
    height: calc(100vh - 400px) !important;
  }
}

@media (max-width: 640px) {
  .actions button {
    font-size: 1.25em;
  }

  .target-detail-sheet .action-sheet {
    left: 20px;
    right: 20px;
    width: calc(100% - 51px);
    top: auto;
  }

  .target-detail-sheet .action-sheet.objective-examples {
    top: auto;
    bottom: 95px !important;
  }

  /*
  .target-detail-sheet .action-item.window {
    max-height: calc(100% - 95px - 49px - 49px);
    overflow: hidden;
  }
  */

  /*
  .target-detail-sheet .action-sheet.step-suggestions,
  .target-detail-sheet .action-sheet.objective-examples,
  .target-detail-sheet .action-sheet.choose-date {
    bottom: 95px !important;
    overflow: auto;
    max-height: calc(100% - 75px - 49px - 49px);
  }
  */

  .action-sheet button {
    height: 40px;
    min-height: 40px;
    font-size: 14px;
  }

  .action-sheet.step-suggestions button {
    height: 43px;
    min-height: 43px;
    text-align: left;
    line-height: 16px;
    font-size: 13px;
  }

  .action-sheet-inr .Cal__Header__root {
    min-height: unset;
    padding: 10px;
  }

  .action-sheet-inr .Cal__Header__dateWrapper.Cal__Header__year {
    height: 17px;
    font-size: 13px;
  }

  .action-sheet-inr .Cal__Header__dateWrapper.Cal__Header__day {
    height: 23px;
    font-size: 23px;
    line-height: 24px;
  }

  .action-sheet-inr .Cal__Weekdays__day {
    padding: 7px 0;
  }
}
