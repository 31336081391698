ol.task-list {
  list-style: none;
  padding: 0;
  margin: 0.5em 0;
  line-height: 1;
  border-top: 1px #ccc solid;
  border-bottom: 1px #ccc solid;
}
ol.task-list > li {
  display: flex;
  flex-flow: column wrap;
  align-items: stretch;

  padding: 0;
  margin-left: 10px;
  height: 90px;

  border-top: 1px #ccc solid;
}
ol.task-list li > * {
  flex: 1 auto;

  position: relative;
}
ol.task-list li > .avatar {
  flex-grow: 0;
  flex-shrink: 0;
  order: 1;

  width: 60px;
  height: 100%;
}
ol.task-list .description {
  flex-grow: 1;
  order: 2;
  width: calc(100% - 60px);
  height: calc(100% - 40px);
}
ol.task-list .info {
  flex-grow: 1;
  order: 3;
  width: calc(100% - 60px);
  height: 40px;
  font-family: 'Roboto Condensed', Arial, sans-serif;
  font-weight: normal;
}
ol.task-list .info b {
  font-size: 0.8em;
  text-transform: uppercase;
  font-family: 'Roboto Condensed', Arial, sans-serif;
  font-weight: bold;
  color: #b7b7b7;
}

ol.task-list > li:first-child {
  border-top: 0;
}

ol.task-list .avatar b {
  background: #2d498d;
  border-radius: 50%;
  overflow: hidden;
  width: 36px;
  height: 36px;
  position: absolute;
  color: white;
  text-align: center;
  line-height: 36px;
  left: 50%;
  top: 10px;
  transform: translateX(-50%);
}
ol.task-list .avatar b svg {
  width: 20px;
  height: 20px;
  margin-left: auto;
  margin-right: auto;
  fill: white;
}
ol.task-list .incomplete .avatar b {
  background: #f2f2f2;
  border: 1px #bbbbbb dotted;
}
ol.task-list .incomplete .avatar svg {
  fill: #2d498d;
}
ol.task-list span {
  position: absolute;
  display: block;
  left: 0;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  line-height: 1.2;
}
ol.task-list span * {
  display: inline;
  margin: 0 auto;
}
ol.task-list time {
  display: inline;
  font-weight: 200;
  position: inherit;
  padding: 0;
}
ol.task-list time:before {
  color: #b7b7b7;
}
