/********************** individual cards ***********************/

article.card {
  position: absolute;
  left: 10px;
  top: 10px;
  right: 10px;
  bottom: 20px;
  width: calc(100% - 20px);

  color: #353535;
  cursor: pointer;
  /* opacity: 1; */
  background-color: transparent;
  padding: 0;
  perspective: 2500px;

  font-family: 'Roboto Condensed', Arial, sans-serif;
  font-weight: normal;
  font-size: 13px;
  line-height: 1.2;
}

article.card > div {
  position: absolute;
  z-index: 10;

  left: 10px;
  top: 10px;
  right: 10px;
  bottom: 20px;

  /*background: #f2f2f2;*/
  background: white;
  border-radius: 12px;
  box-shadow: 0 1px 12px rgba(0, 0, 0, 0.3);
  min-width: 240px;
  max-width: calc(650px - 20px);
  margin: 0 auto;
  overflow-x: hidden;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;

  transition: 0.5s;
  transform-style: preserve-3d;
}

article.card > div.back {
  transform: rotateY(-180deg);
  background: #efefef;
  pointer-events: none;
  z-index: 0;
  border-radius: 12px;
  transition: 0.5s;
}

article.card.flipped > .front {
  transform: rotateY(180deg);
  z-index: 0;
  pointer-events: none;
  opacity: 0;
}

article.card.flipped > .back {
  transform: rotateY(0deg);
  pointer-events: all;
  z-index: 10;
  overflow-x: hidden;
}

/* so we can transition show/hide prev/next slides */
article.card > div > * {
  transition: opacity 0.3s;
}

article.card img {
  width: 100%;
  margin: 0 auto;
}

article.card svg {
  fill: white;
  margin-right: 0.25em;
}

article.card a {
  font-family: 'Roboto Condensed', sans-serif;
}

/*
article.card a:visited {
  color: blue;
}
*/

/* if there's a .company following a .contact, add a pipe between */
article.card h1 .contact ~ .company:before {
  content: ' » ';
}

article.card header {
  background: white;
  border-radius: 12px 12px 0 0;
  position: relative;
  height: auto;
  /*padding: 0 20px;*/
  margin: 0 auto;
  overflow: hidden;
  z-index: 3;
  display: block;
  width: 100%;
}

article.card header h1 {
  font-family: 'Roboto Condensed', sans-serif;
  font-weight: 300;
  padding: 10px 20px 0 20px;
  height: 75px;
  line-height: 75px; /* line height taller than actual height */
  font-size: 2em;
  text-transform: none;
  color: #2d498d;
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  text-align: left;
  margin: 0;
  border-bottom: 3px #2d498d solid;
}

article.card header h2 {
  font-family: 'Roboto Condensed';
  font-weight: normal;
  position: relative;
  font-weight: normal;
  font-size: 13px;
  text-align: left;
  margin: 0;
  padding: 25px 20px 20px 20px;
  height: 90px;
}

article.card header h2.objective:before {
  content: 'Desired Outcome';
  font-family: 'Roboto Condensed', Arial, sans-serif;
  font-weight: bold;
  font-size: 12px;
  text-transform: uppercase;
  color: #2d498d;
  position: absolute;
  left: 20px;
  top: 10px;
}

article.card.completed header h2.objective:before {
  content: 'Outcome';
}

article.card h2 > span {
  position: absolute;
  left: 20px;
  right: 20px;
  top: 60%;
  transform: translateY(-50%);
}

/*
  article.card header .tags {
    display: none;
  }
  */

article.card header .tags {
  position: absolute;
  top: 10px;
  left: 50%;
  transform: translateX(-50%);
  margin-left: 0;
  white-space: nowrap;
}

article.card header h2 .tags {
  position: absolute;
  top: 5px;
  left: auto;
  right: 15px;
  bottom: auto;
  transform: none;
}

article.card .main .tags {
  position: absolute;
  left: 20px;
  bottom: 20px;
}

article.card .main .tags svg {
  fill: white;
}

article.card .icon-list {
  display: none;

  position: absolute;
  top: 5px;
  left: 50%;
  transform: translateX(-50%);
}

article.card header .block {
  border-top: 0;
}

/* do not scroll on mobile screens */
article.card .main {
  display: block;
  position: relative;
  margin: 0 auto;
  height: auto;
}

article.card .main > * {
  padding: 0 10px;
}

article.card .main > ol {
  list-style: none;
  margin: 0 15px;
  padding: 0;
}

article.card .main > .block.target > h3 {
  font-size: 1.5em;
  font-weight: normal;
  line-height: 1.1;
  margin: 0;
  text-transform: none;
  padding-top: 0;
}

article.card .main > .block.objective > h3 {
  margin: 0 auto;
}

/* the big action button area */
article.card button.action.next {
  background: #3761c5;
  border: 0;
  color: white;
}

article.card .block.next,
article.card .block.next > div {
  background: #0a193e;
  /*background: linear-gradient(180deg, rgba(10,25,62,1) 0%, rgba(1,52,96,1) 100%);*/
}

article.card .followup .date-picker:before {
  background: linear-gradient(to right, rgba(10, 25, 62, 1) 0%, rgba(10, 25, 62, 0) 100%);
}

article.card .followup .date-picker:after {
  background: linear-gradient(to left, rgba(10, 25, 62, 1) 0%, rgba(10, 25, 62, 0) 100%);
}

article.card .edit .date-picker button.action.next.active {
  background: #2d498d;
  border-color: #2d498d;
  color: white;
}

/**** action buttons on a card ****/

.yesno {
  display: flex;
  justify-content: space-around;
  gap: 5px 10px;
  flex-wrap: wrap;
}

.yesno > * {
  flex-grow: 1;
  flex-basis: 0;
}

/**** action buttons on a card ****/

article.card .block.action-list ul.strip {
  padding: 0;
  text-align: center;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  width: 100%;
}

article.card .block.action-list ul.strip > li {
  -webkit-box-flex: 1;
  -ms-flex: 1 1 33.334%;
  flex: 1 1 33.334%;
  padding-left: 5px;
}

article.card .block.action-list ul.strip > li:first-child {
  padding-left: 0;
}

article.card .block.action-list a,
article.card .block.action-list li > span {
  white-space: nowrap;
}

/**** blocks are groups of data *****/

article.card .block {
  padding: 15px 10px;
  position: relative;
}

/**** action sheets ****/

article.card > div > footer,
article.create > footer {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 10;
  pointer-events: none;
  opacity: 0;
  transition: 0.5s opacity;
  overflow: hidden;
}

article.card > div > footer.active,
article.create > footer.active {
  pointer-events: auto;
  opacity: 1;
}

article.card > div > footer:before,
article.create > footer:before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.3);
  pointer-events: none;
}

article.card > div > footer.active:before,
article.create > footer.active:before {
  pointer-events: auto;
}

article.card .action-sheet,
article.create .action-sheet {
  position: absolute;
  left: 15px;
  right: 15px;
  bottom: -100%;
  color: white;
  background: transparent;
  max-height: calc(100% - 40px);
  overflow-x: hidden;
  overflow-y: auto;
  transition: 0.3s bottom cubic-bezier(0, 0.5, 0.3, 1);
}

article.create .action-sheet {
  left: 20px;
  right: 20px;
}

article.card .action-sheet.active,
article.create .action-sheet.active {
  bottom: 15px;
}

.action-sheet-btn svg {
  fill: #2d498d;
}

.action-sheet-btn:hover {
  background: #daecf9;
}

/***********************************
 ************* overlaps ************
 ***********************************/

dl.overlap dt {
  display: block;
  /*font-size: 70%;*/
  font-family: 'Roboto Condensed', Arial, sans-serif;
  font-weight: bold;
  text-transform: uppercase;
  border-radius: 4px;
  /*margin-right: 0.25em;*/
  margin-bottom: 4px;
  background: #ececec;
  padding: 0.5em;
}

dl.overlap dd {
  /*font-size: 0.8em;*/
  font-family: 'Roboto Condensed', Arial, sans-serif;
  font-weight: 300;
  padding: 0.5em;
}

dl.overlap dd > * {
  display: block;
  font-size: 1em;
  padding-left: 15px;
}

dl.overlap dd > u {
  display: inline-block;
}

/***********************************
 ** Data inside cards **
 ***********************************/

ol.data {
  list-style: none;
  margin: 0.1em 0;
  padding: 15px;
  text-align: left;
}

ol.data > li {
  padding: 0.5em 0;
  width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

ol.data > li a {
  color: #4db7ed;
  text-decoration-color: #4db7ed;
}

ol.data > li.card-text-title {
  font-size: 1.2em;
  color: #383838;
  padding: 0.25em 0;
  text-transform: none;
  white-space: nowrap;
  text-overflow: ellipsis;
  text-align: center;
}

ol.data > li.card-text-description {
  white-space: pre-wrap;
}

ol.data > li.text-left {
  text-align: left;
}

ol.data > li.text-right {
  text-align: right;
}

ol.data > li.goal-identifier {
  float: left;
  width: auto;
  max-width: 153px;
  margin: 5px 10px;
}

ol.data > li.goal-buttons {
  display: inline-block;
  max-width: 49%;
}

ol.data > li.goal-buttons button {
  border-radius: 5px;
  cursor: pointer;
  background: #4db7ed;
  color: white;
  width: 100%;
  max-width: 110px;
  padding: 4px;
  margin: 0 auto;
}

ol.data > li.goal-buttons svg {
  fill: white;
}

ol.data > li.strategy-text {
  white-space: pre-wrap;
}

ol.data svg.icon {
  top: -0.125em;
  margin-right: 0.5em;
  fill: gray;
  width: 17px;
  height: 17px;
  margin-left: -21px;
}

/***** Goal Progress Bar *****/
ol.data li .progress-bar-slider {
  /*height: 20vh;*/
  margin: 0;
  display: flex;
}

ol.data li .progress-bar-slider span.progress-bar-slider__value {
  font-size: 18px;
  font-family: 'Roboto Condensed', Arial, sans-serif;
  font-weight: bold;
  color: #4db7ed;
  text-align: right;
  padding-left: 5px;
  width: 37px;
}

ol.data li .progress-bar-slider input[type='range'] {
  margin: auto;
  margin-left: 5px;
  -webkit-appearance: none;
  position: relative;
  padding: 0;
  overflow: hidden;
  height: 25px;
  width: 100%;
  cursor: ew-resize;
  border-radius: 0;
  /* iOS */
}

::-webkit-slider-runnable-track {
  background: #ddd;
}

/*
   * 1. Set to 0 width and remove border for a slider without a thumb
   */
::-webkit-slider-thumb {
  -webkit-appearance: none;
  width: 24px;
  /* 1 */
  height: 25px;
  background: #fff;
  box-shadow: -100vw 0 0 100vw #4db7ed;
  border: 2px solid #999;
  /* 1 */
}

::-moz-range-track {
  height: 25px;
  background: #ddd;
}

::-moz-range-thumb {
  background: #fff;
  width: 24px;
  height: 25px;
  border: 3px solid #999;
  border-radius: 0 !important;
  box-shadow: -100vw 0 0 100vw #4db7ed;
  box-sizing: border-box;
}

::-ms-fill-lower {
  background: #4db7ed;
}

::-ms-thumb {
  background: #fff;
  width: 24px;
  height: 25px;
  border: 2px solid #999;
  box-sizing: border-box;
}

::-ms-ticks-after {
  display: none;
}

::-ms-ticks-before {
  display: none;
}

::-ms-track {
  background: #ddd;
  color: transparent;
  height: 25px;
  border: none;
}

::-ms-tooltip {
  display: none;
}

ol.data li .progress-bar-label {
  margin: 0 auto;
  display: flex;
  /*justify-content: center;*/
}

ul.progress-bar-label-items {
  margin: 0 40px 0 67px;
  padding: 0;
  list-style: none;
  display: flex;
  justify-content: space-between;
  font-family: 'Roboto Condensed', Arial, sans-serif;
  font-weight: bold;
  flex: auto;
  font-size: 11px;
}

/**** the button-list near this block handles show/hide of these elements *****/

div.context-container > * {
  opacity: 1;
  z-index: 1;
  position: absolute;
  left: 10px;
  right: 10px;
  transition: 0.3s opacity;
}

div.collaboration {
  padding-bottom: 20px;
}

div.collaboration h4 {
  margin-top: 2em;
  margin-bottom: 0.25em;
}

div.collaboration h4 + p {
  margin-top: 0;
}

fieldset .form-group > .sharing-container {
  display: flex;
}

fieldset .form-group > .sharing-container .field-line button {
  position: relative;
  float: left;
  cursor: pointer;
}

fieldset .form-group > .sharing-container .field-line button:hover + span.popup-window {
  display: inline-block;
}

fieldset .form-group > .sharing-container .field-line span.popup-window {
  display: none;
  position: absolute;
  left: -30px;
  top: 30px;
  font-family: 'Roboto Condensed', Arial, sans-serif;
  font-weight: bold;
  color: #2d498d;
  background: white;
  border-radius: 2px;
  padding: 3px;
  min-width: 100px;
  max-width: 250px;
  height: auto;
  z-index: 3000;
  opacity: 0.8;
  text-align: center;
}

.sharing-container .field-line button.popup svg {
  width: 26px;
  height: 26px;
  margin-right: 0;
  border: 1px solid #2d498d;
  border-radius: 20px;
  fill: #2d498d;
  padding: 1px;
}

.sharing-container .field-line button.popup svg.create {
  padding: 5px;
}

/**** scrolling the main element *****/

article.card.scrolled header {
  /*box-shadow: 0 0 50px rgba(0,0,0, 0.4);*/
  border-bottom: 1px darkgray solid;
  z-index: 3;
  pointer-events: none;
}

.card-tasks ol.tile-container > li {
  max-width: 100% !important;
  /* to override tile length inside cards */
}

/*
article.card.flipped>.back fieldset legend:before {
  left: 16px;
}
*/

article.card.flipped > .back legend svg {
  top: 3px;
}

article.card.flipped > .back .inner {
  position: relative;
  padding: 20px 0 0px;
  height: 100%;
  /*overflow-y: auto;*/
  /*overflow-x: hidden;*/

  display: flex;
  flex-flow: column nowrap;
}

article.card.flipped > .back .inner .action {
  bottom: 0;
  top: auto;
  position: sticky;
}

/****** responsive ******/

@media (min-width: 360px) {
  article.card .current-step time {
    padding: 0px 5px 8px 0px;
  }
}

@media (min-width: 481px) {
  article.card header h1 {
    font-size: 2.5em;
  }
}

@media (min-width: 600px) {
  article.card ul.strip.ui span {
    display: inline;
  }

  article.card > div {
    overflow: hidden;
  }

  article.card .main {
    height: calc(100% - 90px - 90px);
    overflow-x: hidden;
    overflow-y: scroll;
    /* has to be scroll, not auto */
    -webkit-overflow-scrolling: touch;
  }

  article.card .main.height-100 {
    height: 100%;
  }

  article.card.flipped > .back {
    overflow-y: auto;
    overflow-x: hidden;
  }
}

@media (max-width: 600px) {
  article.card.flipped > .back ul.button-bar {
    flex-wrap: nowrap;
  }
}

/* .is-keyboard-open article.card header .hideElement,
.is-keyboard-open article.card .block.next.hideElement,
.is-keyboard-open article.card div.block.action-list.hideElement,
.is-keyboard-open article.card div.block.tabs.hideElement {
  display: none;
} */

.is-keyboard-open .react-dropdown-select-dropdown {
  max-height: 200px;
}

.react-dropdown-select-item-selected {
  background-color: transparent !important;
  color: #585858 !important;
}

@media (max-width: 599px) {
  article.card .tags {
    -webkit-transform: none;
    transform: none;
  }
}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  article.card > div {
    left: calc(50% - 325px);
    right: auto;
    width: 650px;
  }

  article.card.flipped > .front {
    width: 650px;
  }

  article.card.flipped > .back {
    width: 650px;
  }
}

/**
mobile browser
*/
/* this is causing all kinds of other visual issues */
/*
@media (max-width: 412px) {
  @media screen and (-webkit-min-device-pixel-ratio:0) {
    .safari_only article.card {
      bottom: 85px;
    }

    .chrome_only .dialog {
      height: calc(100vh - 55px);
      height: calc(var(--vh, 1vh) * 100);
    }

    .safari_only .dialog {
      height: calc(100vh - 75px);
      height: calc(var(--vh, 1vh) * 100);
    }

    .safari_only .dialog>.box {
      height: 100%;
      top: 0;
      left: 0;
      transform: none;
    }

    .chrome_only .dialog>.box {
      height: 100%;
      top: 0;
      left: 0;
      transform: none;
    }
  }
}

/**
iphone 11 and iphone 11 pro mobile browser
*/
/* this is causing all kinds of other visual issues */
/*
@media only screen and (-webkit-min-device-pixel-ratio: 3),
only screen and (min--moz-device-pixel-ratio: 3),
only screen and (-o-min-device-pixel-ratio: 3/1),
only screen and (min-device-pixel-ratio: 3),
only screen and (min-resolution: 458dpi),
only screen and (min-resolution: 3dppx) {
  .safari_only article.card {
    bottom: 105px;
  }

  .safari_only .dialog {
    height: calc(100vh - 111px);
    height: calc(var(--vh, 1vh) * 100);
  }

  .safari_only .dialog>.box {
    height: 100%;
  }
}
*/

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  /* IE10+ CSS styles go here */
}
