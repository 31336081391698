/***********************************
 ********* ios button list *********
 ***********************************/

/**** standard button list ****/

ul.button-bar {
  display: flex;
  flex-wrap: nowrap;
  align-self: center;
  /*box-align: stretch;*/
  align-items: stretch;
  align-content: stretch;

  padding: 0;
  margin: 0 auto;
  list-style: none;
  font-family: Arial, sans-serif !important;
  font-size: 12px;
  font-weight: normal;
}

ul.button-bar.menu-space {
  margin: 2px 5px !important;
}

ul.button-bar li {
  flex: 1 0 0;
  position: relative;
}

ul.button-bar a,
ul.button-bar span {
  text-align: center;
  text-decoration: none;
  border: 1px #003470 solid;
  border-radius: 0;
  border-left: 0;
  padding: 0 10px;
  height: 30px;
  /*line-height: 28px;*/
  min-width: 30px;
  color: #003470;
  background: white;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

ul.button-bar li:first-child a,
ul.button-bar li:first-child span {
  border-left: 1px #003470 solid;
  border-top-left-radius: 4px !important;
  border-bottom-left-radius: 4px !important;
}

ul.button-bar li:last-child a,
ul.button-bar li:last-child span {
  border-top-right-radius: 4px !important;
  border-bottom-right-radius: 4px !important;
}

ul.button-bar li a:hover,
ul.button-bar li span:hover {
  background: #daecf9;
}

ul.button-bar li.active a,
ul.button-bar li.active span {
  background: #003470;
  color: white;
}

ul.button-bar input[type='radio'] {
  position: absolute;
  opacity: 0;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  cursor: pointer;
}

ul.button-bar input[type='radio'],
ul.button-bar a {
  outline: 0;
}

/* if we have a selection */

ul.button-bar.has-value a {
  background: transparent;
  color: gray;
  /*border-color: transparent #2d498d transparent transparent;*/
}

ul.button-bar.has-value li.active a {
  background: transparent;
  color: #315da8;
  border-color: #315da8;
  border-left: 1px #315da8 solid;
}

/* if the button has a marker, place it on the right */

ul.button-bar li.has-marker a {
  justify-content: flex-start;
  position: relative;
  text-align: left !important;
  text-transform: none;
}
ul.button-bar label.marker {
  min-width: auto;
  padding: 0 0.5em;
  right: 5px;
}
ul.button-bar label.marker svg {
  margin-left: 0 !important;
}
ul.button-bar a > small {
  margin-left: 5px;
}

ul.button-bar.sort li.has-marker label.marker {
  padding: 0;
}

/**** if it contains inputs, tweak the styling ****/

ul.button-bar.input {
  flex-wrap: nowrap;
  font-family: 'Roboto Condensed', Arial, sans-serif !important;
  font-weight: normal;
}

ul.button-bar.input a {
  text-transform: uppercase;
}

ul.button-bar.ui {
  display: inline-flex;
}
ul.button-bar.ui > li {
  flex: 1 1 0;
}

/* if it's a UI element, make it work like ios tabs */

ul.button-bar.ui > li > a,
ul.button-bar.ui > li > span {
  border-left: 0;
  border-radius: 0 !important;
  border-color: #003470 !important;
  color: #003470;
  background: white;
}

ul.button-bar.ui > li:hover > a {
  background: #d5e4f7;
}

ul.button-bar.ui > li:last-child > a,
ul.button-bar.ui > li:last-child > span {
  border-top-right-radius: 4px !important;
  border-bottom-right-radius: 4px !important;
}

ul.button-bar.ui > li:first-child > a,
ul.button-bar.ui > li:first-child > span {
  border-left: 1px #003470 solid;
  border-top-left-radius: 4px !important;
  border-bottom-left-radius: 4px !important;
}

ul.button-bar.ui > li.active > a,
ul.button-bar.ui > li.active > span {
  background: #003470;
  color: white;
}

ul.button-bar.error a {
  border: 1px solid #be1e2d !important;
}

/* but if it's ui and full width, go back to flex */

ul.button-bar.ui.full-width {
  display: flex;
  flex-flow: row nowrap;
}

/****** responsive ******/

@media (min-width: 400px) {
  ul.button-bar > li > a,
  ul.button-bar > li > span {
    font-size: 13px;
  }
}

@media (max-width: 400px) {
}

@media (max-width: 640px) {
  .dialog .field-line > label {
    width: 82%;
  }

  .dialog ul.button-bar a {
    border-left: 1px #ccc solid;
  }

  .dialog ul.button-bar {
    flex-wrap: wrap;
  }

  .dialog ul.button-bar.input {
    flex-wrap: nowrap;
  }
}
